<template>
  <div class="card mb-25 border-0 rounded-0 bg-white letter-spacing">
    <div
      class="card-head box-shadow bg-white d-lg-flex align-items-center justify-content-between p-15 p-sm-20 p-md-25"
    >
      <div class="d-sm-flex align-items-center">
        <router-link 
          class="default-btn position-relative transition border-0 fw-medium text-white pt-11 pb-11 ps-25 pe-25 pt-md-12 pb-md-12 ps-md-30 pe-md-30 rounded-1 bg-success fs-md-15 fs-lg-16 d-inline-block me-10 mb-10 mb-lg-0 text-decoration-none"
          to="/ajouter-reabonnement2"
        >
          Faire un réabonnement
          <i class="flaticon-plus position-relative ms-5 fs-12"></i>
        </router-link>
       <!-- <button
          class="default-outline-btn position-relative transition fw-medium text-black pt-10 pb-10 ps-25 pe-25 pt-md-11 pb-md-11 ps-md-30 pe-md-30 rounded-1 bg-transparent fs-md-15 fs-lg-16 d-inline-block mb-10 mb-lg-0"
          type="button"
        >
          Exporter
          <i class="flaticon-file-1 position-relative ms-5 top-2 fs-15"></i>
        </button>-->
      </div>
      <div class="d-flex align-items-center">
        <form class="search-box position-relative me-15">
          <input
            type="text"
            class="form-control shadow-none text-black rounded-0 border-0"
            placeholder="Rechercher un abonnement"/>
          <button
            type="submit"
            class="bg-transparent text-primary transition p-0 border-0">
            <i class="flaticon-search-interface-symbol"></i>
          </button>
        </form>
      </div>
    </div>
    <div class="card-body p-15 p-sm-20 p-md-25">
      <div class="table-responsive">
        <table class="table text-nowrap align-middle mb-0">
          <thead>
            <tr>
              <th scope="col" class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0">
                REFERENCE
              </th>
              <th scope="col" class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0">
                ABONNEMENT
              </th>
              <th scope="col" class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0" >
                ABONNE
              </th>
              <th scope="col" class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0" >
                Numéro DE BOITE
              </th>
              <th scope="col" class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0" >
                ADRESSE POSTALE / VIRTUELLE
              </th>
              <th scope="col" class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0" >
                ANNEE
              </th>
              <th scope="col" class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0" >
                TARIF
              </th>
              <th scope="col" class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0" >
                MONTANT TOTAL
              </th>
              <th scope="col" class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0" >
                DATE ABONNEMENT
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(redv, index) in redevances" :key="index">
              <td class="shadow-none lh-1 fw-medium text-primary">
                {{ redv.refRedevance}}
              </td>
              <td class="shadow-none lh-1 fw-medium text-primary">
                {{ redv.abonnement.refAbo }}
              </td>
              <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                {{ redv.abonnement.abonne? redv.abonnement.abonne.nomComplet : "" }}
              </td>
              <td class="shadow-none lh-1 fw-medium text-primary">
                {{ redv.abonnement.boite?.refBoite }}
              </td>
              <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                {{ redv.abonnement?.boite ? redv.abonnement?.boite?.adressePostale?.adresse_postale : redv.abonnement.adresseVirtuelle}}
              </td>
              <td class="shadow-none lh-1 fw-medium text-primary">
                {{ redv.annee }}
              </td>
              <td class="shadow-none lh-1 fw-medium text-primary">
                {{ separateur(redv.abonnement.loyer) }}
              </td>
              <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                {{ separateur(redv.abonnement.montant) }}
              </td>
              <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                {{ format_date(redv.abonnement.createdAt)  }}
              </td>
              <!--<td
                class="shadow-none lh-1 fw-medium text-body-tertiary text-end pe-0"
              >
                <div class="dropdown">
                  <button
                    class="dropdown-toggle lh-1 bg-transparent border-0 shadow-none p-0 transition"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <i class="flaticon-dots"></i>
                  </button>
                  <ul class="dropdown-menu">
                   <li>
                      <router-link :to="{ name: 'ViewAbonnementPage', params: { id: redv.abonnement.id } }" 
                          class="dropdown-item d-flex align-items-center"><i
                          class="flaticon-eye lh-1 me-8 position-relative top-1"
                        ></i>Détails</router-link>
                    </li>
                    <li>
                      <a @click="suppression(abonne.id,abonnements,'abonnements','abonnment')"
                        class="dropdown-item d-flex align-items-center" href="javascript:void(0);" >
                        <i class="flaticon-delete lh-1 me-8 position-relative top-1" ></i>
                         Supprimer
                      </a>
                    </li>
                    <li>
                      <a 
                        class="dropdown-item d-flex align-items-center" href="javascript:void(0);" >
                        <i class="flaticon-delete lh-1 me-8 position-relative top-1" ></i>
                         Détails
                      </a>
                    </li>
                  </ul>
                </div>
              </td>-->
            </tr>
          </tbody>
        </table>
      </div>
      <div
        class="pagination-area d-md-flex mt-15 mt-sm-20 mt-md-25 justify-content-between align-items-center"
      >
        <!-- <p class="mb-0 text-paragraph">
          Showing <span class="fw-bold">11</span> out of
          <span class="fw-bold">134</span> results
        </p>
        <nav class="mt-15 mt-md-0">
          <ul class="pagination mb-0">
            <li class="page-item">
              <a class="page-link" href="#" aria-label="Previous">
                <i class="flaticon-chevron-1"></i>
              </a>
            </li>
            <li class="page-item">
              <a class="page-link active" href="#">1</a>
            </li>
            <li class="page-item">
              <a class="page-link" href="#">2</a>
            </li>
            <li class="page-item">
              <a class="page-link" href="#">3</a>
            </li>
            <li class="page-item">
              <a class="page-link" href="#" aria-label="Next">
                <i class="flaticon-chevron"></i>
              </a>
            </li>
          </ul>
        </nav> -->
      </div>
    </div>
  </div>
  <AddBoitePostale />
</template>
<script lang="ts">

import JwtService from "@/services/JwtService";
import { defineComponent, onMounted, ref } from "vue";
import ApiService from "@/services/ApiService";
import { Abonnements } from "@/models/Abonnement";
import { format_date,suppression,separateur, error } from "@/utils/utils";

export default defineComponent({
    name: "ListeReabonnement",
    components: {
  },
  setup: () => {
    onMounted(() => {
      if (JwtService.getUser()) {
        getAllRedevancesPayees(JwtService.getUser());
      }
    });
    
    const redevances = ref([]);
    const abonnement = ref<Abonnements>();
    const abonneid= ref<null | string>(null)

    function getAllRedevancesPayees(abonneid:string|null) {
      return ApiService.get('/paiement/with/redevance/'+ abonneid)
        .then(({ data }) => {
          redevances.value = data.data.data;
        })
        .catch(({ response }) => {
          error(response.data.message)
      });
    }
    
    return { redevances,format_date,
      suppression,
      getAllRedevancesPayees,
       separateur,
       abonnement,
       abonneid
       };
  },
});
</script>