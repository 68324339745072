import { ref } from "vue";
import { defineStore } from "pinia";
import ApiService from "@/services/ApiService";
import JwtService, { saveUserPhone } from "@/services/JwtService";
import { error, success } from '@/utils/utils';
import { string } from "yup";


export interface Reposes {
  code: number;
  message: string;
  data: Data;
}

export interface Data {
  user: Abonne;
  token: string;
  refreshToken: string;
}
export interface Abonne {
  id:number;
  nomComplet: string;
  telephone: string;
  sexe: string;
  email: string;
  numeroNational: string;
  password: string;
  createdAt: string;
  updatedAt: string;
  deletedAt?: any;
}

export const useAuthStore = defineStore("auth", () => {
  const errors = ref({});
  const user = ref<Abonne>({} as Abonne);
  const isAuthenticated = ref(!!JwtService.getToken());
  const isVerify = ref(!!JwtService.getToken());

  console.log(isAuthenticated.value,isVerify.value,!!JwtService.getToken(),JwtService.getToken(),"eeeeeeeeeeeeeeeeeeeeeeeeeeee");
  function setAuth(authUser: Abonne) {
    const user_info = JSON.stringify(authUser);
    isAuthenticated.value = true;
    user.value = authUser;
    errors.value = {};
    JwtService.saveUser(authUser.id.toString());
    JwtService.saveUserPhone(authUser.telephone);
    JwtService.saveUserInfo(user_info);
    JwtService.saveUserName(authUser.nomComplet);
    JwtService.saveUserEmail(authUser.email);
  }

  function getUserInfo(){
    if(!JwtService.getUserInfo()){
      const varia= JwtService.getUserInfo()  != null ? JwtService.getUserInfo() : "";
      return JSON.parse(varia!);
    }
  } 
  
  
  function saveToken(token:string) {
    isVerify.value = true;
    JwtService.saveToken(token);
  }

  function setError(error: any) {
    errors.value = { ...error };
  }

  function purgeAuth() {
    isAuthenticated.value = false;
    user.value = {} as Abonne;
    errors.value = [];
    isVerify.value=false;
    JwtService.destroyUser();
  }

  function login(credentials: Abonne) {
    return ApiService.post("/login/abonner", credentials)
      .then(({ data }) => {
        setAuth(data.data["abonne"]);
        JwtService.saveUserId(data.data["id"]);
        success(data.message)
      })
      .catch(({ response }) => {
        setError(response.data);
      });
  }

  function logout() {
    JwtService.destroyToken();
    JwtService.destroyUserPhone();
    JwtService.destroyUserINfo();
    JwtService.destroyUserName();
    JwtService.destroyUserEmail();
    JwtService.destroyUser();
    JwtService.destroyUserId();
    purgeAuth();
  }

  function registerAbonner(credentials) {
    return ApiService.post("/register/abonner", credentials)
      .then(({ data }) => {
        success(data.message)
        JwtService.saveUserPhone(data.data!.telephone)
        return data;
      })
      .catch(({ response }) => {
        error(response.data.message)
        setError(response.data.errors);
        return response.data;
      });
  }

  function forgotPassword(email: string) {
    return ApiService.post("auth/forgot_password", email)
      .then(() => {
        setError({});
      })
      .catch(({ response }) => {
        setError(response.data.errors);
    });
  }

  function verifyPhone(code:string, estEmail:any, email:any) {
    if (JwtService.getUserPhone() != null && JwtService.getUserPhone() != '') {
      console.log('premier if')
    return  ApiService.post("/checkverification",{
        telephone:JwtService.getUserPhone(),
        email:email,
        code:code,
        estEmail:estEmail,
        userId:JwtService.getUserId(),
        abonneId:JwtService.getUser(),
      }).then( data  => {
          return data;
        })
        .catch( response  => {
          setError(response.response.data.errors);
          return response;
        });
    }else {
      console.log('elseeee')
      purgeAuth();
      
    }
  }

  function verifyReset(code:string, estEmail:any, email:any, telephone:any) {
      console.log('premier if')
    return  ApiService.post("/resetpassword/checkverification",{
        telephone:telephone,
        email:email,
        code:code,
        estEmail:estEmail,
        userId:JwtService.getUserId(),
        abonneId:JwtService.getUser(),
      }).then( data  => {
          return data;
        })
        .catch( response  => {
          setError(response.response.data.errors);
          return response;
        });
  }

  return {
    errors,
    user,
    isAuthenticated,
    isVerify,
    login,
    registerAbonner,
    logout,
    forgotPassword,
    saveToken,
    verifyPhone,
    getUserInfo,
    verifyReset,
  };
});
