<template>
  <div class="row justify-content-center flex-align-middle">
    <div class="col-md-6 col-lg-6 col-xl-6 col-xxl-6 col-xxxl-6">
      <div class="card mb-25 border-0 rounded-0 bg-white authentication-card">
        <div class="card-body letter-spacing">
          <div class="text-center mb-10">
            <img src="../../../assets/images/lapostebj.png" alt="logo-icon" style="height: 100px;" />
          </div>
          <h4 class="text-black fw-bold mb-0 text-center">
            Code de vérification
          </h4>
          <p class="text-center">Saisissez le code de validation à cinq chiffres <br> pour confirmer que vous avez bien reçu le SMS</p>
          <Form ref="codeForm" @submit="codeConfirmation" :validation-schema="codeSchema">
            <div class="form-group mb-10 mb-sm-15 mb-md-17">
              <label class="d-block text-black fw-semibold mb-10">
              </label>
              <div class="text-center">
                <div class="form-group mb-10 mb-sm-15 mb-md-17 d-flex justify-content-center">
                  <v-otp-input
                    ref="otpInput"
                    v-model:value="bindModal"
                    input-classes="otp-input"
                    separator=""
                    :num-inputs="5"
                    :should-auto-focus="true"
                    inputmode="numeric"
                    input-type="tel"
                    :conditionalClass="['one', 'two', 'three', 'four']"
                    :placeholder="['*', '*', '*', '*', '*']"
                    @on-change="handleOnChange"
                    @on-complete="handleOnComplete"
                  />
                </div>
              </div>
              <ErrorMessage name="code" class="text-danger" />
            </div>
            <button
              class="text-decoration-none text-center default-btn transition border-0 fw-medium text-white rounded-1 fs-md-15 fs-lg-16 bg-success  w-100"
              type="submit"
            >
              Validez
            </button>
            <span class="d-block text-muted text-center mt-20 mt-md-20 mb-12 mb-md-10 fs-md-15 fs-lg-16">
              <p v-if="countdown > 0">Obtenir un nouveau code ({{ countdown }} secondes)</p>  
            </span>
            <button v-if="countdown === 0" to="#"  class="default-btn with-border transition fw-medium rounded-1 fs-md-15 fs-lg-16 d-block w-100 text-decoration-none text-center"
            @click="newCode" >
              Obtenir un nouveau code
            </button>
          </Form>
        </div>
      </div>
    </div>
  </div>
</template>


<script lang="ts">
import { defineComponent, ref, onMounted, watch } from 'vue';
import { Form, Field, ErrorMessage } from 'vee-validate';
import * as Yup from 'yup';
import { useRoute, useRouter } from 'vue-router';
import VOtpInput from "vue3-otp-input";
import { useAuthStore} from "@/services/auth";
import { error, success } from '@/utils/utils';
import ApiService from '@/services/ApiService';
import axios from 'axios';

export default defineComponent({
    name: "CodeConfirmation",
    components: {
    Form,
    VOtpInput
},
  setup: () => {
    const codeSchema = Yup.object().shape({
      //code: Yup.number().typeError('Entrer le code correct').max(1, 'Vous ne devez pas dépasse 1 caractère').required('Le code est obligatoire'),
    });

    const router = useRouter();
    const codeConf = ref(null);
    const countdown = ref(60);

    const otpInput = ref<InstanceType<typeof VOtpInput> | null>(null);
    const bindModal = ref("");
    const route = useRoute();

    const authStore = useAuthStore();
    const estEmail = route.query.estEmail;
    const email = route.query.email;

    const handleOnComplete = async (value: string) => {
      try {
          const response = await authStore.verifyPhone(value,estEmail === 'true',email);
          if (response.status === 200) {
            success(response.data.message)
            console.log('Dans le response.data.message success', response.data.message)
              if (response.data.accessToken) {
                authStore.saveToken(response.data.accessToken);
              console.log('Dans le else', response)
              console.log('Dans le response.data.accessToken', response.data.accessToken)

                  router.push({ name: "tableauBordPage" });
              } else {
                  router.push({ name: "LoginPage" });
              }
          } else {
              error(response.response.data.message)
              console.log('Dans le else', response.response.data.message)
          }
      } catch (response:any) {
          error(response.response.data.message);
          console.log('Dans le cash', response.response.data.message)
      }
    };

    const handleOnChange = (value: string) => {
      //
    };

    const codeConfirmation = async () => {   
      //  
    };
    const telephone = route.query.telephone as string;
    

    const newCode = async () => {
      const dataToSend = {
        telephone: telephone,
        estEmail: estEmail,
        email:email,
      };
      console.log('laa',dataToSend)
      axios.post("/sendrestepasswordcode/abonner", dataToSend)
        .then(({ data }) => {
          if(data.code == 201) { 
            success(data.message);
          }
        }).catch(({ response }) => {
          error(response.data.message);
        });
    }

    const updateCountdown = () => {
      if (countdown.value > 0) {
        countdown.value--;
      } else {
        //
      }
    };
    const countdownInterval = setInterval(updateCountdown, 1000);

    onMounted(() => {
      watch(() => countdown.value, () => {
        if (countdown.value === 0) {
          clearInterval(countdownInterval);
        }
      });
    });

    return { codeSchema,
      codeConfirmation,
      codeConf,
      countdown,
      handleOnChange,
      handleOnComplete,
      bindModal,
      otpInput,
      authStore,
      newCode,
      telephone,
      estEmail,
      email
    };
  },
});
</script>

<style>
  .otp-input {
      width: 50px;
      height: 50px;
      font-size: 18px;
      text-align: center;
      margin: 0 5px;
      border: 1px solid #ccc;
      border-radius: 4px;
      margin-bottom: 20px;
  }
  .otp-input.is-complete {
  background-color: #e4e4e4;
  }
  .otp-input::-webkit-inner-spin-button,
  .otp-input::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  /* input::placeholder {
    font-size: 15px;
    text-align: center;
    font-weight: 600;
  } */
</style>