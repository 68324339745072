import { Modal } from "bootstrap";
import moment from "moment";
import ApiService from "@/services/ApiService";
import Swal from "sweetalert2";
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
import { defineComponent, onMounted, ref, watch } from "vue";


const hideModal = (modalEl: HTMLElement | null): void => {
  if (!modalEl) {
    return;
  }
  
  const myModal = Modal.getInstance(modalEl);
  myModal?.hide();
};

const getUrlApiForFiles = (dossier:string|null, nomFichier:string|null)=>{
  if(dossier){
    return `${ApiService.vueInstance.axios.defaults.baseURL?.split("api")[0]}uploads/${dossier}/${nomFichier}`;
  }else{
    return `${ApiService.vueInstance.axios.defaults.baseURL?.split("api")[0]}uploads/Erreur404.pdf`;
  }
}


const showModal = (modalEl: HTMLElement | null): void => {
  if (!modalEl) {
    return;
  }
  const myModal = new Modal(modalEl);
  myModal?.show()
};


const  success = (message) => {
  Swal.fire({
    title: 'Succès',
    text: message,
    icon: "success",
    toast: true,
    timer: 5000,
    position: 'top-right',
    showConfirmButton: false,
  });
}

const error = (message) => {
  Swal.fire({
    title: 'Erreur',
    text: message,
    icon: "error",
    toast: true,
    timer: 5000,
    position: 'top-right',
    showConfirmButton: false
  });
}

// const  format_date = (value)=>{
//   if (value) {
//     return moment(String(value)).format('DD-MM-YYYY h:m')
//   }
// }

const  format_date = (value)=>{
  if (value) {
    return format(new Date(value), 'dd-MM-yyyy HH:mm', { locale: fr });
    //return moment(String(value)).format('DD-MM-YYYY hh:mm')
  }
}
const separateur = (montant)=>{ 
  if(montant){
    return montant.toLocaleString('fr-FR');
  }
}

const removeModalBackdrop = (): void => {
  if (document.querySelectorAll(".modal-backdrop.fade.show").length) {
    document.querySelectorAll(".modal-backdrop.fade.show").forEach((item) => {
      item.remove();
    });
  }
};


const getAssetPath = (path: string): string => {
    return '' + path;
};

function getAllCategorieAbonnes(route:string,element:any) {
  return ApiService.get(`/${route}`)
  .then(({ data }) => {
    element.value = data.data
  })
  .catch(({ response }) => {
    error(response.data.msg)
  });
} 

const suppression = (id:number,element:any, route:string, entite:string) => {
  Swal.fire({
      text: "Vous êtes sur le point de supprimer " + entite +". Vous êtes sûr ?",
      icon: "warning",
      buttonsStyling: true,
      showCancelButton: true,
      confirmButtonText: "Supprimer",
      cancelButtonText: `Annuler`,
      heightAuto: false,
      customClass: {
        confirmButton: "btn fw-semobold btn-light-danger",
      },
    }).then((result) => {
      if (result.isConfirmed) {
      ApiService.delete(`/${route}/${id}`)
        .then(({ data }) => {
            Swal.fire({
              title: 'Succès',
              text: data.message,
              icon: "success",
              toast: true,
              timer: 5000,
              position: 'top-right',
              showConfirmButton: false,
              // text: data.message,
              // icon: "success",
              // buttonsStyling: false,
              // icon: "success",
              // toast: true,
              // timer: 3000,
              // position: 'top-right',
              // showConfirmButton: false, 
              // confirmButtonText: "Ok, Merci!",
              // heightAuto: false,
              // customClass: {
              //   confirmButton: "btn fw-semobold btn-light-primary",
              // },
            });
            for(let i = 0; i < element.length; i++) {
              if (element[i].id === id) {
                element.splice(i, 1);
              }
            }
        }).catch(({ response }) => {
          Swal.fire({
            text: response.data.message, //'Oups il y a un problème',//
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Rssayer à nouveau!",
            heightAuto: false,
            customClass: {
              confirmButton: "btn fw-semobold btn-light-danger",
            },
          });
      });
        } else if (result.isDenied) {
          Swal.fire("Changes are not saved", "", "info");
        }
      });
};

export { removeModalBackdrop,suppression,getUrlApiForFiles, hideModal,separateur, getAssetPath, showModal, format_date, success, error};