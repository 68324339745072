<template>
  <!-- <BreadCrumb PageTitle="Login" /> -->
  <CodeResetPass />
</template>

<script lang="ts">
import { defineComponent } from "vue";

import CodeResetPass from "../../components/Authentication/CodeResetPass/CodeResetPass.vue";

export default defineComponent({
  name: "CodeResetPage",
  components: {
    CodeResetPass,
  },
});
</script>