<template>
  <BreadCrumb PageTitle="Liste des demandes de cessions" />
  <ListDemandeCessions />
</template>

<script lang="ts">
import { defineComponent } from "vue";

import BreadCrumb from "../../components/Common/BreadCrumb.vue";
import ListDemandeCessions from "../../components/DemandeCessions/ListDemandeCessions.vue";


export default defineComponent({
  name: "ListDemandeCessionsPage",
  components: {
    BreadCrumb,
    ListDemandeCessions,
  },
});
</script>