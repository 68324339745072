const ID_TOKEN_KEY = "id_token" as string;
const ID_USER_KEY = "id_user" as string;
const ID_PHONE_KEY = "user_phone" as string;
const USER_INFO = "user_info" as string;
const USER_NAME = "user_name" as string;
const USER_EMAIL = "user_email" as string;
const ID_USERID_KEY = "id" as string;

/**
 * @description get user form localStorage
 */
export const getUser = (): string | null => {
  return window.localStorage.getItem(ID_USER_KEY);
};

/**
 * @description get user id form localStorage
 */
export const getUserId = (): string | null => {
  return window.localStorage.getItem(ID_USERID_KEY);
};

/**
 * @description save user into localStorage
 * @param user_phone: string
 */
export const saveUserPhone = (telephone: string): void => {
  window.localStorage.setItem(ID_PHONE_KEY, telephone);
};

export const saveUserName = (name: string): void => {
  window.localStorage.setItem(USER_NAME, name);
};

export const saveUserEmail = (email: string): void => {
  window.localStorage.setItem(USER_EMAIL, email);
};

export const saveUserInfo = (user_info: string): void => {
  window.localStorage.setItem(USER_INFO, user_info);
};

/**
 * @description get userphonr form localStorage
 */
export const getUserPhone = (): string | null => {
  return window.localStorage.getItem(ID_PHONE_KEY);
};

export const getUserName = (): string | null => {
  return window.localStorage.getItem(USER_NAME);
};

export const getUserEmail = (): string | null => {
  return window.localStorage.getItem(USER_EMAIL);
};

export const getUserInfo = (): string | null => {
  return window.localStorage.getItem(USER_INFO);
};


/**
 * @description remove user form localStorage
 */
export const destroyUserPhone = (): void => {
  window.localStorage.removeItem(ID_PHONE_KEY);
};


/**
 * @description save user into localStorage
 * @param user: string
 */
export const saveUser = (user: string): void => {
  window.localStorage.setItem(ID_USER_KEY, user);
};

/**
 * @description save user id into localStorage
 * @param id: string
 */
export const saveUserId = (id: string): void => {
  window.localStorage.setItem(ID_USERID_KEY, id);
};

/**
 * @description remove user form localStorage
 */
export const destroyUser = (): void => {
  window.localStorage.removeItem(ID_USER_KEY);
};

/**
 * @description remove user form localStorage
 */
export const destroyUserId = (): void => {
  window.localStorage.removeItem(ID_USERID_KEY);
};

export const destroyUserName = (): void => {
  window.localStorage.removeItem(USER_NAME);
};


export const destroyUserEmail = (): void => {
  window.localStorage.removeItem(USER_EMAIL);
};

/**
 * @description get token form localStorage
 */
export const getToken = (): string | null => {
  return window.localStorage.getItem(ID_TOKEN_KEY);
};

/**
 * @description save token into localStorage
 * @param token: string
 */
export const saveToken = (token: string): void => {
  window.localStorage.setItem(ID_TOKEN_KEY, token);
};

/**
 * @description remove token form localStorage
 */
export const destroyToken = (): void => {
  window.localStorage.removeItem(ID_TOKEN_KEY);
};

export const destroyUserINfo = (): void => {
  window.localStorage.removeItem(USER_INFO);
};

export default {
  getUserName,
  getUserEmail,
  saveUserEmail, 
  saveUserName,
  destroyUserName,
  destroyUserEmail,
  destroyUserINfo,
  getUserInfo, 
  getToken,
  saveUserPhone,
  getUserPhone,
  destroyUserPhone,
  saveUserInfo, 
  saveToken, 
  destroyToken, 
  saveUser ,
  destroyUser,
  getUser,
  getUserId,
  saveUserId,
  destroyUserId
};
