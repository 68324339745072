<template>
  <div class="row justify-content-center flex-align-middle">
    <div class="col-md-8 col-lg-6 col-xl-7 col-xxl-6 col-xxxl-5">
      <div class="card mb-25 border-0 rounded-0 bg-white authentication-card">
        <div class="card-body letter-spacing">
          <div class="text-center mb-10">
            <img src="../../../assets/images/lapostebj.png" alt="logo-icon" style="height: 100px;"/>
          </div>
          <h4 class="text-black fw-bold mb-0 text-center">
            Renseignez votre numéro de téléphone
          </h4>
          <Form ref="telForm" @submit="telConfirmation" :validation-schema="telSchema">
            <div class="form-group mb-10 mb-sm-15 mb-md-17">
              <label class="d-block text-black fw-semibold mb-10">
                Numéro de téléphone
              </label>
              <Field name="telephone" v-model="telephone"  v-slot="{ field }">
                  <vue-tel-input
                    placeholder="Ex: +229 97 00 00 00"
                    v-model = "field.value"
                    v-bind = "field"
                    defaultCountry="BJ"
                    mode="international"
                    @validate="validate"
                    class ="shadow-none fs-md-15 text-black py-2">
                  </vue-tel-input>
                </Field>
                <ErrorMessage name="telephone" class="text-danger"/>
                <div v-if="!validPhone"  class="text-danger">Veuillez entrer un numéro correcte</div>
            </div>
            <button
              class="text-decoration-none text-center default-btn transition border-0 fw-medium text-white rounded-1 fs-md-15 fs-lg-16 bg-success  w-100"
              type="submit" :disabled="!validPhone"
            >
              Validez
            </button>
            <div class="text-center mt-15 mt-sm-20 mt-md-25">
              <router-link
                to="/login"
                class="link-btn text-decoration-none text-primary fw-medium fs-md-15 fs-lg-16 position-relative"
              >
                <i class="ph-bold ph-arrow-left"></i>
                Revenir à la page de connexion
              </router-link>
            </div>
          </Form>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted } from 'vue';
import { Form, Field, ErrorMessage } from 'vee-validate';
import * as Yup from 'yup';
import { useRouter } from 'vue-router';
import Swal from 'sweetalert2';
import { VueTelInput } from 'vue3-tel-input'
import 'vue3-tel-input/dist/vue3-tel-input.css'

export default defineComponent({
    name: "TelConfirmation",
    components: {
    Form,
    Field,
    ErrorMessage,
    VueTelInput
},
  setup: () => {
    const telSchema = Yup.object().shape({
      telephone: Yup.string().required('Le numéro de téléphone est obligatoire'),
    });

    const router  = useRouter();
    const submitButton = ref<HTMLButtonElement | null>(null);
    const  codepay= ref();
    const  nationalnumlber= ref();
    const telephone=ref();
    const validPhone=ref<boolean>(false);

    function onInput(phone, phoneObject, input) {
      //
    }

    function validate(phoneObject) {
      validPhone.value=phoneObject.valid;
      if (phoneObject.valid == true) {
        telephone.value= phoneObject.number;
        codepay.value= phoneObject.countryCallingCode;
        nationalnumlber.value= phoneObject.nationalNumber;
      }else{
        telephone.value="";
        codepay.value= "";
        nationalnumlber.value= "";
      }
    }

    const telConf = ref(null);
    const telConfirmation = async (values: any) => {
      //
    };
   
    return { telSchema, telConfirmation, telConf, submitButton, 
      telephone,
      validPhone,
      validate,
      onInput,};
  },
});
</script>