<template>
    <div class="row justify-content-center">
    <div class="col-md-10 col-lg-8 col-xl-9 col-xxl-8 col-xxxl-5">
        <div class="card mb-25 border-0 rounded-0 bg-white authentication-card email-confirmation-card">
            <div class="card-body letter-spacing">
                <div class="icon mb-20 mb-sm-25 mb-md-30 md-lg-35 text-warning text-center rounded-circle position-relative ms-auto me-auto">
                    <i class="flaticon-check"></i>
                </div><h4 class="text-black fw-bold mb-0 text-center"> Abonnement effectué avec succès </h4>
                <p class="mb-0 fs-md-15 fs-lg-16 text-black text-center sub-text">
                    Pour le dépôt physique et la récupération de clés : 
                    Rendez vous dans l’agence concernée avec une copie 
                    physique des documents ainsi que votre pièce d’identité pour recevoir les clés de la boîte postale. </p>
            </div>
        </div>
    </div>
</div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "AbonnementInfoPage",
  setup: () => {
    //
  },
});
</script>
