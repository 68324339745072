<template>
  <BreadCrumb PageTitle="Liste des demandes de partage d'abonnement" />
  <ListDemandePartages />
</template>

<script lang="ts">
import { defineComponent } from "vue";

import BreadCrumb from "../../components/Common/BreadCrumb.vue";
import ListDemandePartages from "../../components/DemandePartages/ListDemandePartages.vue";


export default defineComponent({
  name: "ListDemandePartagesPage",
  components: {
    BreadCrumb,
    ListDemandePartages,
  },
});
</script>