import { createWebHistory, createRouter, createWebHashHistory } from "vue-router";
import LMSCoursesPage from "../pages/Dashboard/LMSCoursesPage.vue";
import AddProductPage from "../pages/Ecommerce/AddProductPage.vue";
import LoginPage from "../pages/Authentication/LoginPage.vue";
import TelConfPage from "../pages/Authentication/TelConfPage.vue";
import CodeConfPage from "../pages/Authentication/CodeConfPage.vue";
import CodeResetPage from "../pages/Authentication/CodeResetPage.vue";
import RegisterPage from "../pages/Authentication/RegisterPage.vue";
import ForgotPasswordPage from "../pages/Authentication/ForgotPasswordPage.vue";
import ResetPasswordPage from "../pages/Authentication/ResetPasswordPage.vue";
import ChangePasswordPage from "../pages/Authentication/ChangePasswordPage.vue";
import EmailConfirmationPage from "../pages/Authentication/EmailConfirmationPage.vue";
import UsersListPage from "../pages/Users/UsersListPage.vue";
import AddUserPage from "../pages/Users/AddUserPage.vue";
import ErrorPage from "../pages/ErrorPage.vue";
import Add2ReabonnementPage from "@/pages/Reabonnement/Add2ReabonnementPage.vue";
import AddReabonnementPageVue from "@/pages/Reabonnement/AddReabonnementPage.vue";
import ListeReabonnementPage from "@/pages/Reabonnement/ListeReabonnementPage.vue";
import MaintenancePage from "../pages/PagesInner/MaintenancePage.vue";
import NotificationsPage from "../pages/PagesInner/NotificationsPage.vue";
import ViewNotificationContentPage from "../pages/PagesInner/ViewNotificationContentPage.vue";
import ConnectedAccountsPage from "../pages/PagesInner/ConnectedAccountsPage.vue";
import ProfilePage from "../pages/PagesInner/ProfilePage.vue";
import SearchResultPage from "../pages/PagesInner/SearchResultPage.vue";
import AccountSettingsPage from "../pages/Settings/AccountSettingsPage.vue";
//import ChangePasswordPage from "../pages/Settings/ChangePasswordPage.vue";
import SettingsPrivacyPolicyPage from "../pages/Settings/SettingsPrivacyPolicyPage.vue";
import LogoutPage from "../pages/LogoutPage.vue";
import AddReabonnementPage from "@/pages/Reabonners/AddReabonnementPage.vue";
import ReabonnementsListPage from "@/pages/Reabonners/ReabonnementsListPage.vue";
import AddDemandeAbonnementPage from "@/pages/DemandeAbonnements/AddDemandeAbonnementPage.vue";
import ListDemandeAbonnementsPage from "@/pages/DemandeAbonnements/ListDemandeAbonnementsPage.vue";
import AddDemandeTransfertPage from "@/pages/DemandeTransferts/AddDemandeTransfertPage.vue";
import ListDemandeTransfertsPage from "@/pages/DemandeTransferts/ListDemandeTransfertsPage.vue";
import AddDemandeAttestationPage from "@/pages/DemandeAttestations/AddDemandeAttestationPage.vue";
import ListDemandeAttestationsPage from "@/pages/DemandeAttestations/ListDemandeAttestationsPage.vue";
import AddDemandeCessionPage from "@/pages/DemandeCessions/AddDemandeCessionPage.vue";
import ListDemandeCessionsPage from "@/pages/DemandeCessions/ListDemandeCessionsPage.vue";
import AddDemandeResiliationPage from "@/pages/DemandeResiliations/AddDemandeResiliationPage.vue";
import ListDemandeResiliationsPage from "@/pages/DemandeResiliations/ListDemandeResiliationsPage.vue";
import AddDemandeChangementPage from "@/pages/DemandeChangements/AddDemandeChangementPage.vue";
import ListDemandeChangementsPage from "@/pages/DemandeChangements/ListDemandeChangementsPage.vue";
import AddDemandePartagePage from "@/pages/DemandePartages/AddDemandePartagePage.vue";
import ListDemandePartagesPage from "@/pages/DemandePartages/ListDemandePartagesPage.vue";
import AddRedevancePage from "@/pages/Redevances/AddRedevancePage.vue";
import ListRedevancesPage from "@/pages/Redevances/ListRedevancesPage.vue";
import ListPaiementServicesPage from "@/pages/Paiements/ListPaiementServicesPage.vue";
import ListPaiementRedevancesPage from "@/pages/Paiements/ListPaiementRedevancesPage.vue";
import ListAbonnementsPage from "@/pages/Abonnements/ListAbonnementsPage.vue";
import ViewAbonnementPage from "@/pages/Abonnements/ViewAbonnementPage.vue";
import AddAbonnementPage from "@/pages/Abonnements/AddAbonnementPage.vue";
import AddReclamationPage from "@/pages/Reclamations/AddReclamationPage.vue";
import ListReclamationsPage from "@/pages/Reclamations/ListReclamationsPage.vue";
import { useAuthStore } from "@/services/auth";
import ApiService from "@/services/ApiService";
import AddMpostBoxPage from "@/pages/MpostBox/AddMpostBoxPage.vue";
import AbonnementInfoPage from "@/pages/Abonnements/AbonnementInfoPage.vue";

const routes = [
  {
    path: "/",
    redirect: "/login",
    component: () => import("@/layouts/MainLayout.vue"),
    meta: {
      middleware: "auth",
    },
    children: [
      {
        path: "/tableau_bord",
        name: "tableauBordPage",
        component: LMSCoursesPage,
      },
      {
        path: "/users-list",
        name: "UsersListPage",
        component: UsersListPage,
      },
      {
        path: "/add-user",
        name: "AddUserPage",
        component: AddUserPage,
      },
      {
        path: "/profile",
        name: "ProfilePage",
        component: ProfilePage,
      },
      {
        path: "/search-result",
        name: "SearchResultPage",
        component: SearchResultPage,
      },
      {
        path: "/account-settings",
        name: "AccountSettingsPage",
        component: AccountSettingsPage,
      },
      // {
      //   path: "/settings-change-password",
      //   name: "ChangePasswordPage",
      //   component: ChangePasswordPage,
      // },
      {
        path: "/settings-privacy-policy",
        name: "SettingsPrivacyPolicyPage",
        component: SettingsPrivacyPolicyPage,
      },
      {
        path: "/add-product",
        name: "AddProductPage",
        component: AddProductPage,
      },
      {
        path: "/ajouter-reabonnement",
        name: "AddReabonnementPage",
        component: AddReabonnementPage,
      },
      {
        path: "/liste-reabonnement",
        name: "ListeReabonnementPage",
        component: ListeReabonnementPage,
      },
      {
        path: "/ajouter-reabonnement3",
        name: "AddReabonnementPageVue",
        component: AddReabonnementPageVue,
      },
      {
        path: "/ajouter-reabonnement2",
        name: "Add2ReabonnementPage",
        component: Add2ReabonnementPage,
      },
      
      //
      {
        path: "/ajouter-demandeTransfert",
        name: "AddDemandeTransfertPage",
        component: AddDemandeTransfertPage,
      },
      {
        path: "/liste-demandeTransferts",
        name: "ListDemandeTransfertsPage",
        component: ListDemandeTransfertsPage,
      },
      {
        path: "/ajouter-demandeAttestation",
        name: "AddDemandeAttestationPage",
        component: AddDemandeAttestationPage,
      },
      {
        path: "/liste-demandeAttestations",
        name: "ListDemandeAttestationsPage",
        component: ListDemandeAttestationsPage,
      },
      {
        path: "/ajouter-demandeCession",
        name: "AddDemandeCessionPage",
        component: AddDemandeCessionPage,
      },
      {
        path: "/liste-demandeCessions",
        name: "ListDemandeCessionsPage",
        component: ListDemandeCessionsPage,
      },
      {
        path: "/ajouter-demandeResiliation",
        name: "AddDemandeResiliationPage",
        component: AddDemandeResiliationPage,
      },
      {
        path: "/liste-demandeResiliations",
        name: "ListDemandeResiliationsPage",
        component: ListDemandeResiliationsPage,
      },
      {
        path: "/ajouter-demandeChangement",
        name: "AddDemandeChangementPage",
        component: AddDemandeChangementPage,
      },
      {
        path: "/liste-demandeChangements",
        name: "ListDemandeChangementsPage",
        component: ListDemandeChangementsPage,
      },
      {
        path: "/ajouter-demandePartage",
        name: "AddDemandePartagePage",
        component: AddDemandePartagePage,
      },
      {
        path: "/liste-demandePartages",
        name: "ListDemandePartagesPage",
        component: ListDemandePartagesPage,
      },
      {
        path: "/notifications",
        name: "NotificationsPage",
        component: NotificationsPage,
      },
      {
        path: "/view-notification/:id",
        name: "ViewNotificationContentPage",
        component: ViewNotificationContentPage,
      },
      {
        path: "/ajouter-redevance",
        name: "AddRedevancePage",
        component: AddRedevancePage,
      },
      {
        path: "/liste-redevances",
        name: "ListRedevancesPage",
        component: ListRedevancesPage,
      },
      {
        path: "/liste-paiementRedevances",
        name: "ListPaiementRedevancesPage",
        component: ListPaiementRedevancesPage,
      },
      {
        path: "/liste-paiementServices",
        name: "ListPaiementServicesPage",
        component: ListPaiementServicesPage,
      },
      {
        path: "/view-abonnement/:id",
        name: "ViewAbonnementPage",
        component: ViewAbonnementPage,
      },
      {
        path: "/liste-abonnements",
        name: "ListAbonnementsPage",
        component: ListAbonnementsPage,
      },
      {
        path: "/ajouter-abonnement",
        name: "AddAbonnementPage",
        component: AddAbonnementPage,
      },
      {
        path: "/ajouter-reclamation",
        name: "AddReclamationPage",
        component: AddReclamationPage,
      },
      {
        path: "/liste-reclamations",
        name: "ListReclamationsPage",
        component: ListReclamationsPage,
      },
      {
        path: "/ajouter-mpostbox",
        name: "AddMpostBoxPage",
        component: AddMpostBoxPage,
      },
      {
        path: "/abonnement-info",
        name: "AbonnementInfoPage",
        component: AbonnementInfoPage,
      },
    ],
  },


  {
    path: "/",
    redirect: "/login",
    component: () => import("@/layouts/AuthLayout.vue"),
    children: [
      {
        path: "/login",
        name: "LoginPage",
        component: LoginPage,
      },
      {
        path: "/tel-confirm",
        name: "TelConfPage",
        component: TelConfPage,
      },
      {
        path: "/code-confirm",
        name: "CodeConfPage",
        component: CodeConfPage,
      },
      {
        path: "/code-reset",
        name: "CodeResetPage",
        component: CodeResetPage,
      },
      {
        path: "/register",
        name: "RegisterPage",
        component: RegisterPage,
      },
      {
        path: "/forgot-password",
        name: "ForgotPasswordPage",
        component: ForgotPasswordPage,
      },
      {
        path: "/reset-password",
        name: "ResetPasswordPage",
        component: ResetPasswordPage,
      },
      {
        path: "/change-password",
        name: "ChangePasswordPage",
        component: ChangePasswordPage,
      },
      {
        path: "/email-confirmation",
        name: "EmailConfirmationPage",
        component: EmailConfirmationPage,
      },
      {
        path: "/connected-accounts",
        name: "ConnectedAccountsPage",
        component: ConnectedAccountsPage,
      },
      {
        path: "/maintenance",
        name: "MaintenancePage",
        component: MaintenancePage,
      },

      {
        path: "/logout",
        name: "LogoutPage",
        component: LogoutPage,
      },
    ],
  },
  
  // {
  //   path: "/",
  //   component: () => import("@/layouts/SystemLayout.vue"),
  //   children: [
  //     {
  //       // the 404 route, when none of the above matches
  //       path: "/404",
  //       name: "404",
  //       component: () => import("@/views/crafted/authentication/Error404.vue"),
  //     },
  //     {
  //       path: "/500",
  //       name: "500",
  //       component: () => import("@/views/crafted/authentication/Error500.vue"),
  //     },
  //   ],
  // },
  { path: "/:pathMatch(.*)*", name: "ErrorPage", component: ErrorPage },
];

const router = createRouter({
  // history: createWebHistory(),
  history: createWebHashHistory(),
  linkExactActiveClass: "active",
  routes: routes,
  scrollBehavior() {
    return { top: 0, behavior: "smooth" };
  },
});

router.beforeEach((to, from, next) => {
  const authStore = useAuthStore();

  // verify auth token before each page change
  //authStore.verifyAuth();
  
  // before page access check if page requires authentication
  if (to.meta.middleware == "auth") {
    ApiService.setHeader();
    if (authStore.isAuthenticated && authStore.isVerify) {
      next();
    } else {
      next({ name: "LoginPage" });
    }
  } else {
    next();
  }

  // Scroll page to top on every route change
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: "smooth",
  });
});


export default router;