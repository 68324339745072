<template>
    <BreadCrumb PageTitle="Faire un réabonnement" />
    <Add2Reabonnement />
  </template>
  
  <script lang="ts">
    import { defineComponent } from "vue";
  
    import BreadCrumb from "../../components/Common/BreadCrumb.vue";
    import Add2Reabonnement from "../../components/Reabonnement/Add2Reabonnement.vue";
    export default defineComponent({
      name: "Add2ReabonnementPage",
      components: {
        BreadCrumb,
        Add2Reabonnement,
      },
    });
  </script>