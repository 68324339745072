<template>
  <div class="card mb-25 border-0 rounded-0 bg-white letter-spacing">
    <div
      class="card-head box-shadow bg-white d-lg-flex align-items-center justify-content-between p-15 p-sm-20 p-md-25">
      <div class="d-sm-flex align-items-center">
        <router-link
          class="default-btn position-relative transition border-0 fw-medium text-white pt-11 pb-11 ps-25 pe-25 pt-md-12 pb-md-12 ps-md-30 pe-md-30 rounded-1 bg-success fs-md-15 fs-lg-16 d-inline-block me-10 mb-10 mb-lg-0 text-decoration-none"
          to="/ajouter-demandeAttestation">
          <i class="flaticon-plus position-relative ms-5 fs-12"></i>
          Envoyer une demande d'attestation
        </router-link>
      </div>
      <div class="d-flex align-items-center">
        <form class="search-box position-relative me-15" @submit.prevent="rechercher">
          <input type="text" v-model="searchTerm" @keyup="rechercher"
            class="form-control shadow-none text-black rounded-0 border-0" placeholder="Rechercher une demande" />
          <button type="submit" class="bg-transparent text-primary transition p-0 border-0">
            <i class="flaticon-search-interface-symbol"></i>
          </button>
        </form>
      </div>
    </div>
    <div class="card-body p-15 p-sm-20 p-md-25">
      <div class="table-responsive">
        <table class="table text-nowrap align-middle mb-0">
          <thead>
            <tr>
              <th scope="col" class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0 ps-0">
                Référence
              </th>
              <th scope="col" class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0 ps-0">
                Date
              </th>
              <th scope="col" class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0 ps-0">
                Abonnement
              </th>
              <th scope="col" class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0 pe-0">Statut
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(demande, index) in demandes" :key="index">
              <td class="shadow-none lh-1 fw-medium text-primary">
                {{ demande.refDemande }}
              </td>
              <td class="shadow-none lh-1 fw-medium">
                {{ format_date(demande.createdAt) }}
              </td>
              <td class="shadow-none lh-1 fw-medium">
                {{ demande?.abonnement?.refAbo }} {{ demande?.abonnement?.boite?.adressePostale ? " -" +
                  demande?.abonnement?.boite?.adressePostale : "" }}
              </td>
              <td class="shadow-none lh-1 fw-medium">
                <span v-if="demande.status === 'En attente'" class="badge text-outline-info">{{ demande.status }}</span>
                <span v-else class="badge text-outline-success">{{ demande.status }}</span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="pagination-area d-md-flex mt-15 mt-sm-20 mt-md-25 justify-content-between align-items-center">
        <PaginationComponent :page="page" :totalPages="totalPages" :totalElements="totalElements" :limit="limit"
          @paginate="handlePaginate" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from "vue";
import Swal from "sweetalert2";
import ApiService from "@/services/ApiService";
import { Demande } from "@/models/Demande";
import { suppression, format_date, error } from "@/utils/utils";
import PaginationComponent from '@/components/Utilities/Pagination.vue';
import JwtService from "@/services/JwtService";

export default defineComponent({
  name: "ListDemandeAttestations",
  components: {
    PaginationComponent
  },
  setup: () => {

    onMounted(() => {
      abonneid.value = JwtService.getUser();
      if (JwtService.getUser()) {
        getAllDemandes(abonneid.value);
      }
    });

    const demandes = ref<Array<Demande>>([]);
    const abonneid = ref<null | string>(null)

    // BEGIN PAGINATE
    const searchTerm = ref('');
    const page = ref(1);
    const totalPages = ref(0);
    const limit = ref(10);
    const totalElements = ref(0);

    const handlePaginate = ({ page_, limit_ }) => {
      try {
        page.value = page_;
        getAllDemandes(abonneid.value, page_, limit_);
      } catch (error) {
        //

      }
    };
    function rechercher() {
      getAllDemandes(abonneid.value, page.value, limit.value, searchTerm.value);
    }
    // END PAGINATE

    function getAllDemandes(abonneid: string | null, page = 1, limi = 10, searchTerm = '') {
      return ApiService.get(`/demandes/recherche/type/4?page=${page}&limit=${limi}&mot=${searchTerm}&`)
        .then(({ data }) => {
          demandes.value = data.data.data;
          totalPages.value = data.data.totalPages;
          limit.value = data.data.limit;
          totalElements.value = data.data.totalElements;
          return data.data;
        })
        .catch(({ response }) => {
          error(response.data.message)
        });
    }

    const deleteDemande = (id: number) => {
      ApiService.delete(`/demandes/${id}`)
        .then(({ data }) => {
          Swal.fire({
            text: data.message,
            toast: true,
            icon: 'success',
            title: 'General Title',
            animation: false,
            position: 'top-right',
            showConfirmButton: false,
            timer: 5000,
            timerProgressBar: true,
            heightAuto: false
          });
        })
        .catch(({ response }) => {
          Swal.fire({
            text: response.data.message,
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Réssayer à nouveau!",
            heightAuto: false,
            customClass: {
              confirmButton: "btn fw-semobold btn-light-danger",
            },
          });
        });

      for (let i = 0; i < demandes.value.length; i++) {
        if (demandes.value[i].id === id) {
          demandes.value.splice(i, 1);
        }
      }
    };

    return {
      suppression,
      format_date,
      demandes,
      getAllDemandes,
      deleteDemande,
      page,
      totalPages,
      limit,
      totalElements,
      handlePaginate,
      searchTerm,
      rechercher
    };

  },
});
</script>