<template>
  <ChangePassword />
</template>

<script lang="ts">
import { defineComponent } from "vue";

import ChangePassword from "../../components/Authentication/ChangePassword/ChangePassword.vue";

export default defineComponent({
  name: "ChangePasswordPage",
  components: {
    ChangePassword,
  },
});
</script>