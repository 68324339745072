<template>
  <div class="row justify-content-center flex-align-middle">
    <div class="col-md-8 col-lg-6 col-xl-7 col-xxl-6 col-xxxl-5">
      <div class="card mb-25 border-0 rounded-0 bg-white authentication-card">
        <div class="card-body letter-spacing">
          <div class="text-center mb-10">
            <img src="../../../assets/images/lapostebj.png" alt="logo-icon" style="height: 100px;" />
          </div>
          <h4 class="text-black fw-bold mb-0 text-center">Modifier votre mot de passe</h4>
          <p class="mb-0 fs-md-15 fs-lg-16 text-paragraph text-center sub-text">
            Entrez l'ancien mot de passe reçu, votre nouveau mot de passe et confirmez-le nouveau mot de passe.
          </p>
          <Form ref="changePassForm" @submit="changePass" :validation-schema="changePassSchema">
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <div class="d-flex align-items-center justify-content-between mb-10" >
                <label class="d-block text-black fw-semibold ">
                  Mot de passe 
                </label> 
              </div>
              <div class="input-group has-validation">
                <Field name="password" :type="showPassword ? 'password' : 'text'" class="form-control shadow-none fs-md-15 text-black" placeholder="Entrer votre ancien mot de passe"/>
                <span class="input-group-text" id="inputGroupPrepend">
                  <i :class="{ 'flaticon-eye': !showPassword, 'flaticon-visibility-off': showPassword }" class="flaticon-eye" @click="toggleShow" :aria-label="buttonLabel"></i>
                </span>
              </div>
            <ErrorMessage name="password" class="text-danger"/>
            </div>
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <div class="d-flex align-items-center justify-content-between mb-10" >
                <label class="d-block text-black fw-semibold ">
                  Nouveau mot de passe 
                </label> 
              </div>
              <div class="input-group has-validation">
                <Field name="newPassword" :type="showPassword2 ? 'password' : 'text'" class="form-control shadow-none fs-md-15 text-black" placeholder="Entrer votre nouveau mot de passe"/>
                <span class="input-group-text" id="inputGroupPrepend">
                  <i :class="{ 'flaticon-eye': !showPassword2, 'flaticon-visibility-off': showPassword2 }" class="flaticon-eye" @click="toggleShow2" :aria-label="buttonLabel2"></i>
                </span>
              </div>
            <ErrorMessage name="newPassword" class="text-danger"/>
            </div>
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <div class="d-flex align-items-center justify-content-between mb-10" >
                <label class="d-block text-black fw-semibold ">
                  Confirmer le mot de passe
                </label> 
              </div>
              <div class="input-group has-validation">
                <Field name="confirmNewPassword" :type="showPassword3 ? 'password' : 'text'" class="form-control shadow-none fs-md-15 text-black" placeholder="Confirmer le mot de passe"/>
                <span class="input-group-text" id="inputGroupPrepend">
                  <i :class="{ 'flaticon-eye': !showPassword3, 'flaticon-visibility-off': showPassword3 }" class="flaticon-eye" @click="toggleShow3" :aria-label="buttonLabel3"></i>
                </span>
              </div>
            <ErrorMessage name="confirmNewPassword" class="text-danger"/>
            </div>

            <button
              class="text-decoration-none text-center default-btn transition border-0 fw-medium text-white rounded-1 fs-md-15 fs-lg-16 bg-success  w-100"
              type="submit"
            >
              Validez
            </button>
            <span  class="d-block text-muted text-center mt-20 mt-md-20 mb-12 mb-md-10 fs-md-15 fs-lg-16">
              Ou
            </span>
            <router-link to="/login"  class="default-btn with-border transition fw-medium rounded-1 fs-md-15 fs-lg-16 d-block w-100 text-decoration-none text-center">
              Annuler
            </router-link>
          </Form>
        </div>
      </div>
    </div>
  </div>
</template>


<script lang="ts">
import { defineComponent, ref, onMounted, computed } from 'vue';
import { Form, Field, ErrorMessage } from 'vee-validate';
import * as Yup from 'yup';
import { useAuthStore} from "@/services/auth";
import ApiService from '@/services/ApiService';
import { error, success } from '@/utils/utils';
import { useRouter } from 'vue-router';
import { User } from '@/models/users';
import JwtService from "@/services/JwtService";


export default defineComponent({
    name: "ChangePassPage",
    components: {
    Form,
    Field,
    ErrorMessage
},
  setup: () => {
    const changePassSchema = Yup.object().shape({
      password: Yup.string().min(8, 'Le mot de passe doit contenir au moins 8 caractères').required('Le mot de passe est obligatoire'),
        newPassword: Yup.string().min(8, 'Le mot de passe doit contenir au moins 8 caractères').required('Le mot de passe est obligatoire'),
        confirmNewPassword: Yup.string()
        .min(8, 'Le mot de passe doit contenir au moins 8 caractères')
        .oneOf([Yup.ref("newPassword")], "Les champs de mot de passe et de confirmation de mot de passe doivent correspondre")
        .label("Confirmation de mot"),
      });

      const changePassForm = ref<User>();
      const router = useRouter();
      const store = useAuthStore();

      onMounted(() => {
      if (JwtService.getUser()) {
        getAbonne(JwtService.getUser());
      }
    });

    function getAbonne(id: string | null) {
      ApiService.get("/abonnes/" + id)
        .then(({ data }) => {
          for (const key in data.data) {
            changePassForm.value?.setFieldValue(
              key,
              typeof data.data[key] === 'object' && data.data[key] !== null
                ? data.data[key].id
                : data.data[key]
            );
          }
        })
        .catch(({ response }) => {
          error(response.message);
        });
    }

    function logout() {
      store.logout()
      if (!store.isAuthenticated) {
        router.push({name:'LoginPage'})
      }
    }

    const changePass = async (values, {resetForm}) => {
      ApiService.put("/abonnes/"+values.id,values)
          .then(({ data }) => {
            if(data.code == 200) { 
              success(data.message);
              resetForm();
              logout();
              //router.push({ name: "tableauBordPage" });
            }
          }).catch(({ response }) => {
            error(response.data.message);
        });
      };

      const showPassword = ref(true);
    const password = ref(null);

    const toggleShow = () => {
      showPassword.value = !showPassword.value;
    };

    const buttonLabel = computed(() => (showPassword.value ? 'Hide' : 'Show'));

    const showPassword2 = ref(true);
    const toggleShow2 = () => {
      showPassword2.value = !showPassword2.value;
    };
    const buttonLabel2 = computed(() => (showPassword2.value ? 'Hide' : 'Show'));

    const showPassword3 = ref(true);
    const toggleShow3 = () => {
      showPassword3.value = !showPassword3.value;
    };
    const buttonLabel3 = computed(() => (showPassword3.value ? 'Hide' : 'Show'));

    return { changePassSchema,
      changePass,
      changePassForm,
      showPassword,
      password,
      toggleShow,
      buttonLabel,
      showPassword2,
      toggleShow2,
      buttonLabel2,
      showPassword3,
      toggleShow3,
      buttonLabel3,
    };
  },
});
</script>

<style>
  .otp-input {
      width: 50px;
      height: 50px;
      font-size: 18px;
      text-align: center;
      margin: 0 5px;
      border: 1px solid #ccc;
      border-radius: 4px;
      margin-bottom: 20px;
  }
  .otp-input.is-complete {
  background-color: #e4e4e4;
  }
  .otp-input::-webkit-inner-spin-button,
  .otp-input::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  /* input::placeholder {
    font-size: 15px;
    text-align: center;
    font-weight: 600;
  } */
</style>