<template>
  <BreadCrumb PageTitle="Envoyer une demande de changement de clé" />
  <AddDemandeChangement />
</template>

<script lang="ts">
  import { defineComponent } from "vue";

  import BreadCrumb from "../../components/Common/BreadCrumb.vue";
  import AddDemandeChangement from "../../components/DemandeChangements/AddDemandeChangement.vue";

  export default defineComponent({
    name: "AddDemandeChangementPage",
    components: {
      BreadCrumb,
      AddDemandeChangement,
    },
  });
</script>