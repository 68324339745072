<template>
  <BreadCrumb PageTitle="Envoyer une demande de transfert" />
  <AddDemandeTransfert />
</template>

<script lang="ts">
  import { defineComponent } from "vue";

  import BreadCrumb from "../../components/Common/BreadCrumb.vue";
  import AddDemandeTransfert from "../../components/DemandeTransferts/AddDemandeTransfert.vue";

  export default defineComponent({
    name: "AddDemandeTransfertPage",
    components: {
      BreadCrumb,
      AddDemandeTransfert,
    },
  });
</script>