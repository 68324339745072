<template>
  <AbonnementInfo />
</template>

<script lang="ts">
  import { defineComponent } from "vue";

  import AbonnementInfo from "../../components/Abonnements/AbonnementInfo.vue";

  export default defineComponent({
    name: "AbonnementInfoPage",
    components: {
      AbonnementInfo,
    },
  });
</script>