<template>
  <!-- <BreadCrumb PageTitle="Login" /> -->
  <CodeConfirmation />
</template>

<script lang="ts">
import { defineComponent } from "vue";

import CodeConfirmation from "../../components/Authentication/CodeConfirmation/CodeConfirmation.vue";

export default defineComponent({
  name: "CodeConfPage",
  components: {
    CodeConfirmation,
  },
});
</script>