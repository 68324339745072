<template>
  <div class="card mb-25 border-0 rounded-0 bg-white add-user-card">
    <div class="card-body p-15 p-sm-20 p-md-25 p-lg-30 letter-spacing">
      <Form ref="mpostBoxForm" @submit="payer" :validation-schema="mpostBoxSchema">
        <div class="row">
          <div class="col-md-4">
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">
                Commune
              </label>
              <Field name="commune" v-slot="{ field }">
                <Multiselect v-model="field.value" v-bind="field" :options="communesOptions" :searchable="true"
                  @change="communeChange($event)" placeholder="Rechercher une commune"
                  noResultText="Aucun enregistrement trouvé" noOptionsText="Tapez au moins deux caractères" />
              </Field>
              <ErrorMessage name="commune" class="text-danger" />
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">
                Bureau
              </label>
              <Field v-model="bureauselect" name="bureau" v-slot="{ field }">
                <Multiselect v-model="field.value" v-bind="field" :options=bureauxOptions @change="genererAdresse()"
                  placeholder="selectionner un bureau" noOptionsText="selectionner d'abord une région" />
              </Field>
              <ErrorMessage name="bureau" class="text-danger" />
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">
                Numéro de l'abonné
              </label>
              <Field name="numero" v-model="telephone" v-slot="{ field }">
                <vue-tel-input placeholder="Entrer le numéro de téléphone" v-model="field.value" v-bind="field"
                  defaultCountry="BJ" mode="international" @validate="validate"
                  class="shadow-none fs-md-15 text-black py-2">
                </vue-tel-input>
              </Field>
              <ErrorMessage name="numero" class="text-danger" />
              <div v-if="!validPhone" class="text-danger">Veuillez entrer un numéro correcte</div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">
                Adresse virtuelle
              </label>
              <Field readonly="true" v-model="adressePostales" name="adresseVirtuelle" type="text" aria-disabled="true"
                class="form-control shadow-none fs-md-15 text-black" placeholder="Adresse virtuelle" />
              <ErrorMessage name="adresseVirtuelle" class="text-danger" />
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">
                Frais d'abonnement
              </label>
              <Field name="loyer" v-model="montant" type="text" readonly="true"
                class="form-control shadow-none fs-md-15 text-black" placeholder="Entrer les frais d'abonnements" />
              <ErrorMessage name="loyer" class="text-danger" />
            </div>
          </div>
          <div class="col-md-12">
            <div class="d-flex align-items-center ">
              <button
                class="default-btn me-20 transition border-0 fw-medium text-white pt-10 pb-10 ps-25 pe-25 pt-md-11 pb-md-11 ps-md-35 pe-md-35 rounded-1 fs-md-15 fs-lg-16 bg-success"
                type="submit" :disabled="!validPhone">
                Envoyer sa demande
              </button>
              <router-link to="/liste-abonnements" class=" btn btn-danger transition border-0 lh-1 fw-medium"><i
                  class="flaticon-delete lh-1 me-1 position-relative top-2"></i>
                <span class="position-relative"></span>Annuler</router-link>
            </div>
          </div>
        </div>
      </Form>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, ref, onMounted, reactive, toRef } from 'vue';
import { Form, Field, ErrorMessage } from 'vee-validate';
import Multiselect from '@vueform/multiselect'
import * as Yup from 'yup';
import axios from 'axios';
import ApiService from '../../services/ApiService';
import { useRouter } from 'vue-router';
import { error, success } from '@/utils/utils';
import { VueTelInput } from 'vue3-tel-input'
import 'vue3-tel-input/dist/vue3-tel-input.css';
import Swal from "sweetalert2";
import { openKkiapayWidget, addKkiapayListener, removeKkiapayListener } from "kkiapay";
import { KKIAPAY } from '@/models/KKIAPAY';
// import { MpostBox } from '@/models/MpostBox';


export default defineComponent({
  name: "AddMpostBox",
  components: {
    Form,
    Field,
    ErrorMessage,
    Multiselect,
    VueTelInput
  },

  setup: () => {
    const mpostBoxSchema = Yup.object().shape({
      bureau: Yup.number().typeError('Veuillez entrer un nombre').required('Le bureau est obligatoire'),
      commune: Yup.number().typeError('Veuillez entrer un nombre').required('La commune est obligatoire'),
      numero: Yup.string().required(`Le numéro est obligatoire`),
      loyer: Yup.number().typeError('Veuillez entrer un nombre').required(`Le tarif est obligatoire`),
      adresseVirtuelle: Yup.string().required('L\'adresse virtuelle est obligatoire'),
    });


    const router = useRouter();
    // const mpostBoxForm = ref<MpostBox>();
    const bureauxOptions = ref([]);
    const demandesOptions = ref([]);
    const communesOptions = ref([]);
    const codepay = ref();
    const codeVirtuel = ref();
    const nationalnumlber = ref();
    const telephone = ref();
    const bureauselect = ref();
    const adressePostales = ref();
    const validPhone = ref<boolean>(false);
    const montant = ref(0);
    const etat = ref("");

    onMounted(() => {
      fetchCommune();
      fetchmonatant();
    });

    const addMpostBox = async (values, { resetForm }) => {
      values["etat"] = "En attente";
      ApiService.post("/mpostabonnements", values)
        .then(({ data }) => {
          if (data.code == 201) {
            success(data.message);
            resetForm();
            router.push({ name: "ListAbonnementsPage" });
          }
        }).catch(({ response }) => {
          //error(response.data.message);
        });
    };

    var check = reactive({
      typeError: "RAS",
      idTransaction: 0
    });
    async function successHandler(response, values, { resetForm }) {
      // Mise à jour de l'objet check avec les informations de la réponse de succès
      check.typeError = "SUCCESS";
      check.idTransaction = response.transactionId;

      values["idTransaction"] = check.idTransaction;
      values["mode"] = 2;
      values["check"] = check;
      // Bloc de code à exécuter lorsque le paiement est un succès
      if (check.typeError === "SUCCESS") {
        // Appel de la fonction addAbonnement et attente de son exécution
        await addMpostBox(values, { resetForm });
      } else {
        check.typeError = "RAS";
        check.idTransaction = 0;
      }

      // Retourne les données après l'exécution
      return { check, response };
    }

    async function failHandler(response) {
      // Mise à jour de l'objet check avec les informations de l'échec
      check.typeError = "ECHOUE";
      check.idTransaction = response.transactionId;

      check.typeError = "RAS";
      check.idTransaction = response.transactionId;

      // Retourne les données après l'exécution
      return { check, response };
    }

    const payer = async (values, { resetForm }) => {
      try {
        const result = await Swal.fire({
          text: "Vous êtes sur le point de faire un paiement d'une somme de " + values.loyer + " F CFA",
          icon: "warning",
          buttonsStyling: true,
          showCancelButton: true,
          confirmButtonText: "Payer",
          cancelButtonText: `Annuler`,
          heightAuto: false,
          customClass: {
            confirmButton: "btn btn-danger",
          },
        });

        if (result.isConfirmed) {
          KKIAPAY.kkia(values.loyer);
          //Attendre que le paiement soit effectué ou échoué
          await new Promise<void>((resolve) => {
            addKkiapayListener('failed', (event) => {
              failHandler(event);
              resolve();
            });

            addKkiapayListener('success', (event) => {
              successHandler(event, values, { resetForm });
              resolve();
            });
            const checkRef = toRef(check);
            let resolved = false;

            // Résoudre la promesse après avoir traité le résultat du paiement
            const intervalId = setInterval(() => {
              if (!resolved && checkRef.value.typeError !== "RAS") {
                clearInterval(intervalId);
                resolved = true;
                resolve(); // Ajoutez ici un argument (par exemple, undefined)
              }
            }, 500); // Vérifier toutes les 500 millisecondes
          });

        }
        // else {
        //   error("Ok d'accord on va patienter");
        // }

      } catch (error) {
        // Gérer les erreurs ici
      }
    };

    function onInput(phone, phoneObject, input) {
      //
    }

    function validate(phoneObject) {
      validPhone.value = phoneObject.valid;
      if (phoneObject.valid == true) {
        telephone.value = phoneObject.number;
        codepay.value = phoneObject.countryCallingCode;
        nationalnumlber.value = phoneObject.nationalNumber;
        genererAdresse();
      } else {
        telephone.value = "";
        codepay.value = "";
        nationalnumlber.value = "";
        adressePostales.value = "";
      }
    }

    const genererAdresse = async () => {
      if (bureauselect.value && telephone.value && nationalnumlber.value) {
        ApiService.get('/bureaus/' + bureauselect.value)
          .then(({ data }) => {
            const bureau = data.data
            codeVirtuel.value = bureau.codeVirtuelle
            adressePostales.value = codeVirtuel.value + nationalnumlber.value;
          })
          .catch((response) => {
            // error(response.data.message);
          });
      }
    }

    const fetchAgences = async (key) => {
      try {
        const response = await axios.get('/bureaus/recherche/' + key);
        const bureauxData = response.data.data;
        bureauxOptions.value = bureauxData.map((bureau) => ({
          value: bureau.id,
          label: bureau.nom,
        }));
        return bureauxOptions.value;
      } catch (error) {
        //
      }
    };

    function communeChange(value) {
      ApiService.get('/bureaux/commune/' + value)
        .then(({ data }) => {
          const donnee = data.data;
          if (donnee.length > 0) {
            bureauxOptions.value = donnee.map((bureau: any) => {
              return {
                label: bureau.nom,
                value: bureau.id,
              };
            });
          }
        })
        .catch((response) => {
          //error(response.data.message)
        });
    }

    const fetchCommune = async () => {
      ApiService.get('/communes')
        .then(({ data }) => {
          const donneee = data.data.data;
          communesOptions.value = donneee.map((commune: any) => {
            return {
              label: commune.libelle,
              value: commune.id,
            };
          });
        })
        .catch((response) => {
          //error(response.data.message);
        });
    }

    const fetchmonatant = async () => {
      try {
        const response = await axios.get('/parametres/key/AMPOSTBOX');
        montant.value = parseFloat(response.data.data.valeur);
        return demandesOptions.value;
      } catch (error) {
        //
      }
    };

    return {
      bureauselect,
      fetchAgences,
      mpostBoxSchema,
      addMpostBox,
      communeChange,
      genererAdresse,
      payer,
      adressePostales,
      telephone,
      validPhone,
      validate,
      onInput,
      montant,
      communesOptions,
      // mpostBoxForm, 
      bureauxOptions,
      demandesOptions,
      codeVirtuel,
      etat
    };
  },
});
</script>
