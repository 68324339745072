<template>
  <BreadCrumb PageTitle="Liste des demandes de changement de clé" />
  <ListDemandeChangements />
</template>

<script lang="ts">
import { defineComponent } from "vue";

import BreadCrumb from "../../components/Common/BreadCrumb.vue";
import ListDemandeChangements from "../../components/DemandeChangements/ListDemandeChangements.vue";


export default defineComponent({
  name: "ListDemandeChangementsPage",
  components: {
    BreadCrumb,
    ListDemandeChangements,
  },
});
</script>