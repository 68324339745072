<template>
  <BreadCrumb PageTitle="Liste des demandes d' attestations" />
  <ListDemandeAttestations />
</template>

<script lang="ts">
import { defineComponent } from "vue";

import BreadCrumb from "../../components/Common/BreadCrumb.vue";
import ListDemandeAttestations from "../../components/DemandeAttestations/ListDemandeAttestations.vue";


export default defineComponent({
  name: "ListDemandeAttestationsPage",
  components: {
    BreadCrumb,
    ListDemandeAttestations,
  },
});
</script>