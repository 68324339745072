<template>
  <!-- <BreadCrumb PageTitle="Login" /> -->
  <TelConfirmation />
</template>

<script lang="ts">
import { defineComponent } from "vue";

import TelConfirmation from "../../components/Authentication/TelephoneConfirmation/TelConfirmation.vue";

export default defineComponent({
  name: "TelConfPage",
  components: {
    TelConfirmation,
  },
});
</script>