<template>
  <div class="card mb-25 border-0 rounded-0 welcome-lms-courses-box">
    <div
      class="card-body ps-15 pe-15 ps-sm-20 pe-sm-20 ps-md-25 pe-md-25 ps-lg-30 pe-lg-30 ps-xl-40 pe-xl-40 letter-spacing"
    >
      <div class="row align-items-center">
        <div class="col-xxl-8" >
          <div class="content" >
            <span class="d-block fw-bold mb-5 mt-3 text-white" id="current-date">
              {{ formattedDate }}
            </span>
            <h2 class="fw-semibold mb-8 text-white">
              Content de vous revoir, <span class="fw-bold text-white">{{ userName }}</span>
            </h2>
            <p class="fs-md-15 fs-lg-16 text-white">
              Nous prenons en charge la gestion complète de vos boîtes postales.
            </p>
            <div class="row list justify-content-center" v-if="updates.some(update => update.title === 'ABONNEMENT' && update.number !== null)">
              <div
                class="col-lg-3 col-6 col-sm-3"
                v-for="update in updates"
                :key="update.id"
              >
                <div :class="['ps-15 pe-15 pt-15 pb-5 mt-15 mb-2', update.bgClass]" style="min-height: 92px;">
                  <span
                    class="d-block mb-6 fw-medium text-black-emphasis fs-13 text-uppercase"
                  >
                    {{ update.title }}
                  </span>
                  <h6 :class="['mb- fw-black', update.class]">
                    {{ update.number }}
                  </h6>
                </div>
              </div>
            </div>
            
            <div v-else>
                  <router-link to="/ajouter-abonnement" class="default-btn position-relative transition border-0 fw-medium text-white pt-8 pb-8 ps-20 pe-20 pt-md-8 pb-md-8 ps-md-25 pe-md-25 rounded-0 bg-success fs-md-15 fs-lg-16 d-inline-block me-10 mb-10 mb-lg-0 text-decoration-none">
                    <span class="position-relative"></span>M'abonner
                  </router-link>
            </div>

          </div>
        </div>
        <div class="col-xxl-4 text-center text-xxl-end mt-15 mt-md-0 ">
          <img
            src="../../../assets/images/welcomeImage.png"
            width="380"
            class="main-image"
            alt="welcome-image"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted } from "vue";
import stateStore from "../../../utils/store";
import { useRouter } from "vue-router";
import { useAuthStore, Abonne } from "@/services/auth";  
import JwtService from "@/services/JwtService";
import axios from 'axios';

export default defineComponent({
  name: "WhatHappening",
  setup() {
    const stateStoreInstance = stateStore;
    const isSticky = ref(false);
    const router = useRouter();
    const store = useAuthStore();
    const user = ref();
    const formattedDate = ref("");
    const soldes = ref([]);
    const updates = ref<Array<{
        id: number;
        bgClass: string;
        title: string;
        class: string;
        number: any 
        }>>([]);

    const userName = ref("");
    
    onMounted(async () => {
       user.value = store.getUserInfo;
      if(JwtService.getUser()){
        userName.value = JwtService.getUserName()!;
        const element = await getInformation(JwtService.getUser()!);
        updates.value =[
            {
              id: 1,
              bgClass: "bg-f2f1f9",
              title: "Demande en cours",
              class: "text-primary",
              number: element.nombre,
            },
            {
              id: 2,
              bgClass: "bg-faf7f7",
              title: "IMPAYE",
              class: "text-danger",
              number: element.impaye,
            },
            {
              id: 3,
              bgClass: "bg-ecf3f2",
              title: "ABONNEMENT",
              class: "text-success",
              number: element.abonnement,
            },
            {
              id: 4,
              bgClass: "bg-f3f7f9",
              title: "Adresse Postale",
              class: "text-info",
              number: element.adressePostale,
            },
        ]

      }
    });

    const getInformation = async (key) =>{
          const response = await axios.get(`/etats/abonne/info/${key}`);
          const donne = response.data.data;
          //updates.value = donne;
          return  donne;
      } 
    
    const months = [
        "Janvier",
        "Février",
        "Mars",
        "Avril",
        "Mai",
        "Juin",
        "Juillet",
        "Août",
        "Septembre",
        "Octobre",
        "Novembre",
        "Décembre",
      ];

    const currentDate = new Date();
    formattedDate.value = `${currentDate.getDate()}, ${ months[currentDate.getMonth()]} ${currentDate.getFullYear()}`;
    return {
      formattedDate,
      soldes,
      userName,
      isSticky,
      stateStoreInstance,
      user,
      updates
    };
  },
});
</script>