<template>
  <div class="row justify-content-center flex-align-middle">
    <div class="col-md-6 col-lg-6 col-xl-6 col-xxl-6 col-xxxl-6">
      <div class="card mb-25 border-0 rounded-0 bg-white authentication-card">
        <div class="card-body letter-spacing">
          <div class="text-center mb-10">
            <img src="../../../assets/images/lapostebj.png" alt="logo-icon" style="height: 100px;"/>
          </div>
          <h4 class="text-black fw-bold mb-0 text-center">Mot de passe oublié?</h4>
          <p class="mb-0 fs-md-15 fs-lg-16 text-paragraph text-center">
            Entrez votre téléphone ci-dessous et nous <br/>vous enverrons le code de confirmation
          </p>
          <Form ref="fgForm" @submit="forgotPass" :validation-schema="fgSchema">
            <div class="form-group mb-15 mb-sm-20 mb-md-25" v-if="!estEmail">
              <div
                  class="d-flex align-items-center justify-content-between mb-10"
                >
                  <label class="d-block text-black fw-semibold">
                    Numéro de téléphone
                  </label>
                  <a
                    @click="useEmail"
                    v-if="!estEmail"
                    class="forgot-password-btn fs-md-15 fs-lg-16 text-decoration-none position-relative text-primary"
                  >
                  Utilisé le mail
                </a>
                </div>
              <Field name="telephone" v-model="telephone"  v-slot="{ field }">
                  <vue-tel-input
                    placeholder="Ex: +229 97 00 00 00"
                    v-model = "field.value"
                    v-bind = "field"
                    defaultCountry="BJ"
                    mode="auto"
                    @validate="validate"
                    class ="shadow-none fs-md-15 text-black py-2">
                  </vue-tel-input>
                </Field>
                <ErrorMessage name="telephone" class="text-danger"/>
                <div v-if="!validPhone"  class="text-danger">Veuillez entrer un numéro correcte</div>
            </div>
            <div class="col-md-12" v-if="estEmail">
                <div class="form-group mb-15 mb-sm-20 mb-md-25">
                  <div
                  class="d-flex align-items-center justify-content-between mb-10"
                    >
                      <label class="d-block text-black fw-semibold">
                        Email
                      </label>
                      <a
                        @click="useEmail"
                        v-if="estEmail"
                        class="forgot-password-btn fs-md-15 fs-lg-16 text-decoration-none position-relative text-primary"
                      >
                      Utilisé le téléphone
                    </a>
                    </div>
                  <Field
                    name="email"
                    type="email"
                    class="form-control shadow-none fs-md-15 text-black"
                    placeholder="Entrer l' email"
                  />
                  <ErrorMessage name="email" class="text-danger" />
                </div>
              </div>
            <button
              class="text-decoration-none text-center default-btn transition border-0 fw-medium text-white rounded-1 fs-md-15 fs-lg-16 bg-success  w-100"
              type="submit" :disabled="!validPhone"
              v-if="!estEmail"
            >
              Envoyer
            </button>
            <button
              v-if="estEmail"
              class="text-decoration-none text-center default-btn transition border-0 fw-medium text-white rounded-1 fs-md-15 fs-lg-16 bg-success  w-100"
              type="submit" 
            >
              Envoyer
            </button>
            <div class="text-center mt-15 mt-sm-20 mt-md-25">
              <router-link
                to="/login"
                class="link-btn text-decoration-none text-primary fw-medium fs-md-15 fs-lg-16 position-relative"
              >
                <i class="ph-bold ph-arrow-left"></i>
                Revenir à la page de connexion
              </router-link>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>


<script lang="ts">
import { defineComponent, ref, onMounted } from 'vue';
import { Form, Field, ErrorMessage } from 'vee-validate';
import * as Yup from 'yup';
import Swal from 'sweetalert2';
import { VueTelInput } from 'vue3-tel-input'
import 'vue3-tel-input/dist/vue3-tel-input.css'
import ApiService from '@/services/ApiService';
import { useRouter } from 'vue-router';
import { error, success } from '@/utils/utils';
import JwtService, { saveUserPhone } from "@/services/JwtService";

export default defineComponent({
    name: "ForgotPassword",
    components: {
    Form,
    Field,
    ErrorMessage,
    VueTelInput
},
  setup: () => {
    const fgSchema = Yup.object().shape({
      //telephone: Yup.string().required('Le numéro de téléphone est obligatoire'),
      telephone: Yup.string().when([], (value, schema) => {
        return !estEmail.value
          ? schema
              .required("Le numéro de téléphone est obligatoire")
          : schema.notRequired();
      }),
      email: Yup.string().when([], (value, schema) => {
        return estEmail.value
          ? schema
              .email("L'email n'est pas valide")
              .required("L'email est obligatoire")
          : schema.notRequired();
      }),
    });

    const submitButton = ref<HTMLButtonElement | null>(null);
    const  codepay= ref();
    const  nationalnumlber= ref();
    const telephone=ref();
    const validPhone=ref<boolean>(false);
    const numberPhone=ref();
    const fgForm = ref(null);
    const  numeroNational = ref();
    const router = useRouter();

    function onInput(phone, phoneObject, input) {
      //
    }

    function validate(phoneObject) {
      validPhone.value=phoneObject.valid;
      if (phoneObject.valid == true) {
        telephone.value= phoneObject.number;
        codepay.value= phoneObject.countryCallingCode;
        nationalnumlber.value= phoneObject.nationalNumber;
        numberPhone.value=phoneObject.number;
        numeroNational.value = phoneObject.nationalNumber;
      }else{
        telephone.value="";
        codepay.value= "";
        nationalnumlber.value= "";
        numberPhone.value="";
      }
    }

    const forgotPass = async (values) => {
      values["estEmail"] = estEmail.value;
      console.log('jnndn', values)
      ApiService.post("/sendrestepasswordcode/abonner",values)
      .then(({ data }) => {
          if(data.code === 200) { 
            JwtService.saveUserPhone(telephone.value);
            success(data.message);
            router.push({ name: "CodeResetPage", query: { telephone: values.telephone, estEmail: values.estEmail, email: values.email } });
          }
        }).catch(({ response }) => {
          error(response.data.message);
        });
    }
   
    const estEmail = ref(false);
    const useEmail = () => {
      estEmail.value = !estEmail.value;
    };

    return { fgSchema, forgotPass, fgForm,submitButton, 
      telephone,
      validPhone,
      validate,
      onInput,
      useEmail,
      estEmail,
    };
  },
});
</script>