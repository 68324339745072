<template>
  <BreadCrumb PageTitle="Liste des demandes de résiliations" />
  <ListDemandeResiliations />
</template>

<script lang="ts">
import { defineComponent } from "vue";

import BreadCrumb from "../../components/Common/BreadCrumb.vue";
import ListDemandeResiliations from "../../components/DemandeResiliations/ListDemandeResiliations.vue";


export default defineComponent({
  name: "ListDemandeResiliationsPage",
  components: {
    BreadCrumb,
    ListDemandeResiliations,
  },
});
</script>