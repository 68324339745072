<template>
  <div class="row justify-content-center flex-align-middle">
    <div class="col-md-8 col-lg-6 col-xl-7 col-xxl-6 col-xxxl-5">
      <div class="card mb-25 border-0 rounded-0 bg-white authentication-card">
        <div class="card-body letter-spacing">
          <div class="text-center mb-10">
            <img src="../../../assets/images/lapostebj.png" alt="logo-icon" style="height: 100px;" />
          </div>
          <h4 class="text-black fw-bold mb-0 text-center">Réinitialiser le mot de passe?</h4>
          <p class="mb-0 fs-md-15 fs-lg-16 text-paragraph text-center sub-text">
            Entrez votre nouveau mot de passe et confirmez-le dans le champ en dessous
          </p>
          <Form ref="resetForm" @submit="resetPassword" :validation-schema="resetSchema">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <div class="d-flex align-items-center justify-content-between mb-10">
                  <label class="d-block text-black fw-semibold ">
                    Nouveau mot de passe
                  </label>
                </div>
                <div class="input-group has-validation">
                  <Field name="password" :type="showPassword ? 'password' : 'text'"
                    class="form-control shadow-none fs-md-15 text-black" placeholder="Entrer votre mot de passe" />
                  <span class="input-group-text" id="inputGroupPrepend">
                    <i :class="{ 'flaticon-eye': !showPassword, 'flaticon-visibility-off': showPassword }"
                      class="flaticon-eye" @click="toggleShow" :aria-label="buttonLabel"></i>
                  </span>
                </div>
                <ErrorMessage name="password" class="text-danger" />
              </div>
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <div class="d-flex align-items-center justify-content-between mb-10">
                  <label class="d-block text-black fw-semibold ">
                    Confirmer le mot de passe
                  </label>
                </div>
                <div class="input-group has-validation">
                  <Field name="newPassword" :type="showPassword2 ? 'password' : 'text'"
                    class="form-control shadow-none fs-md-15 text-black" placeholder="Confirmer le mot de passe" />
                  <span class="input-group-text" id="inputGroupPrepend">
                    <i :class="{ 'flaticon-eye': !showPassword2, 'flaticon-visibility-off': showPassword2 }"
                      class="flaticon-eye" @click="toggleShow2" :aria-label="buttonLabel2"></i>
                  </span>
                </div>
                <ErrorMessage name="newPassword" class="text-danger" />
              </div>
            <button
              class="text-decoration-none text-center default-btn transition border-0 fw-medium text-white rounded-1 fs-md-15 fs-lg-16 bg-success  w-100"
              type="submit">
              Validez
            </button>
            <div class="text-center mt-15 mt-sm-20 mt-md-25">
              <router-link to="/login"  class="link-btn text-decoration-none text-primary fw-medium fs-md-15 fs-lg-16 position-relative">
                <i class="ph-bold ph-arrow-left"></i>
                Revenir à la page de connexion
              </router-link>
            </div>
          </Form>
        </div>
      </div>
    </div>
  </div>
</template>


<script lang="ts">
import { defineComponent, ref, onMounted, watch, computed } from 'vue';
import { Form, Field, ErrorMessage } from 'vee-validate';
import * as Yup from 'yup';
import { useRouter, useRoute } from 'vue-router';
import { useAuthStore} from "@/services/auth";
import { error, success } from '@/utils/utils';
import ApiService from '@/services/ApiService';
import JwtService, { saveUserPhone } from "@/services/JwtService";

export default defineComponent({
    name: "ResetPassword",
    components: {
    Form,
    Field,
    ErrorMessage,
},
  setup: () => {
    const resetSchema = Yup.object().shape({
      password: Yup.string().min(8, 'Le mot de passe doit contenir au moins 8 caractères').required('Le mot de passe est obligatoire'),
      newPassword: Yup.string()
        .required('Le champ de confirmation est obligatoire')
        .oneOf([Yup.ref("password")], "Les champs de mot de passe et de confirmation de mot de passe doivent correspondre")
        .label("Confirmation de mot"),
    });

    const router = useRouter();
    const route = useRoute();
    const resetPass = ref(null);

    const authStore = useAuthStore();

    // const email = route.query.email;
    // const estEmail = route.query.estEmail === 'true';
    // const telephone = route.query.telephone as string;
    
    const resetPassword = async (values) => {
      // if(JwtService.getUserPhone()) {
      // values["telephone"] = JwtService.getUserPhone();    
      // }
      // else {
      //   values["telephone"]= telephone
      // }
      // values["email"]= email,
      // values["estEmail"]= estEmail,
      values["token"]= route.query.token;
      ApiService.post("/resetpassword/abonner",values)
      .then(({ data }) => {
          if(data.code === 200) { 
            success(data.message);
            router.push({ name: "LoginPage" });
          }
        }).catch(({ response }) => {
          error(response.data.message);
        });
    };

    const showPassword = ref(true);
    const password = ref(null);

    const toggleShow = () => {
      showPassword.value = !showPassword.value;
    };

    const buttonLabel = computed(() => (showPassword.value ? 'Hide' : 'Show'));

    const showPassword2 = ref(true);
    const toggleShow2 = () => {
      showPassword2.value = !showPassword2.value;
    };
    const buttonLabel2 = computed(() => (showPassword2.value ? 'Hide' : 'Show'));

    return { resetSchema,
      resetPassword,
      resetPass,
      authStore,
      showPassword,
      password,
      toggleShow,
      buttonLabel,
      showPassword2,
      toggleShow2,
      buttonLabel2,
      // email,
      // estEmail,
    };
  },
});
</script>

<style>
  .otp-input {
      width: 50px;
      height: 50px;
      font-size: 18px;
      text-align: center;
      margin: 0 5px;
      border: 1px solid #ccc;
      border-radius: 4px;
      margin-bottom: 20px;
  }
  .otp-input.is-complete {
  background-color: #e4e4e4;
  }
  .otp-input::-webkit-inner-spin-button,
  .otp-input::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  /* input::placeholder {
    font-size: 15px;
    text-align: center;
    font-weight: 600;
  } */
</style>