<template>
  <div class="row">
      <div class="col-md-6 col-xxl-6 col-sm-6 mb-10">
        <div class="card mb-25 border-0 rounded-0">
          <div class="card-body p-15 p-sm-20 p-md-25 p-lg-30 letter-spacing">
            <h4 class="text-black fw-bold mb-10">Informations sur l'abonnement</h4>
            <table class="table">
              <tbody>
                <tr v-if="abonnement?.refAbo">
                  <td>Référence</td>
                  <td>{{ abonnement?.refAbo }}</td>
                </tr>
                <tr v-if="abonnement?.demande?.refDemande">
                  <td>Demande</td>
                  <td>{{ abonnement?.demande.refDemande }}</td>
                </tr>
                <tr v-if="abonnement?.adresseVirtuelle">
                  <td>Adresse virtuelle</td>
                  <td>{{ abonnement?.adresseVirtuelle }}</td>
                </tr>
                <tr v-if="abonnement?.adressePostale?.adresse_postale">
                  <td>Adresse postale</td>
                  <td>{{ abonnement?.adressePostale.adresse_postale }}</td>
                </tr>
                <tr v-if="abonnement?.loyer">
                  <td>Loyer</td>
                  <td>{{ separateur(abonnement?.loyer) }}</td>
                </tr>
                <tr v-if="abonnement?.caution">
                  <td>Caution</td>
                  <td>{{ separateur(abonnement?.caution) }}</td>
                </tr>
                <tr v-if="abonnement?.fraisCle">
                  <td>Frais clé</td>
                  <td>{{ separateur(abonnement?.fraisCle) }}</td>
                </tr>
                <tr v-if="abonnement?.montant">
                  <td>Tarif</td>
                  <td>{{ separateur(abonnement?.montant) }}</td>
                </tr>
                <tr v-if="abonnement?.numero">
                  <td>Numéro</td>
                  <td>{{ abonnement?.numero }}</td>
                </tr>
                <tr v-if="abonnement?.nombreCle">
                  <td>Nombre clé</td>
                  <td>{{ abonnement?.nombreCle }}</td>
                </tr>
                <tr v-if="abonnement?.typeBoite">
                  <td>Type de boîte</td>
                  <td>{{ abonnement?.typeBoite }}</td>
                </tr>
               
                <tr v-if="abonnement?.createdAt">
                  <td>Date d'abonnement</td>
                  <td>{{ format_date(abonnement? abonnement.createdAt : null)  }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div class="col-md-6 col-xxl-6 col-sm-6 mb-10">
        <div class="card mb-25 border-0 rounded-0">
          <div class="card-body p-15 p-sm-20 p-md-25 p-lg-30 letter-spacing">
            <h4 class="text-black fw-bold mb-10">Information sur l'abonné</h4>
            <table class="table">
              <tbody>
                <tr>
                  <td>Nom et prénoms</td>
                  <td>{{ abonnement?.abonne?.nomComplet }}</td>
                </tr>
                <tr>
                  <td>Nationalité</td>
                  <td>{{ abonnement?.abonne?.nationalite }}</td>
                </tr>
                <tr>
                  <td>Téléphone</td>
                  <td>{{ abonnement?.abonne?.telephone }}</td>
                </tr>
                <tr>
                  <td>Date de naissance</td>
                  <td>{{ format_date(abonnement?.abonne?.dateNaissance) }}</td>
                </tr>
                <!-- <tr>
                  <td>Date de dernière connection</td>
                  <td>{{ format_date(abonnement?.abonne?.datelastLogin) }}</td>
                </tr> -->
                <tr>
                  <td>Email</td>
                  <td>{{ abonnement?.abonne?.email }}</td>
                </tr>
                <tr>
                  <td>Sexe</td>
                  <td>{{ abonnement?.abonne?.sexe }}</td>
                </tr>
                <tr>
                  <td>Profession</td>
                  <td>{{ abonnement?.abonne?.profession }}</td>
                </tr>
                <tr>
                  <td>Adresse de résidence</td>
                  <td>{{ abonnement?.abonne?.adresseResidence }}</td>
                </tr>
                <tr>
                  <td>Ville de résidence</td>
                  <td>{{ abonnement?.abonne?.villeResidence }}</td>
                </tr>
                <tr>
                  <td>Catégorie de l'abonné</td>
                  <td>{{ abonnement?.abonne?.categorieAbonne?.libelle }}</td>
                </tr>
                <tr>
                  <td>Secteur d'activité</td>
                  <td>{{ abonnement?.abonne?.activite?.libelle }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div class="col-md-12 col-xxl-12 col-sm-12 mb-10">
        <div class="card mb-25 border-0 rounded-0">
          <div class="card-body p-15 p-sm-20 p-md-25 p-lg-30 letter-spacing">
            <h4 class="text-black fw-bold mb-10">Information sur la boîte postale</h4>
            <table class="table">
              <tbody>
                <tr>
                  <td><b>Référence</b></td>
                  <td>{{ abonnement?.boite?.refBoite }}</td>
                  <td><b>Adresse postale</b></td>
                  <td>{{ abonnement?.boite?.refBoite }}</td>
                </tr>
                <tr v-if="abonnement?.bureau?.nom">
                  <td><b>Agence</b></td>
                  <td>{{ abonnement?.bureau?.nom }}</td>
                  <td><b>Etat</b></td>
                  <td>{{ abonnement?.boite?.etat }}</td>
                </tr>
                <tr>
                  <td><b>Couleur</b></td>
                  <td>{{ abonnement?.boite?.couleur }}</td>
                  <td><b>Dimension</b></td>
                  <td>{{ abonnement?.boite?.dimension }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from "vue";
import ApiService from "@/services/ApiService";
import { Abonnements } from "@/models/Abonnement";
import { error, format_date, separateur } from "@/utils/utils";
import { useRoute } from "vue-router";

export default defineComponent({
  name: "ViewAbonnement",
  setup: () => {
    const route = useRoute();
    const abonnement = ref<Abonnements | null>(null);

    function getAbonnement(id: string) {
      return ApiService.get("/abonnements", id)
        .then(({ data }) => {
          abonnement.value = data.data; 
        })
        .catch(({ response }) => {
          error(response.data.message);
        });
    } 

    onMounted(() => {
      const id = route.params.id as string;
      getAbonnement(id);
    });

    return { abonnement, getAbonnement, format_date, separateur };
  },
});
</script>
