<template>
  <div class="row">
    <div class="col-6">
      <Form ref="redeForm" @submit="payer" :validation-schema="redeSchema">
        <div class="card mb-25 border-0 rounded-0 bg-white letter-spacing">
          <div class="card-body p-15 p-sm-20 p-md-25 p-lg-30">
            <h5 class="card-title fw-bold mb-15 mb-lg-20 mb-xl-25">
            </h5>
            <div v-show="messagePromotion != ''" class="alert alert-primary fs-md-15 fs-lg-16" role="alert">
              {{ messagePromotion }}
            </div>
            <div v-show="messagePenalite != ''" class="alert alert-danger fs-md-15 fs-lg-16" role="alert">
              {{ messagePenalite }}
            </div>
            <div class="border p-2 p-lg-4 border-dark border-opacity-10">
              <!--
                    <div class="mb-15 mb-md-20">
                    <label
                      for="bureau"
                      class="form-label fw-medium text-black"
                    >
                    BUREAU/RELAIS
                    </label>
                    <Field name="bureau" v-model="bureau" class="form-control shadow-none fs-md-15 text-black" v-slot="{ field }">
                    <Multiselect 
                    v-model = "field.value"
                      v-bind = "field"
                      :filter-results="false"
                      :min-chars="2"
                      :resolve-on-load="false"
                      :delay="0"
                      :searchable="true"
                      :options-limit="300"
                      :options="bureauxOptions"
                      noResultText= "Aucun enregistrement trouvé"
                      noOptionsText="Tapez au moins trois caractères"
                      placeholder="Rechercher une agence"
                    />
                  </Field>
                  <ErrorMessage name="bureau" class="text-danger"/>
                  </div>-->
              <div class="mb-15 mb-md-20">
                <label for="abonnement" class="form-label fw-medium text-black">
                  ABONNEMENT
                </label>
                <Field name="abonnement" v-model="abonnement" class="form-control shadow-none fs-md-15 text-black"
                  v-slot="{ field }">
                  <Multiselect v-model="field.value" v-bind="field" :filter-results="false" :resolve-on-load="false"
                    :searchable="true" :options="abonnementOptions" noResultText="Aucun enregistrement trouvé"
                    placeholder="Rechercher un abonnement" />
                </Field>
                <ErrorMessage name="abonnement" class="text-danger" />
              </div>
              <!-- <div class="mb-15 mb-md-20">
                    <div class="form-group mb-15 mb-sm-20 mb-md-25">
                      <label
                      class="form-label fw-medium text-black"
                    >
                      Mode de paiement
                    </label>
                      <Field name="mode" v-slot="{ field }" v-model="mode">
                        <Multiselect 
                          :options="modeOptions"
                          :disabled="true" 
                          :searchable="true"
                          v-model="field.value"
                          v-bind="field"
                          placeholder="Sélectionner un mode"
                        />
                      </Field>
                      <ErrorMessage name="mode" class="text-danger"/>
                    </div>
                  </div> -->
              <div class="mb-15 mb-md-20">
                <div class="row">
                  <div class="col-md-6">
                    <label for="duree" class="form-label fw-medium text-black">
                      Durée
                    </label>
                    <Field name="duree" v-model="duree" class="form-control shadow-none fs-md-15 text-black" />
                    <ErrorMessage name="duree" class="text-danger" />
                  </div>
                  <div class="col-md-6">
                    <label for="montant" class="form-label fw-medium text-black">
                      Montant
                    </label>
                    <Field name="montant" v-model="montant" :disabled="true"
                      class="form-control shadow-none fs-md-15 text-black" />
                    <ErrorMessage name="montant" class="text-danger" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card-footer border p-2 p-lg-4 border-dark border-opacity-10">
            <div class="row">
              <div class="col-md-6">
                <button type="submit" class="btn btn-primary">
                  Se réabonner
                </button>
              </div>
              <div class="col-md-6">
                <router-link to="/liste-paiementRedevances" class="btn btn-danger"><i
                    class="flaticon-delete lh-1 me-1 position-relative top-2"></i>
                  <span class="position-relative"></span>Annuler</router-link>
              </div>
            </div>
          </div>
        </div>
      </Form>

    </div>
    <div class="col-6">
      <div class="card mb-25 border-0 rounded-0 bg-white">
        <div class="card-body p-15 p-sm-20 p-md-25 p-lg-30 letter-spacing">
          <div class="mb-15 mb-md-30 d-sm-flex align-items-center justify-content-between">
            <h6 class="card-title fw-bold mb-0">REDEVANCES</h6>
            <div class="card-select mt-10 mt-sm-0 mb-10 mb-sm-0 d-flex align-items-center ps-10 pe-10 pt-5 pb-5">
              <span class="fw-medium text-muted me-8">TOTAL IMPAYE : {{ somRedevance }}</span>
            </div>
          </div>
          <div class="table-responsive">
            <table class="table text-nowrap align-middle mb-0">
              <thead>
                <tr>
                  <th scope="col" class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0 ps-0">
                    #
                  </th>
                  <th scope="col" class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0">
                    Année
                  </th>
                  <th scope="col" class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0">
                    Loyer
                  </th>
                  <th scope="col" class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0">
                    Date Redevance
                  </th>
                  <th scope="col" class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0">
                    Etat
                  </th>
                </tr>
              </thead>
              <tbody v-if="redevances">
                <tr v-for="(redv, index) in redevances" :key="index">
                  <th class="shadow-none lh-1 fw-bold ps-0">
                    {{ index + 1 }}
                  </th>
                  <td class="shadow-none lh-1 fw-semibold text-dark">
                    {{ redv.annee }}
                  </td>
                  <td class="shadow-none lh-1 fw-semibold text-dark">
                    {{ separateur(redv.montant) }}
                  </td>
                  <td class="shadow-none lh-1 fw-medium text-body-tertiary">
                    {{ format_date(redv.dateRedevance) }}
                  </td>
                  <td class="shadow-none lh-1 fw-medium text-body-tertiary">
                    <span class="badge text-outline-danger">En attente</span>
                  </td>
                </tr>
              </tbody>
              <tbody v-else>
                <tr>
                  <th class="shadow-none lh-1 fw-bold ps-0" colspan="4">
                    AUCUNE REDEVANCE TROUVE
                  </th>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, watch, reactive, toRef, onMounted } from 'vue';
import { Form, Field, ErrorMessage } from 'vee-validate';
import { error, success, format_date, getUrlApiForFiles, separateur } from '@/utils/utils';
import { useRouter } from "vue-router";
import ApiService from '@/services/ApiService';
import * as Yup from 'yup';
import Multiselect from '@vueform/multiselect'
import axios from 'axios';
import { Redevance2 } from '@/models/Redevance';
import { useAuthStore, Abonne } from "@/services/auth";
import JwtService from "@/services/JwtService";
import { openKkiapayWidget, addKkiapayListener, removeKkiapayListener } from "kkiapay";
import Swal from "sweetalert2";
import { KKIAPAY } from '@/models/KKIAPAY';
import { Abonnement } from '@/models/Abonnement';

export default defineComponent({
  name: "AddCasier",
  components: {
    Form,
    Field,
    ErrorMessage,
    Multiselect,
  },
  setup: () => {
    const redeSchema = Yup.object().shape({
      montant: Yup.number().typeError("Renseigner une valeur numérique").required('Le montant est obligatoire'),
      duree: Yup.number().typeError("Renseigner une valeur numérique").min(1, "La valeur saisie doit être >= 1").required('Le mode est obligatoire'),
      // mode: Yup.number().typeError("Renseigner une valeur numérique").required('Le mode est obligatoire'),
      abonnement: Yup.number().typeError("Renseigner une valeur numérique").required('L\' abonnement est obligatoire'),
    });
    const store = useAuthStore();
    const user = ref();
    const router = useRouter();
    const abonnementOptions = ref([]);
    const abonnement = ref(null);
    const imageBaseUrl = "http://192.168.8.140:3001/uploads";
    const redevances = ref<Array<Redevance2>>([]);
    const duree = ref(1);
    const montantAb = ref(0);
    const somRedevance = ref(0);
    const loyer = ref(0);
    const total = ref(0);
    const montant = ref(0);
    const categorieAbonneId = ref(0);
    const messagePromotion = ref("");
    const messagePenalite = ref("");
    const tauxPromotion = ref(0);


    const getAbonnementByAbonne = async (key) => {
      const response = await axios.get(`/abonnements/abonne`);
      const abonnemnt = response.data.data.data;
      console.log("yesy yesy ", abonnemnt, categorieAbonneId.value);
      abonnementOptions.value = abonnemnt.map((abo) => ({
        value: abo.id,
        label: abo.boite ? abo.refAbo + " " + abo.abonne?.nomComplet + " [ " + abo.abonne?.telephone + " ]" + " [" + abo.boite?.refBoite + "]" + " [" + abo.boite?.adressePostale?.adresse_postale + "]" : abo.refAbo + " " + abo.abonne?.nomComplet + " [ " + abo.abonne?.telephone + " ]" + " [" + abo.adresseVirtuelle + "]",
      }));
      return abonnementOptions.value;
    }

    onMounted(() => {
      fetchModePaiement();
      user.value = JwtService.getUser();
      if (user.value) {
        getAbonnementByAbonne(user.value);
      }
    })

    const modeOptions = ref([]);
    const mode = ref(2);

    const fetchModePaiement = async () => {
      try {
        const response = await axios.get('/modepaiements');
        const modesData = response.data.data.data;
        modeOptions.value = modesData.map((mode) => ({
          value: mode.id,
          label: mode.libelle,
        }));
        return modeOptions.value;
      } catch (error) {
        //
      }
    };

    const fetchAbonnement = async (key) => {
      try {
        const response = await axios.get('/abonnements/' + key);
        const abonnementsData = response.data.data;
        return abonnementsData;
      } catch (error) {
        //
      }
    };

    const fetchRedevances = async (key) => {
      try {
        const response = await axios.get('/redev/non/abonnement/' + key);
        const abonnementsData = response.data.data;
        return abonnementsData;
      } catch (error) {
        //
      }
    };


    const som = (varia) => {
      somRedevance.value += parseInt(varia);
    }
    watch(abonnement, async (newValue, oldValue) => {
      if (newValue) {
        fetchReductionCampagne();
      }
      somRedevance.value = 0;
      abonnement.value = newValue;
      const val = await fetchAbonnement(newValue);
      loyer.value = val ? val.loyer : 0;
      montant.value = val ? (duree.value * loyer.value) : 0;
      montantAb.value = val ? val.montant : 0;
      redevances.value = await fetchRedevances(newValue);
      let tail = redevances.value;
      for (let i = 0; i < tail.length; i++) {
        if ((tail[i])) {
          somRedevance.value += (tail[i]).montant ? tail[i].montant : 0;
        }

      }
    });

    watch(duree, async (newValue, oldValue) => {
      if (isNaN(newValue)) {
        newValue = 0;
      }
      montant.value = (loyer.value ? loyer.value : 0) * (newValue ? newValue : 0);
    });

    var check = reactive({
      typeError: "RAS",
      idTransaction: 0
    });

    async function successHandler(response, values, { resetForm }) {
      // Mise à jour de l'objet check avec les informations de la réponse de succès
      check.typeError = "SUCCESS";
      check.idTransaction = response.transactionId;

      values["idTransaction"] = check.idTransaction;
      values["mode"] = 2;
      values["check"] = check;
      // Bloc de code à exécuter lorsque le paiement est un succès
      if (check.typeError === "SUCCESS") {
        // Appel de la fonction addAbonnement et attente de son exécution
        await addAPaiementRedev(values, { resetForm });
      } else {
        check.typeError = "RAS";
        check.idTransaction = 0;
      }

      // Retourne les données après l'exécution
      return { check, response };
    }

    async function failHandler(response) {
      // Mise à jour de l'objet check avec les informations de l'échec
      check.typeError = "ECHOUE";
      check.idTransaction = response.transactionId;

      check.typeError = "RAS";
      check.idTransaction = response.transactionId;

      // Retourne les données après l'exécution
      return { check, response };
    }


    const payer = async (values, { resetForm }) => {
      // if(values.mode == 2){
      try {
        const result = await Swal.fire({
          text: "Vous êtes sur le point de faire un paiement d'une somme de " + values.montant + " F CFA",
          icon: "warning",
          buttonsStyling: true,
          showCancelButton: true,
          confirmButtonText: "Payer",
          cancelButtonText: `Annuler`,
          heightAuto: false,
          customClass: {
            confirmButton: "btn btn-danger",
          },
        });

        if (result.isConfirmed) {
          values["mode"] = 2;
          KKIAPAY.kkia(values.montant);
          await new Promise<void>((resolve) => {
            addKkiapayListener('failed', (event) => {
              failHandler(event);
              resolve();
            });

            addKkiapayListener('success', (event) => {
              successHandler(event, values, { resetForm });
              resolve();
            });

            const checkRef = toRef(check);

            let resolved = false;

            // Résoudre la promesse après avoir traité le résultat du paiement
            const intervalId = setInterval(() => {
              if (!resolved && checkRef.value.typeError !== "RAS") {
                clearInterval(intervalId);
                resolved = true;
                resolve();
              }
            }, 500);
          });
        }

      } catch (error) {
        // Gérer les erreurs ici
      }
      // }
    };

    // reccuperation de la campagne de reduction en cours
    const fetchReductionCampagne = async () => {
      try {
        const response = await axios.get(`/campagne/reduction/active?id=${categorieAbonneId.value}&`);
        const reductionCampagneData = response.data.data;
        messagePromotion.value = "Une promotion de " + reductionCampagneData.taux + " % est accordée pour tout paiement effectué avant le " + format_date(reductionCampagneData.dateFin);
        tauxPromotion.value = reductionCampagneData.taux;
      } catch (error) {
        // 
      }
    };

    const addAPaiementRedev = async (values, { resetForm }) => {
      values["mode"] = 2;
      ApiService.post("/paiement_redevance/by/redevance", values)
        .then(async ({ data }) => {
          if (data.code == 201) {
            success(data.message);
            router.push({ name: "ListPaiementRedevancesPage" });
            var fullUrl = imageBaseUrl + "/Recu/" + data.data.refPaiement + ".pdf"
            router.push({ name: "ListePaiementPage" });
            const result = await Swal.fire({
              html: `<div>Cliquer sur ce lien pour télécharger votre document <br> <a href="${fullUrl}" _target="blank">Fichier ICI</a></div>`,
              icon: "success",
              buttonsStyling: true,
              showCancelButton: true,
              confirmButtonText: `Télécharger`,
              cancelButtonText: `Fermer`,
              heightAuto: false,
              customClass: {
                confirmButton: "btn btn-danger",
              },
            });
            if (result.isConfirmed) {
              window.open(fullUrl, '_blank');
            } else {
              //
            }
          }
        }).catch(({ response }) => {
          // error(response.data.message);
        });
    };
    const redeForm = ref();

    return {
      redeSchema,
      abonnementOptions,
      redeForm,
      som, payer,
      somRedevance,
      abonnement,
      redevances,
      montant,
      separateur,
      duree,
      format_date,
      modeOptions,
      mode,
      montantAb,
      messagePromotion,
      messagePenalite,
      tauxPromotion,
    };
  },
});
</script>