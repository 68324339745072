<template>
    <BreadCrumb PageTitle="Demander un abonnement mpostbox" />
    <AddMpostBox />
  </template>
  
  <script lang="ts">
    import { defineComponent } from "vue";
  
    import BreadCrumb from "../../components/Common/BreadCrumb.vue";
    import AddMpostBox from "../../components/MpostBox/AddMpostBox.vue";
  
    export default defineComponent({
      name: "AddMpostBoxPage",
      components: {
        BreadCrumb,
        AddMpostBox,
      },
    });
  </script>