<template>
  <BreadCrumb PageTitle="Faire une demande d' abonnement" />
  <AddAbonnement />
</template>

<script lang="ts">
  import { defineComponent } from "vue";

  import BreadCrumb from "../../components/Common/BreadCrumb.vue";
  import AddAbonnement from "../../components/Abonnements/AddAbonnement.vue";

  export default defineComponent({
    name: "AddAbonnementPage",
    components: {
      BreadCrumb,
      AddAbonnement,
    },
  });
</script>