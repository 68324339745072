<template>
  <BreadCrumb PageTitle="Liste des paiements de redevances" />
  <ListPaiementRedevances />
</template>

<script lang="ts">
import { defineComponent } from "vue";

import BreadCrumb from "../../components/Common/BreadCrumb.vue";
import ListPaiementRedevances from "../../components/Paiements/ListPaiementRedevances.vue";


export default defineComponent({
  name: "ListPaiementRedevancesPage",
  components: {
    BreadCrumb,
    ListPaiementRedevances,
  },
});
</script>