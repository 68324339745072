<template>
  <div class="col-md-8 col-xxl-8 col-sm-8 mb-10">
    <div class="card mb-25 border-0 rounded-0">
      <div class="card-body p-15 p-sm-20 p-md-25 p-lg-30 letter-spacing">
        <h4 class="text-black fw-bold mb-10">Mes informations</h4>
        <Form ref="abonneForm"   @submit="editAbonne" :validation-schema="abonneSchema" :initial-values="abonneForm">
          <div class="row">
              <div class="col-md-6">
                <div class="form-group mb-15 mb-sm-20 mb-md-25">
                  <label class="d-block text-black fw-semibold mb-10">
                    Nom et Prénoms
                  </label>
                  <Field name="nomComplet" type="text" 
                  class="form-control shadow-none fs-md-15 text-black"/>
                  <ErrorMessage name="nomComplet" class="text-danger"/>
                </div>
              </div>
              <div class="col-md-6">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="d-block text-black fw-semibold mb-10">
                  Telephone
                </label>
                <Field name="telephone" type="text" 
                  class="form-control shadow-none fs-md-15 text-black"/>
                  <ErrorMessage name="telephone" class="text-danger"/>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="d-block text-black fw-semibold mb-10">
                  Sexe {{  sexeAbonne  }}
                </label>
                <Field  name="sexe" v-model="sexeAbonne"  type="text"  v-slot="{ field }">
                  <Multiselect
                    :searchable = "true"
                    :options = "['Masculin', 'Féminin']"
                    v-model = "field.value"
                    v-bind = "field"
                  />
                </Field>
                  <ErrorMessage name="sexe" class="text-danger"/>
              </div>
            </div>
            <div class="col-md-6">
                <div class="form-group mb-15 mb-sm-20 mb-md-25">
                  <label class="d-block text-black fw-semibold mb-10">
                    Pays de résidence
                  </label>
                  <Field  name="nationalite" v-model="nationalite"  v-slot="{ field }">
                  <Multiselect
                    :options="countries"
                    :searchable="true"
                    track-by="value"
                    label="label"
                    v-model = "field.value"
                    v-bind = "field"
                    placeholder="Sélectionner le pays de résidence"
                  />
                </Field> 
                  <ErrorMessage name="nationalite" class="text-danger"/>
                </div>
              </div>
            <div class="col-md-6">
                <div class="form-group mb-15 mb-sm-20 mb-md-25">
                  <label class="d-block text-black fw-semibold mb-10">
                    Secteur d'activité
                  </label>
                  <Field  name="activite"  v-slot="{ field }">
                    <Multiselect
                    :options="secteurActiviteOptions"
                    :searchable="true"
                    track-by="value"
                    label="label"
                    v-model = "field.value"
                    v-bind = "field"
                    placeholder="Sélectionner le secteur d'activité"
                  />
                  </Field>
                  <ErrorMessage name="activite" class="text-danger"/>
                </div>
              </div>
            <div class="col-md-6">
                <div class="form-group mb-15 mb-sm-20 mb-md-25">
                  <label class="d-block text-black fw-semibold mb-10">
                    Catégorie d'abonné {{ categorieAbonneId }}
                  </label>
                  <Field name="categorieAbonne" v-model="categorieAbonneId"  v-slot="{ field }" readonly="true">
                    <Multiselect 
                      :options="categorieAbonneOptions" 
                      track-by="value"
                      v-model="field.value"
                      v-bind="field"
                    />
                  </Field>
                  <ErrorMessage name="categorieAbonne" class="text-danger"/>
                </div>
              </div>
            <div class="col-md-6">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="d-block text-black fw-semibold mb-10">
                  Adresse Mail
                </label>
                <Field name="email" type="email"
                  class="form-control shadow-none fs-md-15 text-black"/>
                  <ErrorMessage name="email" class="text-danger"/>
              </div>
            </div>
              <div class="col-md-6">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="d-block text-black fw-semibold mb-10">
                  Profession
                </label>
                <Field name="profession" type="text" 
                  class="form-control shadow-none fs-md-15 text-black"/>
                  <ErrorMessage name="profession" class="text-danger"/>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="d-block text-black fw-semibold mb-10">
                  Date de naissance
                </label>
                <Field name="dateNaissance" type="date" 
                class="form-control shadow-none rounded-0 text-black"/>
                  <ErrorMessage name="dateNaissance" class="text-danger"/>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="d-block text-black fw-semibold mb-10">
                  Département de résidence
                </label>
                <Field name="departementResidence" type="text" 
                class="form-control shadow-none rounded-0 text-black"/>
                  <ErrorMessage name="departementResidence" class="text-danger"/>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="d-block text-black fw-semibold mb-10">
                  Adresse de résidence
                </label>
                <Field name="adresseResidence" type="text" 
                class="form-control shadow-none rounded-0 text-black"/>
                  <ErrorMessage name="adresseResidence" class="text-danger"/>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="d-block text-black fw-semibold mb-10">
                  Ville de résidence
                </label>
                <Field name="villeResidence" type="text" 
                class="form-control shadow-none rounded-0 text-black"/>
                  <ErrorMessage name="villeResidence" class="text-danger"/>
              </div>
            </div>
            <div class="col-md-12">
              <div class="d-flex align-items-center ">
                <button
                  class="default-btn me-20 transition border-0 fw-medium text-white pt-10 pb-10 ps-25 pe-25 pt-md-11 pb-md-11 ps-md-35 pe-md-35 rounded-1 fs-md-15 fs-lg-16 bg-success"
                  type="submit"
                >
                    Modifier
                </button>
                <router-link to="/tableau_bord" 
                class=" btn btn-danger transition border-0 lh-1 fw-medium"><i class="flaticon-delete lh-1 me-1 position-relative top-2"></i>
                <span class="position-relative"></span>Annuler</router-link>
              </div>
            </div>
          </div>
        </Form>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted } from 'vue';
import { Form, Field, ErrorMessage } from 'vee-validate';
import Multiselect from '@vueform/multiselect'
import * as Yup from 'yup';
import axios from 'axios';
import ApiService from '@/services/ApiService';
import { error, success } from '@/utils/utils';
import { countries } from './countries';
import { useRoute, useRouter } from 'vue-router';
import { Abonne } from '@/models/Abonne';
import JwtService from "@/services/JwtService";

export default defineComponent({
  name: "ProfileContent",
  components: {
    Form,
    Field,
    ErrorMessage,
    Multiselect,
  },
  setup: () => {
    const abonneSchema = Yup.object().shape({
      nomComplet: Yup.string().required('Le nom est obligatoire'),
      nationalite: Yup.string().required('La nationalité est obligatoire'),
      telephone: Yup.string().required('Le téléphone est obligatoire'),
      dateNaissance: Yup.date().notRequired(),
      email: Yup.string().email().notRequired(),
      sexe: Yup.string().required('Le sexe est obligatoire'),
      profession: Yup.string().notRequired(),
      adresseResidence: Yup.string().notRequired(),
      villeResidence: Yup.string().notRequired(),
      departementResidence: Yup.string().notRequired(),
      categorieAbonne: Yup.number().typeError('Veuillez entrer un nombre').required('La catégorie de l\'abonnée est obligatoire'),
      activite: Yup.number().typeError('Veuillez entrer un nombre').required('Le secteur d\'activité est obligatoire'),
    });

    const abonneForm = ref<Abonne>();
    const categorieAbonnes = ref(null);
    const categorieAbonneOptions = ref([]);
    const secteurActivites = ref(null);
    const secteurActiviteOptions = ref([]);
    const countriesRef = ref(countries);
    const categorieAbonneId = ref(0);
    const nationalite = ref('');
    const sexeAbonne = ref('');
    const router = useRouter();
    const route = useRoute();

    onMounted(() => {
      if (JwtService.getUserId()) {
        getAbonne(JwtService.getUserId());
      }
      fetchCategorieAbonnes();
      fetchSecteurActivites();
    });

    const getAbonne = async (id: string | null) => {
      // ApiService.get("/users/" + id)
      //   .then(({ data }) => {
      //     for (const key in data.data) {
      //       abonneForm.value?.setFieldValue(
      //         key,
      //         typeof data.data[key] === 'object' && data.data[key] !== null
      //           ? data.data[key].id
      //           : data.data[key]
      //       );
      //     }
      //     categorieAbonneId.value = data.data.abonne?.categorieAbonne?.id;
      //     console.log("la valeur est là", categorieAbonneId.value);
      //     nationalite.value = data.data.abonne?.nationalite;
      //   })
      //   .catch(({ response }) => {
      //     error(response.message);
      //     //router.push({ name: "abonneForms-liste" });
      //   });
      try {
        const { data } = await ApiService.get("/user");
        for (const key in data.data) {
          abonneForm.value?.setFieldValue(
            key,
            typeof data.data[key] === 'object' && data.data[key] !== null
              ? data.data[key].id
              : data.data[key]
          );
        }
        categorieAbonneId.value = data.data.abonne?.categorieAbonne?.id;
        console.log("la valeur est là", categorieAbonneId.value);
        nationalite.value = data.data.abonne?.nationalite;
        sexeAbonne.value = data.data.abonne?.sexe;
      } catch (error) {
        // error(error.response.message);
        //router.push({ name: "abonneForms-liste" });
      }

    }
    
    const editAbonne = async (values, {resetForm}) => {
      ApiService.put("/user",values)
        .then(({ data }) => {
          if(data.code == 200) { 
            success(data.message);
            resetForm();
            router.push({ name: "tableauBordPage" });
          }
        }).catch(({ response }) => {
          error(response.data.message);
      });
    };

    const fetchCategorieAbonnes = async () => {
      try {
        const response = await axios.get('/categorieAbonnes');
        const categorieAbonnesData = response.data.data.data;
        categorieAbonneOptions.value = categorieAbonnesData.map((categorieAbonne) => ({
          value: categorieAbonne.id,
          label: categorieAbonne.libelle,
        }));
      } catch (error) {
        //
        console.log("nous avons une erreur", error);
        
      }
    };

    const fetchSecteurActivites = async () => {
      try {
        const response = await axios.get('/secteurActivites');
        const secteurActivitesData = response.data.data;
        secteurActiviteOptions.value = secteurActivitesData.map((secteurActivite) => ({
          value: secteurActivite.id,
          label: secteurActivite.libelle,
        }));
      } catch (error) {
        //
      }
    };
    return { abonneSchema, editAbonne, abonneForm, categorieAbonnes, categorieAbonneOptions, nationalite, sexeAbonne,  categorieAbonneId, secteurActivites, secteurActiviteOptions, countries: countriesRef };
  },
});
</script>
<style src="@vueform/multiselect/themes/default.css"></style>
