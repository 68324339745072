<template>
  <div class="card mb-25 border-0 rounded-0 bg-white add-user-card">
    <div class="card-body p-15 p-sm-20 p-md-25 p-lg-30 letter-spacing">
      <Form ref="reabonnementForm" @submit="payer" :validation-schema="reabonnementSchema">
      <div class="row">
        <div class="col-md-4">
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">
                Abonnement
              </label>
              <Field name="abonnement" v-model="abonnement" v-slot="{ field }">
                  <Multiselect 
                    :options="abonnementOptions" 
                    :searchable="true" 
                    v-on:change="changementEvent"
                    v-model="field.value"
                    v-bind="field"
                    placeholder="Sélectionner l'abonnement"
                  />
              </Field>
              <ErrorMessage name="abonnement" class="text-danger"/>
            </div>
        </div>
        <div class="col-md-4">
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">
                Année
              </label>
              <Field name="annees" v-model="anneesval" v-slot="{ field }">
                  <Multiselect 
                    mode="tags"
                    :options="yearOptions"  
                    :searchable="true" 
                    v-model="field.value"
                    @change="changementEvent2"
                    v-bind="field"
                    placeholder="Sélectionner une année"
                  />
              </Field>
              <ErrorMessage name="annees" class="text-danger"/>
            </div>
        </div>
        <div class="col-md-4">
          <div class="form-group mb-15 mb-sm-20 mb-md-25">
            <label class="d-block text-black fw-semibold mb-10">
              Tarif
            </label>
            <Field 
              readonly="true"
              name="montant" 
              type="text"
              v-model="montantvaleur"
              class="form-control shadow-none fs-md-15 text-black"/>
            <ErrorMessage name="montant" class="text-danger"/>
          </div>
        </div>
        <div class="col-md-12">
          <div class="d-flex align-items-center">
            <button
              class="default-btn me-20 transition border-0 fw-medium text-white pt-10 pb-10 ps-25 pe-25 pt-md-11 pb-md-11 ps-md-35 pe-md-35 rounded-1 fs-md-15 fs-lg-16 bg-success"
              type="submit"
            >
               Faire un reabonnement
            </button>
            <router-link to="/liste-reabonnement" 
                class=" btn btn-danger transition border-0 lh-1 fw-medium"><i class="flaticon-delete lh-1 me-1 position-relative top-2"></i>
                <span class="position-relative"></span>Annuler</router-link>
          </div>
        </div>
      </div>
    </Form>
    </div>
  </div>
</template>

<script lang="ts">
import Swal from "sweetalert2";
import { format_date, showModal, suppression,success,error } from "../../../utils/utils";
import {openKkiapayWidget,addKkiapayListener,removeKkiapayListener} from "kkiapay";
import { defineComponent, ref,toRef,reactive, onMounted } from "vue";
import { Form, Field, ErrorMessage } from 'vee-validate';
import * as Yup from 'yup';
import ApiService from '@/services/ApiService';
import { Reabonnement } from '@/models/Reabonnement';
import Multiselect from '@vueform/multiselect';
import { useRouter } from "vue-router";
import {KKIAPAY} from '@/models/KKIAPAY';

export default ({
  name: "AddReabonnement",
  components: {
  Form,
  Field,
  ErrorMessage,
  Multiselect,
},
setup: () => {
  const reabonnementSchema = Yup.object().shape({
    abonnement: Yup.string().required('L\' abonnement est obligatoire'),
    annees: Yup.array().required('La date est obligatoire'),
    montant: Yup.number().typeError("Veuillez entrer des chiffres").required('Le tarif est obligatoire'),
  });

  const reabonnementForm = ref<null | Reabonnement>(null);
  const abonnementOptions = ref([]);
  const router = useRouter();
  const year = ref(new Date().getFullYear());
  const years = ref<Array<{ value: number; label: string }>>([]);
  const yearOptions = ref<Array<{ value: number; label: string }>>([]);
  const montantvaleur = ref();
  const abonnement = ref(0);
  const anneesval = ref();
  var montant = 0
  var tail = ref(1);

  const addReabonnement = async (values, {resetForm}) => {
    
    // ApiService.post("/paie/redevance/paiements",values)
    //   .then(({ data }) => {
    //     if(data.code == 201) { 
    //       success(data.message);
    //       resetForm();
    //       router.push({ name: "ListReabonnementPage" });
    //     }
    //   }).catch(({ response }) => {
    //     error(response.data.message);
    //   });
  };
  const changementEvent2 = async (value)=>{
      await changementEvent(abonnement.value);
      if(value.length > 0){
        tail.value = value.length
      }
      montantvaleur.value *= tail.value;
    }

    var check = reactive({
      typeError: "RAS",
      idTransaction: 0
    });
    async function successHandler(response, values, { resetForm }) {
      // Mise à jour de l'objet check avec les informations de la réponse de succès
      check.typeError = "SUCCESS";
      check.idTransaction = response.transactionId;

      values["idTransaction"] = check.idTransaction;
      values["mode"] = 2;
      values["check"] = check;
      // Bloc de code à exécuter lorsque le paiement est un succès
      if (check.typeError === "SUCCESS") {
        // Appel de la fonction addAbonnement et attente de son exécution
        await addReabonnement(values, {resetForm});
      } else {
        check.typeError = "RAS";
        check.idTransaction = 0;
      }

      // Retourne les données après l'exécution
      return { check, response };
    }

    async function failHandler(response) {
      // Mise à jour de l'objet check avec les informations de l'échec
      check.typeError = "ECHOUE";
      check.idTransaction = response.transactionId;

      check.typeError = "RAS";
      check.idTransaction = response.transactionId;

      // Retourne les données après l'exécution
      return { check, response };
    }

      const payer = async (values, {resetForm}) => {
        try {
          const result = await Swal.fire({
            text: "Vous êtes sur le point de faire un paiement d'une somme de " + values.montant + " F CFA",
            icon: "warning",
            buttonsStyling: true,
            showCancelButton: true,
            confirmButtonText: "Payer",
            cancelButtonText: `Annuler`,
            heightAuto: false,
            customClass: {
              confirmButton: "btn btn-danger",
            },
          });

          if (result.isConfirmed) {
           KKIAPAY.kkia(values.montant);
            //Attendre que le paiement soit effectué ou échoué
            await new Promise<void>((resolve) => {
              addKkiapayListener('failed', (event) => {
                failHandler(event);
                resolve();
              });

              addKkiapayListener('success', (event) => {
                successHandler(event, values, { resetForm });
                resolve();
              });

              const checkRef = toRef(check);

              let resolved = false;

              // Résoudre la promesse après avoir traité le résultat du paiement
              const intervalId = setInterval(() => {
                if (!resolved && checkRef.value.typeError !== "RAS") {
                  clearInterval(intervalId);
                  resolved = true;
                  resolve(); // Ajoutez ici un argument (par exemple, undefined)
                }
              }, 500); 
              // Vérifier toutes les 500 millisecondes
            });
            
          } else {
            error("Ok d'accord on va patienter");
          }

        } catch (error) {
          // Gérer les erreurs ici
        }
      };


    const changementEvent = async (value: any) => {
    if(value){
      return ApiService.get("/abonnements/"+value)
      .then(({ data }) => {
        if(data.code === 200) {
          montant = data.data.montant;
          montantvaleur.value =montant * tail.value;
        }
      })
      .catch((response) => {
        error(response.data.message);
      });
    }else{
      montant =0;
      montantvaleur.value = 0;
    }
    
  };

  const fetchAbonnements = async () => {
    return ApiService.get('/abonnements')
      .then(({ data }) => {
        if (data.code === 200) {
          // abonnements.value = data.data;
          abonnementOptions.value = data.data.map((abonnement: any) => ({
            value: abonnement.id,
            //label: `${abonnement.refAbo} - [${abonnement.adressePostale.adresse_postale}] - [${abonnement.abonne.nomComplet}]`,
            // enlever le deuxième label et garder le premier
            label: abonnement.abonne
              ? `${abonnement.refAbo} - [${abonnement.adressePostale.adresse_postale}] - [${abonnement.abonne.nomComplet}]`
              : `${abonnement.refAbo} - [${abonnement.adressePostale.adresse_postale}] - [NULL]`,
          }));
        }
      })
      .catch((response) => {
        error(response.data.message);
      });
  };

  const fetchYears = async () => {
      const options = Array.from({ length: 5 }, (_, i) => {
        const currentYear = year.value + i;
        return { value: currentYear, label: currentYear.toString() };
      });
      years.value = options;
    };


  onMounted(() => {
    fetchAbonnements();
    fetchYears();
      yearOptions.value = years.value;
  });

  return { changementEvent,payer,anneesval,abonnement,montantvaleur,changementEvent2, reabonnementSchema, addReabonnement, reabonnementForm, abonnementOptions, years, yearOptions };
},
});
</script>