<template>
  <div class="card mb-25 border-0 rounded-0 bg-white add-user-card">
    <div class="card-body p-15 p-sm-20 p-md-25 p-lg-30 letter-spacing">
      <Form ref="redevanceForm" @submit="addRedevance" :validation-schema="redevanceSchema">
      <div class="row">
        <div class="col-md-4">
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">
                Abonnement
              </label>
              <Field name="abonnement" v-slot="{ field }">
                  <Multiselect 
                    :options="abonnementOptions" 
                    :searchable="true" 
                    v-model="field.value"
                    v-bind="field"
                    @change="selectLastRedevanceYear"
                    placeholder="Sélectionner l'abonnement"
                  />
              </Field>
              <ErrorMessage name="abonnement" class="text-danger"/>
            </div>
        </div>
        <div class="col-md-4">
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">
                Année
              </label>
              <Field name="annee" v-slot="{ field }">
                  <Multiselect 
                    :options="years" 
                    :searchable="true" 
                    v-model="field.value"
                    v-bind="field"
                    placeholder="Sélectionner une année"
                  />
              </Field>
              <ErrorMessage name="annee" class="text-danger"/>
            </div>
        </div>
        <div class="col-md-12">
          <div class="d-flex align-items-center ">
            <button
              class="default-btn me-20 transition border-0 fw-medium text-white pt-10 pb-10 ps-25 pe-25 pt-md-11 pb-md-11 ps-md-35 pe-md-35 rounded-1 fs-md-15 fs-lg-16 bg-success"
              type="submit"
            >
                Créer une redevance
            </button>
            <router-link to="/liste-redevances" 
                class=" btn btn-danger transition border-0 lh-1 fw-medium"><i class="flaticon-delete lh-1 me-1 position-relative top-2"></i>
                <span class="position-relative"></span>Annuler</router-link>
          </div>
        </div>
      </div>
    </Form>
    </div>
  </div>
</template>

<script lang="ts">
import { onMounted, ref, watch } from 'vue';
import { Form, Field, ErrorMessage } from 'vee-validate';
import * as Yup from 'yup';
import ApiService from '@/services/ApiService';
import { error , success } from '@/utils/utils';
import { Redevance } from '@/models/Redevance';
import Multiselect from '@vueform/multiselect';
import { useRouter } from "vue-router";


export default ({
  name: "AddRedevance",
  components: {
  Form,
  Field,
  ErrorMessage,
  Multiselect,
},
setup: () => {
  const redevanceSchema = Yup.object().shape({
    abonnement: Yup.string().required('L\' abonnement est obligatoire'),
    annee: Yup.string().required('La date est obligatoire'),
  });

  const redevanceForm = ref<null | Redevance>(null);
  const abonnement = ref(0);
  const abonnementOptions = ref([]);
  const year = ref(new Date().getFullYear());
  const years = ref([{}]);
  const router = useRouter();



  const addRedevance = async (value: any) => {
    value = value as Redevance;
    value.refRedevance = "R"+value.annee.toString().substr(2, 2)+value.abonnement.toString().substr(0, 2);
    value.arrierer = 0;
    value.status = "En cours";
    value.dateRedevance = new Date().toISOString();

    return ApiService.post('/redevances', value)
      .then(({ data }) => {
        if (data.code === 201) {
          success(data.message);
          router.push({ path: "/liste-reclamations" });
        }
      })
      .catch((err) => {
        error(err.response.data.message);
      });
  };

  const selectLastRedevanceYear = async (value: any) => {
    return ApiService.get("/last/redevance/year/"+value)
      .then(({ data }) => {

        if(data.code === 200) {
          abonnement.value = 1;
          years.value = [{ value: data.data, label: parseInt(data.data), selected: true }];
        }
      })
      .catch((response) => {
        error(response.data.message)
      });
  };

  const fetchAbonnements = async () => {
    return ApiService.get('/abonnements')
      .then(({ data }) => {
        if (data.code === 200) {
          abonnementOptions.value = data.data.map((abonnement: any) => ({
            value: abonnement.id,
            label: `${abonnement.refAbo} - [${abonnement.adressePostale.adresse_postale}] - [${abonnement.abonne.nomComplet}]`,
          }));
        }
      })
      .catch((response) => {
        error(response.data.message)
      });
  };

  onMounted(() => {
    fetchAbonnements();
  });

  return {  redevanceSchema, addRedevance, redevanceForm, abonnementOptions, years, selectLastRedevanceYear };
},
});
</script>