<template>

  <div v-show="messagePromotion != ''" class="alert alert-primary fs-md-15 fs-lg-16" role="alert">
    {{ messagePromotion }}
  </div>

  <div class="card mb-25 border-0 rounded-0 bg-white add-user-card">
    <div class="card-body p-15 p-sm-20 p-md-25 p-lg-30 letter-spacing">
      <Form ref="abonneForm" @submit="payer" :validation-schema="abonnementSchema">
        <div class="row">
          <div class="col-md-4">
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">
                Commune<span class="text-danger">*</span>
              </label>
              <Field name="commune" v-slot="{ field }">
                <Multiselect v-model="field.value" v-bind="field" :options="communesOptions" :searchable="true"
                  @change="communeChange($event)" placeholder="Rechercher une commune"
                  noResultText="Aucun enregistrement trouvé" noOptionsText="Tapez au moins deux caractères" />
              </Field>
              <ErrorMessage name="commune" class="text-danger" />
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">
                Agence<span class="text-danger">*</span>
              </label>
              <Field name="bureau" v-slot="{ field }">
                <Multiselect v-model="field.value" v-bind="field" :options="bureauxOptions"
                  @select="getLoyerAndBoitBureau(field.value)" placeholder="Selectionner un bureau" />
              </Field>
              <ErrorMessage name="bureau" class="text-danger" />
            </div>
          </div>

          <div class="col-md-4">
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">
                Modèle de Boîte<span class="text-danger">*</span>
              </label>
              <Field name="modeleBoite" v-slot="{ field }">
                <Multiselect :options="modelesBoitesOptions" :searchable="true" v-model="field.value" v-bind="field"
                  @select="getLoyerAndBoitModel(field.value)" placeholder="Sélectionner le modèle de la boîte" />
              </Field>
              <ErrorMessage name="modeleBoite" class="text-danger" />
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">
                Boîte <span class="text-danger">*</span>
              </label>
              <Field v-model="boitesselect" name="boite" v-slot="{ field }">
                <Multiselect v-model="field.value" v-bind="field" :searchable="true"
                  placeholder="Sélectionner une boite" :options=boitesOptions :no-options-text="noOptionsTextBoi"
                  @select="modelChange" />
              </Field>
              <ErrorMessage name="boite" class="text-danger" />
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">
                Adresse Postale <span class="text-danger">*</span>
              </label>
              <Field v-model="adressePostaleC" readonly="true" name="adressePostale" type="text" aria-disabled="true"
                class="form-control shadow-none fs-md-15 text-black" placeholder="Adresse postale" />
              <ErrorMessage name="adressePostale" class="text-danger" />
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">
                Durée de l'abonnement <span class="text-danger">*</span>
              </label>
              <Field name="dureeAbonnement" v-model="dureeAbonnement" type="text"
                class="form-control shadow-none fs-md-15 text-black" placeholder="Entrer la durée de l'abonnement" />
              <ErrorMessage name="dureeAbonnement" class="text-danger" />
            </div>
          </div>
          <!-- <div class="col-md-4">
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">
               Nombre de clé
              </label>
              <Field v-model="nombreCle" name="nombreCle" type="text" aria-disabled="true"
                class="form-control shadow-none fs-md-15 text-black" placeholder="Entrer le nombre de clé"/>
                <ErrorMessage name="nombreCle" class="text-danger"/>
            </div>
          </div> -->
          <div class="col-md-4">
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">
                Loyer année encours
              </label>
              <Field readonly="true" v-model="loyerAnneeEncours" name="loyerAnneeEncours" type="text"
                aria-disabled="true" class="form-control shadow-none fs-md-15 text-black" placeholder="0" />
              <ErrorMessage name="loyerAnneeEncours" class="text-danger" />
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">
                Loyer annuel
              </label>
              <Field readonly="true" v-model="loyert" name="loyer" type="text" aria-disabled="true"
                class="form-control shadow-none fs-md-15 text-black" placeholder="Entrer le loyer mensuel" />
              <ErrorMessage name="loyer" class="text-danger" />
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">
                Caution
              </label>
              <Field v-model="cautionval" readonly="true" name="caution" type="text"
                class="form-control shadow-none fs-md-15 text-black" placeholder="Entrer le montant caution" />
              <ErrorMessage name="caution" class="text-danger" />
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">
                Frais des clefs
              </label>
              <Field v-model="fraiscleval" readonly="true" name="fraisCle" type="text"
                class="form-control shadow-none fs-md-15 text-black" placeholder="Frais des clefs" />
              <ErrorMessage name="fraisCle" class="text-danger" />
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">
                Montant remise
              </label>
              <Field v-model="montantRemise" readonly="true" name="montantRemise" type="text"
                class="form-control shadow-none fs-md-15 text-black" placeholder="Entrer le montant remise" />
              <ErrorMessage name="montantRemise" class="text-danger" />
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">
                Montant Total de l'abonnement
              </label>
              <Field v-model="montanttotalval" readonly="true" name="montant" type="text"
                class="form-control shadow-none fs-md-15 text-black" placeholder="Montant total l'abonnement" />
              <ErrorMessage name="montant" class="text-danger" />
            </div>
          </div>
          <div class="col-md-12">
            <div class="d-flex align-items-center ">
              <button
                class="default-btn me-20 transition border-0 fw-medium text-white pt-10 pb-10 ps-25 pe-25 pt-md-11 pb-md-11 ps-md-35 pe-md-35 rounded-1 fs-md-15 fs-lg-16 bg-success"
                type="submit">
                Envoyer sa demande
              </button>
              <router-link to="/liste-abonnements" class=" btn btn-danger transition border-0 lh-1 fw-medium"><i
                  class="flaticon-delete lh-1 me-1 position-relative top-2"></i>
                <span class="position-relative"></span>Annuler</router-link>
            </div>
          </div>
        </div>
      </Form>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, ref, reactive, watch, toRef, onMounted } from 'vue';
import { Form, Field, ErrorMessage } from 'vee-validate';
import Multiselect from '@vueform/multiselect'
import * as Yup from 'yup';
import axios from 'axios';
import ApiService from '../../services/ApiService';
import { useRouter } from 'vue-router';
import { error, success, format_date } from '@/utils/utils';
import { Abonnement } from '@/models/Abonnement';
import JwtService from '@/services/JwtService';
import Swal from "sweetalert2";
import { openKkiapayWidget, addKkiapayListener, removeKkiapayListener } from "kkiapay";
import { KKIAPAY } from '@/models/KKIAPAY';

export default defineComponent({
  name: "AddAbonnement",
  components: {
    Form,
    Field,
    ErrorMessage,
    Multiselect,
  },

  setup: () => {
    const abonnementSchema = Yup.object().shape({
      //categorieAbonne: Yup.number().typeError('Veuillez entrer un nombre').required('La catégorie est obligatoire'),
      adressePostale: Yup.string().required('L\' adresse postale est obligatoire'),
      bureau: Yup.number().typeError('Veuillez entrer un nombre').required('Le bureau est obligatoire'),
      boite: Yup.string().required('La boite postale est obligatoire'),
      loyer: Yup.number().typeError('Veuillez entrer un nombre').required(`L'abonne est obligatoire`),
      caution: Yup.number().typeError('Veuillez entrer un caution').required(`La caution est obligatoire`),
      montant: Yup.number().typeError('Veuillez entrer un nombre').required(`Le tarif est obligatoire`),
      modeleBoite: Yup.number().typeError('Veuillez entrer un nombre').required(`Le modèle de boite est obligatoire`),
      fraisCle: Yup.number().typeError('Veuillez entrer un service').required('le Frais des clefs est obligatoire'),
      commune: Yup.number().typeError('Veuillez entrer un nombre').required('La commune est obligatoire'),
      //typePiece: Yup.string().required('Le type de pièce est obligatoire'),
      //numPiece: Yup.string().required('Le numéro de pièce est obligatoire'),
      loyerAnneeEncours: Yup.number().typeError('Veuillez entrer un nombre').required(`Le loyer est obligatoire`),
      montantRemise: Yup.number().typeError('Veuillez entrer un nombre').required(`Le montant Total de l'abonnement est obligatoire`),
      dureeAbonnement: Yup.number().min(1).default(1).typeError('Veuillez entrer des chiffres').required('La durée de l\'abonnement est obligatoire'),
    });

    onMounted(() => {
      fetchModeles()
      fetchCommune()

    });

    const router = useRouter();
    const abonnementForm = ref<Abonnement>();
    //const categorieAbonnes = ref(null);
    const boitesOptions = ref([]);
    const adressePostalesOptions = ref([]);
    const bureauxOptions = ref([]);
    const modelesBoitesOptions = ref([]);
    const servicesOptions = ref([]);
    const dureeAbonnement = ref(1);
    const bureauselect = ref();
    const modelselect = ref();
    const cateselect = ref();
    const boitesselect = ref();
    const loyert = ref(0);
    const cautionval = ref(0);
    const fraiscleval = ref(0);
    const montanttotalval = ref(0);
    const montantRemise = ref(0);
    const loyerAnneeEncours = ref(0);
    const communesOptions = ref([]);
    const abonneid = ref();
    const noOptionsTextAdd = ref("Tapez au moins deux caractères");
    const noOptionsTextAbb = ref("Tapez au moins trois caractères");
    const noOptionsTextBoi = ref("Sélectionner d'abord le bureau et le modèle");
    const adressePostaleC = ref("");
    const etat = ref("");
    const messagePromotion = ref("");
    const tauxPromotion = ref(0);
    const dateFinCampagne = ref(null);

    const options = [
      { value: 'CIP', label: 'CIP' },
      { value: 'Passeport', label: 'Passeport' },
      { value: 'Carte Biométrique', label: 'Carte Biométrique' }
    ];
    const typePiece = ref('CIP');

    watch(dureeAbonnement, (newValue, oldValue) => {
      montanttotalval.value = ((newValue * loyert.value) + fraiscleval.value + cautionval.value);
      getLoyer();
    });

    const addAbonnement = async (values, { resetForm }) => {
      if (typeof values.boite === 'string') {
        const boiteParts = values.boite.split('-');
        if (boiteParts.length > 0) {
          values.boite = parseInt(boiteParts[0], 10);
        }
      }
      values["abonneId"] = JwtService.getUser();
      values["etat"] = "En attente";
      ApiService.post("/abonnements/A", values)
        .then(({ data }) => {
          if (data.code == 201) {
            success(data.message);
            resetForm();
            router.push({ name: "AbonnementInfoPage" });
          }
        }).catch(({ response }) => {
          //error(response.data.message);
        });
    };
    var check = reactive({
      typeError: "RAS",
      idTransaction: 0
    });

    async function successHandler(response, values, { resetForm }) {
      // Mise à jour de l'objet check avec les informations de la réponse de succès
      check.typeError = "SUCCESS";
      check.idTransaction = response.transactionId;

      values["idTransaction"] = check.idTransaction;
      values["check"] = check;
      // Bloc de code à exécuter lorsque le paiement est un succès
      if (check.typeError === "SUCCESS") {
        // Appel de la fonction addAbonnement et attente de son exécution
        await addAbonnement(values, { resetForm });
      } else {
        check.typeError = "RAS";
        check.idTransaction = 0;
      }

      // Retourne les données après l'exécution
      return { check, response };
    }

    async function failHandler(response) {
      // Mise à jour de l'objet check avec les informations de l'échec
      check.typeError = "ECHOUE";
      check.idTransaction = response.transactionId;

      check.typeError = "RAS";
      check.idTransaction = response.transactionId;

      // Retourne les données après l'exécution
      return { check, response };
    }

    const payer = async (values, { resetForm }) => {
      check = reactive({
        typeError: "RAS",
        idTransaction: 0
      });
      const result = await Swal.fire({
        text: "Le montant de votre abonnement s'élève à " + values.montant + " F CFA.",
        icon: "warning",
        buttonsStyling: true,
        showCancelButton: true,
        confirmButtonText: "Payer",
        cancelButtonText: `Annuler`,
        heightAuto: false,
        customClass: {
          confirmButton: "btn btn-danger",
        },
      });
      if (result.isConfirmed) {
        values["mode"] = 2;
        KKIAPAY.kkia(values.montant);
        await new Promise<void>((resolve) => {
          addKkiapayListener('failed', (event) => {
            failHandler(event);  // Passe values et resetForm ici
            resolve();                                  // Résolution de la promesse
          });

          addKkiapayListener('success', (event) => {
            successHandler(event, values, { resetForm }); // Passe values et resetForm ici
            resolve();                                   // Résolution de la promesse
          });

          const checkRef = toRef(check);

          let resolved = false;

          const intervalId = setInterval(() => {
            if (!resolved && checkRef.value.typeError !== "RAS") {
              clearInterval(intervalId);
              resolved = true;
              resolve();
            }
          }, 500);
        });
      }
    }

    const fetchAdressePostale = async (key) => {
      try {
        const response = await axios.get('/adressePostales/recherche/' + key);
        const adressePostaleData = response.data.data;
        adressePostalesOptions.value = adressePostaleData.map((adresse) => ({
          value: adresse.id,
          label: adresse.adresse_postale,
        }));
        return adressePostalesOptions.value;
      } catch (error) {
        //
      }
    };

    const fetchAgences = async (key) => {
      try {
        const response = await axios.get('/bureaus/recherche/' + key);
        const bureauxData = response.data.data;
        bureauxOptions.value = bureauxData.map((bureau) => ({
          value: bureau.id,
          label: bureau.nom,
        }));
        return bureauxOptions.value;
      } catch (error) {
        //
      }
    };

    const fetchBoites = async () => {

      if (bureauselect.value && modelselect.value) {
        var url = `/boitePostales/${bureauselect.value}/${modelselect.value}`;
        try {
          const response = await axios.get(`/boitePostales/recherche/${bureauselect.value}/${modelselect.value}`);
          const boitesData = response.data.data;
          boitesOptions.value = boitesData.map((boite) => ({
            value: boite.id + '-' + boite?.adressePostale?.adresse_postale,
            label: boite.refBoite
          }));
          if (boitesOptions.value.length == 0) {
            noOptionsTextBoi.value = "Il n'y a pas de boîte dans ce bureau pour ce modèle"
          } else {
            noOptionsTextBoi.value = "Sélectionner d'abord le bureau et le modèle";
          }
          return boitesOptions.value;
        } catch (error) {
          //
        }
      }
    };

    const modelChange = (selectedBoite) => {
      if (selectedBoite == null) return;
      // const adressePostalePart = selectedBoite.split('-')[1];
      const adressePostalePart = selectedBoite.split('-').slice(1).join('-');
      adressePostaleC.value = adressePostalePart;
    };

    const fetchModeles = async () => {
      try {
        const response = await axios.get('/modele_boites');
        const modelesData = response.data.data;
        modelesBoitesOptions.value = modelesData.map((boite) => ({
          value: boite.id,
          label: boite.code + " [ " + boite.libelle + " ]",
        }));
      } catch (error) {
        //
      }
    };

    const getLoyerAndBoitBureau = (val) => {
      boitesselect.value = "";
      boitesOptions.value = [];
      bureauselect.value = val;
      fetchBoites();
      getLoyer();
    }

    function communeChange(value) {
      ApiService.get('/bureaux/commune/' + value)
        .then(({ data }) => {
          const donnee = data.data;
          if (donnee.length > 0) {
            bureauxOptions.value = donnee.map((bureau: any) => {
              return {
                label: bureau.nom,
                value: bureau.id,
              };
            });
          }
        })
        .catch((response) => {
          //error(response.data.response)
        });
    }

    const fetchCommune = async () => {
      ApiService.get('/communes')
        .then(({ data }) => {
          const donneee = data.data.data;
          communesOptions.value = donneee.map((commune: any) => {
            return {
              label: commune.libelle,
              value: commune.id,
            };
          });
        })
        .catch((response) => {
          //error(response.data.message)
        });
    }

    const getLoyerAndBoitModel = (val) => {
      boitesselect.value = "";
      boitesOptions.value = [];
      modelselect.value = val;
      // abonneid.value = val;
      fetchBoites();
      getLoyer();
    }

    const calculeMontantTotal = async (dure: number) => {
      if (fraiscleval.value >= 0 && cautionval.value >= 0 && loyert.value >= 0 && dure) {
        console.log("nous avons utiliser le taux ici", tauxPromotion.value);
        if (tauxPromotion.value > 0) {
          montantRemise.value = (((dure - 1) * loyert.value) + loyerAnneeEncours.value) * tauxPromotion.value / 100;
          montanttotalval.value = (((dure - 1) * loyert.value) + fraiscleval.value + cautionval.value + loyerAnneeEncours.value) - (((dure - 1) * loyert.value) + loyerAnneeEncours.value) * tauxPromotion.value / 100;
          messagePromotion.value = "Une promotion de " + tauxPromotion.value + " % est accordée pour tout paiement effectué avant le " + format_date((dateFinCampagne.value && dateFinCampagne.value != null) ? dateFinCampagne.value : '');
        } else {
          montanttotalval.value = ((dure - 1) * loyert.value) + fraiscleval.value + cautionval.value + loyerAnneeEncours.value;
        }
      } else {
        montanttotalval.value = 0;

        montantRemise.value = 0;
      }
    }


    const getLoyer = async () => {
      abonneid.value = JwtService.getUser();
      if (abonneid.value && modelselect.value) {
        try {
          const response = await axios.get(`/tarifAnnuels/${abonneid.value}/${modelselect.value}`);
          const tarifAnnuels = response.data.data;
          loyerAnneeEncours.value = tarifAnnuels.loyerAnneeEncours;
          loyert.value = tarifAnnuels.cout;
          cautionval.value = tarifAnnuels.caution;
          fraiscleval.value = tarifAnnuels.fraisCle;
          tauxPromotion.value = tarifAnnuels.taux;
          dateFinCampagne.value = tarifAnnuels.dateFinCampagne;
          // dureeAbonnement.value = 1;
          // if(fraiscleval.value > 0 &&  cautionval.value > 0 && loyert.value> 0 && dureeAbonnement.value > 0 ){
          //    montanttotalval.value =  fraiscleval.value + cautionval.value + parseInt(dureeAbonnement.value.toString()) * parseFloat(loyert.value.toString());
          // }
          console.log("nous avons utiliser le taux ici", tauxPromotion.value);
          if (tauxPromotion.value > 0) {
            montantRemise.value = (((dureeAbonnement.value - 1) * loyert.value) + loyerAnneeEncours.value) * tauxPromotion.value / 100;
            montanttotalval.value = (((dureeAbonnement.value - 1) * loyert.value) + fraiscleval.value + cautionval.value + loyerAnneeEncours.value) - (((dureeAbonnement.value - 1) * loyert.value) + loyerAnneeEncours.value) * tauxPromotion.value / 100;
            messagePromotion.value = "Une promotion de " + tauxPromotion.value + " % est accordée pour tout paiement effectué avant le " + format_date((dateFinCampagne.value && dateFinCampagne.value != null) ? dateFinCampagne.value : '');
          } else {
            montanttotalval.value = ((dureeAbonnement.value - 1) * loyert.value) + fraiscleval.value + cautionval.value + loyerAnneeEncours.value;
          }
        } catch (error) {
          //
        }
      }
    }

    return {
      loyert,
      bureauselect,
      cateselect,
      modelselect,
      boitesselect,
      montanttotalval,
      cautionval,
      fraiscleval,
      getLoyerAndBoitBureau,
      getLoyerAndBoitModel,
      getLoyer,
      fetchBoites,
      fetchAgences,
      payer,
      fetchAdressePostale,
      abonnementSchema,
      modelesBoitesOptions,
      adressePostalesOptions,
      addAbonnement,
      abonnementForm,
      //categorieAbonnes,
      bureauxOptions,
      boitesOptions,
      servicesOptions,
      communeChange,
      communesOptions,
      typePiece,
      options,
      abonneid,
      noOptionsTextAdd,
      noOptionsTextAbb,
      noOptionsTextBoi,
      adressePostaleC,
      modelChange,
      dureeAbonnement,
      etat,
      montantRemise,
      loyerAnneeEncours,
      messagePromotion,
      tauxPromotion,
      format_date,
      dateFinCampagne,
    };
  },
});
</script>
