<template style="border: 5px solid red">
  <div
    style="
      background-image: url(back.jpg);
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      background-attachment: fixed;
      padding: 0px !important;
      margin: 0px !important;
    "
  >
    <div class="row justify-content-center flex-align">
      <div class="col-md-5 col-lg-5 col-xl-5 col-xxl-6 col-xxxl-6">
        <div class="card mb-5 border-0 rounded-0 bg-white authentication-card">
          <div class="card-body letter-spacing">
            <div class="text-center mb-10">
              <img
                src="../../../assets/images/lapostebj.png"
                alt="logo-icon"
                style="height: 80px"
              />
            </div>
            <h4 class="text-black fw-bold mb-0 text-center">
              Connectez-vous à votre compte client !
            </h4>
            <Form
              ref="loginForm"
              @submit="onSubmitLogin"
              :validation-schema="loginSchema"
              style="margin-top: 20px !important"
            >
              <div class="form-group mb-15 mb-sm-20 mb-md-25" v-if="!estEmail">
                <div
                  class="d-flex align-items-center justify-content-between mb-10"
                >
                  <label class="d-block text-black fw-semibold">
                    Numéro de téléphone
                  </label>
                  <a
                    @click="useEmail"
                    v-if="!estEmail"
                    class="forgot-password-btn fs-md-15 fs-lg-16 text-decoration-none position-relative text-primary"
                  >
                  Utilisé le mail
                </a>
                </div>
                <Field name="telephone" v-model="telephone" v-slot="{ field }">
                  <vue-tel-input
                    placeholder="Ex: +229 97 00 00 00"
                    v-model="field.value"
                    v-bind="field"
                    defaultCountry="BJ"
                    mode="auto"
                    @validate="validate"
                    class="shadow-none fs-md-15 text-black py-2"
                  >
                  </vue-tel-input>
                </Field>
                <ErrorMessage name="telephone" class="text-danger" />
                <div v-if="!validPhone" class="text-danger">
                  Veuillez entrer un numéro correcte
                </div>
              </div>
              <div class="col-md-12" v-if="estEmail">
                <div class="form-group mb-15 mb-sm-20 mb-md-25">
                  <div
                  class="d-flex align-items-center justify-content-between mb-10"
                    >
                      <label class="d-block text-black fw-semibold">
                        Email
                      </label>
                      <a
                        @click="useEmail"
                        v-if="estEmail"
                        class="forgot-password-btn fs-md-15 fs-lg-16 text-decoration-none position-relative text-primary"
                      >
                      Utilisé le téléphone
                    </a>
                    </div>
                  <Field
                    name="email"
                    type="email"
                    class="form-control shadow-none fs-md-15 text-black"
                    placeholder="Entrer l' email"
                  />
                  <ErrorMessage name="email" class="text-danger" />
                </div>
              </div>
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <div
                  class="d-flex align-items-center justify-content-between mb-10"
                >
                  <label class="d-block text-black fw-semibold">
                    Mot de passe
                  </label>
                  <router-link
                    to="/forgot-password"
                    class="forgot-password-btn fs-md-15 fs-lg-16 text-decoration-none position-relative text-primary"
                  >
                    Mot de passe oublié ?
                  </router-link>
                </div>
                <div class="input-group has-validation">
                  <Field
                    name="password"
                    :type="showPassword ? 'password' : 'text'"
                    class="form-control shadow-none fs-md-15 text-black"
                    placeholder="Entrer votre mot de passe"
                  />
                  <span class="input-group-text" id="inputGroupPrepend">
                    <i
                      :class="{
                        'flaticon-eye': !showPassword,
                        'flaticon-visibility-off': showPassword,
                      }"
                      class="flaticon-eye"
                      @click="toggleShow"
                      :aria-label="buttonLabel"
                    ></i>
                  </span>
                </div>
                <ErrorMessage name="password" class="text-danger" />
              </div>
              <button
                class="text-decoration-none text-center default-btn transition border-0 fw-medium text-white rounded-1 fs-md-15 fs-lg-16 bg-success w-100"
                type="submit"
                :disabled="!validPhone"
                v-if="!estEmail"
              >
                Connectez-vous maintenant
              </button>
              <button
                class="text-decoration-none text-center default-btn transition border-0 fw-medium text-white rounded-1 fs-md-15 fs-lg-16 bg-success w-100"
                type="submit"
                v-if="estEmail"
              >
                Connectez-vous maintenant
              </button>
              <span
                class="d-block text-muted text-center mt-5 mt-md-5 mb-12 mb-md-10 fs-md-15 fs-lg-16"
              >
                Vous n’avez pas de compte ?
              </span>
              <router-link
                to="/register"
                class="default-btn with-border transition fw-medium rounded-1 fs-md-15 fs-lg-16 d-block w-100 text-decoration-none text-center"
              >
                Créer votre compte
              </router-link>
            </Form>
          </div>
        </div>
      </div>
    </div>
    <div
      style="
        color: white !important;
        text-align: center;
        padding: 10px 0px !important;
      "
    >
      <a
        target="_blank"
        href="https://laposte.bj/mentions-legales/"
        style="text-decoration: none; color: white; margin: 0px 10px"
        >Mentions légales</a
      >
      <a
        target="_blank"
        href="https://laposte.bj/donnees-personnelles/"
        style="text-decoration: none; color: white; margin: 0px 10px"
        >Données personnelles</a
      >
      <a
        target="_blank"
        href="https://laposte.bj/cookies/"
        style="text-decoration: none; color: white; margin: 0px 10px"
        >Cookies</a
      >
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted, computed } from "vue";
import { Form, Field, ErrorMessage } from "vee-validate";
import * as Yup from "yup";
import { useRouter } from "vue-router";
import { useAuthStore } from "@/services/auth";
import Swal from "sweetalert2";
import { VueTelInput } from "vue3-tel-input";
import "vue3-tel-input/dist/vue3-tel-input.css";

export default defineComponent({
  name: "LoginForm",
  components: {
    Form,
    Field,
    ErrorMessage,
    VueTelInput,
  },
  setup: () => {
    const loginSchema = Yup.object().shape({
      // telephone: Yup.string().required(
      //   "Le numéro de téléphone est obligatoire"
      // ),
      telephone: Yup.string().when([], (value, schema) => {
        return !estEmail.value
          ? schema
              .required("Le numéro de téléphone est obligatoire")
          : schema.notRequired();
      }),
      password: Yup.string()
        .min(8, "Le mot de passe doit contenir au moins 8 caractères")
        .required("Le mot de passe est obligatoire"),
      email: Yup.string().when([], (value, schema) => {
        return estEmail.value
          ? schema
              .email("L'email n'est pas valide")
              .required("L'email est obligatoire")
          : schema.notRequired();
      }),
    });

    const router = useRouter();
    const store = useAuthStore();
    const submitButton = ref<HTMLButtonElement | null>(null);
    const codepay = ref();
    const nationalnumlber = ref();
    const telephone = ref();
    const validPhone = ref<boolean>(false);
    const numberPhone = ref();
    function onInput(phone, phoneObject, input) {
      //
    }

    function validate(phoneObject) {
      validPhone.value = phoneObject.valid;
      if (phoneObject.valid == true) {
        telephone.value = phoneObject.number;
        codepay.value = phoneObject.countryCallingCode;
        nationalnumlber.value = phoneObject.nationalNumber;
        numberPhone.value = phoneObject.number;
      } else {
        telephone.value = "";
        codepay.value = "";
        nationalnumlber.value = "";
        numberPhone.value = "";
      }
    }

    const loginForm = ref(null);
    const onSubmitLogin = async (values: any) => {
      values["telephone"] = numberPhone.value;
      values["estEmail"] = estEmail.value;
      console.log("Donées envoyées", values);
      // values = values as User;
      // Clear existing errors
      store.logout();
      if (submitButton.value) {
        // eslint-disable-next-line
        submitButton.value.disabled = true;
      }
      // Send login request
      await store.login(values);
      const error = Object.values(store.errors);
      if (error.length === 0) {
          // Go to page after successfully login
          //router.push({ name: "tableauBordPage" });
          router.push({ name: "CodeConfPage", query: { telephone: values.telephone, estEmail: values.estEmail, email: values.email } });
          //router.push({ name: "CodeConfPage" });
      } else {
        Swal.fire({
          text: error[1] as string,
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "OK",
          heightAuto: false,
          customClass: {
            confirmButton: "btn btn-sm fw-semobold btn-danger",
          },
        }).then(() => {
          store.errors = {};
        });
      }
      // eslint-disable-next-line
      //submitButton.value!.disabled = false;
    };

    const estEmail = ref<boolean>(false);
    const useEmail = () => {
      estEmail.value = !estEmail.value;
    };

    const showPassword = ref(true);
    const password = ref(null);

    const toggleShow = () => {
      showPassword.value = !showPassword.value;
    };

    const buttonLabel = computed(() => (showPassword.value ? "Hide" : "Show"));

    return {
      loginSchema,
      onSubmitLogin,
      loginForm,
      submitButton,
      telephone,
      validPhone,
      validate,
      onInput,
      showPassword,
      password,
      toggleShow,
      buttonLabel,
      useEmail,
      estEmail,
    };
  },
});
</script>
