<template>
  <BreadCrumb PageTitle="Liste des redevances" />
  <ListRedevances />
</template>

<script lang="ts">
import { defineComponent } from "vue";

import BreadCrumb from "../../components/Common/BreadCrumb.vue";
import ListRedevances from "../../components/Redevances/ListRedevances.vue";


export default defineComponent({
  name: "ListRedevancesPage",
  components: {
    BreadCrumb,
    ListRedevances,
  },
});
</script>