<template>
  <div class="card mb-25 border-0 rounded-0 bg-white add-user-card">
    <div class="card-body p-15 p-sm-20 p-md-25 p-lg-30 letter-spacing">
      <Form ref="demandeChangementForm" @submit="payer" :validation-schema="demandeChangementSchema">
        <div class="row">
          <div class="col-md-6">
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">
                Adresse <span class="text-danger">*</span>
              </label>
              <Field name="abonnement" v-slot="{ field }">
                <Multiselect :options="abonnementOptions" track-by="value" label="label" v-model="field.value"
                  v-bind="field" placeholder="Sélectionner l'adresse" />
              </Field>
              <ErrorMessage name="abonnement" class="text-danger" />
            </div>
          </div>
          <!-- <div class="col-md-6">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="d-block text-black fw-semibold mb-10">
                  Date
                </label>
                <Field name="dateDemande" type="date" v-model="currentDate" readonly="true"
                class="form-control shadow-none rounded-0 text-black" placeholder="Choisisser la date"/>
                  <ErrorMessage name="dateDemande" class="text-danger"/>
              </div>
            </div> -->
          <div class="col-md-6">
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">
                Tarif
              </label>
              <Field v-model="montantval" readonly="true" name="montant" type="text"
                class="form-control shadow-none fs-md-15 text-black" placeholder="Tarif" />
              <ErrorMessage name="montant" class="text-danger" />
            </div>
          </div>
          <div class="col-md-12">
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">
                Message
              </label>
              <Field name="contenu" as="textarea" cols="30" rows="12" placeholder="Ecrire votre message"
                v-slot="{ field }" class="form-control shadow-none rounded-0 text-black">
                <textarea class="form-control shadow-none rounded-0 text-black" v-model="field.value"></textarea>
              </Field>
              <ErrorMessage name="contenu" class="text-danger" />
            </div>
          </div>
          <!-- <div class="col-md-12">
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">
                Télécharger un fichier 
              </label>
              <ImageUpload />
            </div>
          </div> -->
          <div class="col-md-12">
            <div class="d-flex align-items-center ">
              <button
                class="default-btn me-20 transition border-0 fw-medium text-white pt-10 pb-10 ps-25 pe-25 pt-md-11 pb-md-11 ps-md-35 pe-md-35 rounded-1 fs-md-15 fs-lg-16 bg-success"
                type="submit">
                Envoyer la demande
              </button>
              <router-link to="/liste-demandeChangements" class=" btn btn-danger transition border-0 lh-1 fw-medium"><i
                  class="flaticon-delete lh-1 me-1 position-relative top-2"></i>
                <span class="position-relative"></span>Annuler</router-link>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, toRef, reactive, onMounted } from "vue";
import BlotFormatter from "quill-blot-formatter";
import ImageUploader from "quill-image-uploader";
import axios from "axios";
import { Form, Field, ErrorMessage } from 'vee-validate';
import * as Yup from 'yup';
import Multiselect from '@vueform/multiselect'
import Swal from "sweetalert2";
import { format_date, showModal, suppression, success, error } from "../../utils/utils";
import { openKkiapayWidget, addKkiapayListener, removeKkiapayListener } from "kkiapay";
import { Demande } from '@/models/Demande';
import { useRouter } from 'vue-router';
import ApiService from '@/services/ApiService';
import JwtService from "@/services/JwtService";

import ImageUpload from "./ImageUpload.vue";
import { KKIAPAY } from "@/models/KKIAPAY";

export default defineComponent({
  name: "AddDemandeChangement",
  components: {
    //ImageUpload,
    Form,
    Field,
    ErrorMessage,
    Multiselect,
  },
  setup: () => {
    const demandeChangementSchema = Yup.object().shape({
      //dateDemande: Yup.date().required('La date est obligatoire'),
      abonnement: Yup.string().required('L\'adresse de l\'abonne est obligatoire'),
      message: Yup.string(),
      montant: Yup.number().required(),
      typeDemande: Yup.number().default(4).typeError('Veuillez entrer un nombre').required('Le type de demande est obligatoire'),
    });

    const demandeChangementForm = ref<Demande | null>(null);
    const abonnements = ref(null);
    const abonnementOptions = ref([]);
    const router = useRouter();
    const montantval = ref(0);

    const addDemandeChangement = async (values, { resetForm }) => {
      const updatedValues = {
        ...values,
        typeDemande: values.typeDemande || 7,
      };
      ApiService.post("/demandes/A", updatedValues)
        .then(({ data }) => {
          if (data.code == 201) {
            success(data.message);
            resetForm();
            router.push({ name: "ListDemandeChangementsPage" });
          }
        }).catch(({ response }) => {
          error(response.data.message);
        });
    };
    var check = reactive({
      typeError: "RAS",
      idTransaction: 0
    });
    async function successHandler(response, values, { resetForm }) {
      // Mise à jour de l'objet check avec les informations de la réponse de succès
      check.typeError = "SUCCESS";
      check.idTransaction = response.transactionId;

      values["idTransaction"] = check.idTransaction;
      values["mode"] = 2;
      values["check"] = check;
      // Bloc de code à exécuter lorsque le paiement est un succès
      if (check.typeError === "SUCCESS") {
        // Appel de la fonction addAbonnement et attente de son exécution
        await addDemandeChangement(values, { resetForm });
      } else {
        check.typeError = "RAS";
        check.idTransaction = 0;
      }

      // Retourne les données après l'exécution
      return { check, response };
    }

    async function failHandler(response) {
      // Mise à jour de l'objet check avec les informations de l'échec
      check.typeError = "ECHOUE";
      check.idTransaction = response.transactionId;

      check.typeError = "RAS";
      check.idTransaction = response.transactionId;

      // Retourne les données après l'exécution
      return { check, response };
    }

    const payer = async (values, { resetForm }) => {
      try {
        const result = await Swal.fire({
          text: "Vous êtes sur le point de faire un paiement d'une somme de " + values.montant + " F CFA",
          icon: "warning",
          buttonsStyling: true,
          showCancelButton: true,
          confirmButtonText: "Payer",
          cancelButtonText: `Annuler`,
          heightAuto: false,
          customClass: {
            confirmButton: "btn btn-danger",
          },
        });

        if (result.isConfirmed) {
          KKIAPAY.kkia(values.montant);
          //Attendre que le paiement soit effectué ou échoué
          await new Promise<void>((resolve) => {
            addKkiapayListener('failed', (event) => {
              failHandler(event);
              resolve();
            });

            addKkiapayListener('success', (event) => {
              successHandler(event, values, { resetForm });
              resolve();
            });
            const checkRef = toRef(check);
            let resolved = false;
            // Résoudre la promesse après avoir traité le résultat du paiement
            const intervalId = setInterval(() => {
              if (!resolved && checkRef.value.typeError !== "RAS") {
                clearInterval(intervalId);
                resolved = true;
                resolve();
              }
            }, 500);
          });
        } else {
          error("Ok d'accord on va patienter");
        }

      } catch (error) {
        // Gérer les erreurs ici
      }
    };
    const abonneid = JwtService.getUser();

    const fetchAbonnements = async (abonneid) => {
      try {
        const response = await axios.get('/abonnements/abonne');
        const abonnementsData = response.data.data.data;
        abonnementOptions.value = abonnementsData.map((abonnement) => ({
          value: abonnement.id + '-' + abonnement.loyer,
          label: `${abonnement.refAbo} - [${abonnement.boite ? abonnement.boite.adressePostale.adresse_postale : abonnement.adresseVirtuelle}] - [${abonnement.abonne.nomComplet}]`,
        }));
        return abonnementOptions.value;
      } catch (error) {
        //
      }
    }
    function getMontant(id: number): Promise<void | any> {
      return ApiService.get(`/typeDemandes/${id.toString()}`)
        .then(({ data }) => {
          const typeDemandeData = data.data;
          if (typeDemandeData && typeDemandeData.service) {
            const cout = typeDemandeData.service.cout || 0;
            montantval.value = cout;
            demandeChangementForm.value?.setFieldValue('montant', montantval.value);
          } else {
            montantval.value = 0;
            demandeChangementForm.value?.setFieldValue('montant', montantval.value);
          }
        })
    }
    const currentDate = ref(new Date().toISOString().split('T')[0]);

    onMounted(async () => {
      currentDate.value = new Date().toISOString().split('T')[0];
      fetchAbonnements(abonneid);
      const typeDemandeData = getMontant(7);
      demandeChangementForm.value?.setFieldValue('montant', montantval.value);
    });


    const modules = {
      module: BlotFormatter,
      ImageUploader,
      options: {
        upload: (file: string | Blob) => {
          return new Promise((resolve, reject) => {
            const formData = new FormData();
            formData.append("image", file);

            axios
              .post("/upload-image", formData)
              .then((res: { data: { url: unknown } }) => {
                resolve(res.data.url);
              })
              .catch((err: unknown) => {
                reject("Upload failed");
              });
          });
        },
      },
    };
    return {
      modules, payer, currentDate,
      addDemandeChangement, demandeChangementSchema,
      demandeChangementForm, abonnements,
      abonnementOptions, montantval, abonneid
    };
  },
});
</script>