<template>
  <div class="card mb-25 border-0 rounded-0 bg-white letter-spacing">
    <div
      class="card-head box-shadow bg-white d-lg-flex align-items-center justify-content-between p-15 p-sm-20 p-md-25"
    >
      <div class="d-flex align-items-center">
        <form class="search-box position-relative me-15" @submit.prevent="rechercher">
          <input
            type="text"
            v-model="searchTerm"
            @keyup="rechercher"
            class="form-control shadow-none text-black rounded-0 border-0"
            placeholder="Rechercher une redevance"
          />
          <button
            type="submit"
            class="bg-transparent text-primary transition p-0 border-0"
          >
            <i class="flaticon-search-interface-symbol"></i>
          </button>
        </form>
      </div>
    </div>
    <div class="card-body p-15 p-sm-20 p-md-25">
      <div class="table-responsive">
        <table class="table text-nowrap align-middle mb-0">
          <thead>
            <tr>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
                REFERENCE
              </th>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
                ANNEE
              </th>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
                ABONNEMENT
              </th>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
                ARRIERES
              </th>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
                DATE DE REDEVANCE
              </th>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
              Statut
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(re , index) in redevances" :key="index" >
              <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                {{ re.refRedevance }}
              </td>
              <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                {{ re.annee }}
              </td>
              <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                {{ re.abonnement ? re.abonnement.refAbo : '' }}
              </td>
              <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                {{ re.arrieres  }}
              </td>
              <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                {{ format_date(re.dateRedevance) }}
              </td>
              <td class="shadow-none lh-1 fw-medium text-black-emphasis">
                <p class="text-warning"> {{ !re.statut ? "En attente" : re.statut  }}</p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div
        class="pagination-area d-md-flex mt-15 mt-sm-20 mt-md-25 justify-content-between align-items-center"
      >
        <PaginationComponent :page="page" :totalPages="totalPages" :totalElements="totalElements" :limit="limit" @paginate="handlePaginate" />
      </div>
    </div>
  </div>
  <AddRedevancePaiementModal/>
</template>

<script lang="ts">
import { Redevance } from "@/models/Redevance";
import { defineComponent, onMounted,toRef, ref,reactive, watch } from "vue";
import ApiService from "@/services/ApiService";
import { useRouter } from 'vue-router';
import AddRedevancePaiementModal from "./AddRedevancePaiementModal.vue";
import Swal from "sweetalert2";
import { format_date, showModal, suppression,success,error } from "../../utils/utils";
import {openKkiapayWidget,addKkiapayListener,removeKkiapayListener} from "kkiapay";
import JwtService from "@/services/JwtService";
import PaginationComponent from '@/components/Utilities/Pagination.vue';
import { KKIAPAY } from "@/models/KKIAPAY";

export default defineComponent({
  name: "ListeRedevance",
  components: {
    AddRedevancePaiementModal,
    PaginationComponent
  },
  setup() {
    
    const redevanceForm = ref<null | Redevance>(null);
    const router = useRouter();
    const redevances = ref<Array<Redevance>>([]);
    const abonneid= ref<null | string>(null)
    //   var check = reactive({
    //   typeError: "RAS",
    //   idTransaction: 0
    // });
    const selectedRedevanceId = ref<number | null>(null);

     // BEGIN PAGINATE
    const searchTerm = ref('');
    const page = ref(1);
    const totalPages = ref(0);
    const limit = ref(10);
    const totalElements = ref(0);

    const handlePaginate = ({ page_, limit_ }) => {
      try {
        page.value = page_;
        getAllRedevance(abonneid.value, page_, limit_);
      } catch (error) {
        //
      }
    };

     function rechercher(){
      getAllRedevance(abonneid.value, page.value, limit.value, searchTerm.value );
    }

    // END PAGINATE

    const getAllRedevance = async (abonneid:string|null, page = 1, limi = 10, searchTerm = '' ) => {
      return ApiService.get(`/redev/non?page=${page}&limit=${limi}&mot=${searchTerm}&`)
        .then((response) => {
          console.log(response,'responseresponseresponseresponse')
          if(response.status === 200){
            redevances.value = response.data.data.data;
            totalPages.value = response.data.data.totalPages;
            limit.value = response.data.data.limit;
            totalElements.value = response.data.data.totalElements;
          }
        })
        .catch((response) => {
          error(response.data.message);
        });
    };
    var check = reactive({
      typeError: "RAS",
      idTransaction: 0
    });

      async function chargement (donnee:any, infoTransaction:any){
        var paiement = {};
        paiement = {
          abonnement: donnee.abonnement.id,
          redevance: donnee.id,
          modePaiement: 1,
          montantRecu: donnee.abonnement.id,
          montantPaye: donnee.abonnement.montant,
          montantRestant: 0,
          transactionId: infoTransaction
        };
          if( infoTransaction.typeError === "ECHOUE"){
           await ApiService.post("/paiement_redevance",paiement)
              .then(({ data }) => {
                if(data.code == 201) { 
                  success(data.message)
                  check = reactive({
                    typeError: "RAS",
                    idTransaction: 0
                  });
                }
              }).catch(({ response }) => {
                error("Oups il y a eu une erreur")
                check = reactive({
                  typeError: "RAS",
                  idTransaction: 0
                });
              });
            error("Oups il y a eu une erreur")
            }else{
              if( check.typeError === "SUCCESS"){
                await ApiService.post("/paiement_redevance",paiement)
                .then(({ data }) => {
                  if(data.code == 201) { 
                    success(data.message)
                    check = reactive({
                      typeError: "RAS",
                      idTransaction: 0
                    });
                    getAllRedevance(abonneid.value);
                  }
                }).catch(({ response }) => {
                  error("Oups il y a eu une erreur")
                  check = reactive({
                      typeError: "RAS",
                      idTransaction: 0
                    });
                });
              // success("Paiement effectué avec succès")
            }
          }
      }

      async function successHandler(response, values) {
      // Mise à jour de l'objet check avec les informations de la réponse de succès
      check.typeError = "SUCCESS";
      check.idTransaction = response.transactionId;

      values["idTransaction"] = check.idTransaction;
      values["mode"] = 2;
      values["check"] = check;
      // Bloc de code à exécuter lorsque le paiement est un succès
      if (check.typeError === "SUCCESS") {
        // Appel de la fonction addAbonnement et attente de son exécution
        await chargement(values,check);
      } else {
        check.typeError = "RAS";
        check.idTransaction = 0;
      }

      // Retourne les données après l'exécution
      return { check, response };
    }

    async function failHandler(response) {
      // Mise à jour de l'objet check avec les informations de l'échec
      check.typeError = "ECHOUE";
      check.idTransaction = response.transactionId;

      check.typeError = "RAS";
      check.idTransaction = response.transactionId;

      // Retourne les données après l'exécution
      return { check, response };
    }

      const payer = async (redev) => {
        try {
          const result = await Swal.fire({
            text: "Vous êtes sur le point de faire un paiement d'une somme de " + redev.abonnement.montant + " F CFA",
            icon: "warning",
            buttonsStyling: true,
            showCancelButton: true,
            confirmButtonText: "Payer",
            cancelButtonText: `Annuler`,
            heightAuto: false,
            customClass: {
              confirmButton: "btn btn-danger",
            },
          });

          if (result.isConfirmed) {
            KKIAPAY.kkia(redev.abonnement.montant);
            await new Promise<void>((resolve) => {
              addKkiapayListener('failed', (event) => {
                failHandler(event);
                resolve();
              });

              addKkiapayListener('success', (event) => {
                successHandler(event, redev);
                resolve();
              });


              const checkRef = toRef(check);

              let resolved = false;

              const intervalId = setInterval(() => {
                if (!resolved && checkRef.value.typeError !== "RAS") {
                  clearInterval(intervalId);
                  resolved = true;
                  resolve();
                }
              }, 500);
            });
          } else {
            error("Ok d'accord on va patienter");
          }

        } catch (error) {
          // Gérer les erreurs ici
        }
      };
    const openRedevancePaiementModal = (id: number) => {
      selectedRedevanceId.value = id;
    };

    onMounted(() => {
      abonneid.value = JwtService.getUser();
      if (JwtService.getUser()) {
        getAllRedevance(abonneid.value);
      }
    });

    return { redevances,
        chargement,
        payer,
        redevanceForm,
        router,
        format_date,
        suppression,
        selectedRedevanceId,
        openRedevancePaiementModal,
        page, 
        totalPages,
        limit,
        totalElements,
        handlePaginate,
        rechercher,
        searchTerm
    };
  },
});
</script>