import { format_date, showModal,error,success, suppression } from "../utils/utils";
import Swal from "sweetalert2";
import {openKkiapayWidget,addKkiapayListener,removeKkiapayListener} from "kkiapay";

export class KKIAPAY {
 static privateKey =  "a7149dafb5f919efba5aed5e3722f1e49fea612d";
  //static privateKey = "bbcb2b106aaf11efa016f944ed967890";
  static typeError = "RAS";
  static idTransaction = 0;

  static async successHandler(response: any) {
    this.typeError = "SUCCESS";
    this.idTransaction = response.transactionId;
    return {check:{typeError:this.typeError,idTransaction:response.transactionId}, response};
  }

  static async check (){
    return {typeError:this.typeError,idTransaction:this.idTransaction};
  }


    
  static async failHandler(response: any) {
    this.typeError = "ECHOUE";
    this.idTransaction = response.transactionId;
    return {check:{typeError:this.typeError,idTransaction:response.transactionId}, response};
  }
  static kkia(montant: number) {
    openKkiapayWidget({
      amount: montant,
      position: "center",
      theme: '#f3c148',
      api_key: this.privateKey,
      sandbox: false,
      phone: "",
    });
  }
    
  static addKkiapayListenerWrapper(eventType: string,handler: (response: any) => Promise<any>) {
    addKkiapayListener(eventType, handler);
  }

}
