<template>
  <div class="card mb-25 border-0 rounded-0 bg-white letter-spacing">
    <div class="card-body p-15 p-sm-20 p-md-25">
      <div class="table-responsive">
        <table class="table text-nowrap align-middle mb-0">
          <thead>
            <tr>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0 ps-0"
              >
                Référence
              </th>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0 ps-0"
              >
                Nom et prénoms
              </th>
              
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
                Adresse Postale
              </th>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0 ps-0">
               Type de Boîte
              </th>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
                Détails
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(abonnement, index) in abonnements" :key="index">
              <td class="shadow-none lh-1 fw-medium text-primary">
                {{ abonnement.refAbo }}
              </td>
              <td class="shadow-none lh-1 fw-medium">
                {{ abonnement.abonne ? abonnement.abonne.nomComplet : "NULL" }}
              </td>
              <td class="shadow-none lh-1 fw-medium">
                {{ abonnement.boite?.adressePostale ? abonnement.boite?.adressePostale.adresse_postale : abonnement?.adresseVirtuelle }}
              </td>
              <td class="shadow-none lh-1 fw-medium">
                {{ abonnement.estMPostBox? "Boîte postale virtuelle":"Boîte postale traditionnelle" }}
              </td>
              <td class="shadow-none lh-1 fw-medium">
                <router-link 
                class="default-btn position-relative transition border-0 fw-medium text-white pt-11 pb-11 ps-25 pe-25 pt-md-12 pb-md-12 ps-md-30 pe-md-30 rounded-1 bg-success fs-md-15 fs-lg-16 d-inline-block me-10 mb-10 mb-lg-0 text-decoration-none"
                :to="{ name: 'ViewAbonnementPage', params: { id: abonnement.id } }"
              >
                Détails
                <i class="flaticon-view position-relative ms-5 fs-12"></i>
              </router-link>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
 
      <div
        class="pagination-area d-md-flex mt-15 mt-sm-20 mt-md-25 justify-content-between align-items-center">
        <PaginationComponent :page="page" :totalPages="totalPages" :totalElements="totalElements" :limit="limit" @paginate="handlePaginate" />
      </div>

    </div>
  </div>
</template>

<script lang="ts">
import JwtService from "@/services/JwtService";
import { defineComponent, onMounted, ref } from "vue";
import ApiService from "@/services/ApiService";
import { Abonnement, Abonnements } from "@/models/Abonnement";
import { separateur,error } from "../../utils/utils";

import PaginationComponent from '@/components/Utilities/Pagination.vue';
export default defineComponent({
    name: "ListAbonnements",
    components: {
      PaginationComponent
  },
  setup: () => {
    onMounted(() => {
      if (JwtService.getUser()) {
        getAllAbonnements(JwtService.getUser());
      }
    });

    const abonnements = ref<Array<Abonnements>>([]);

    // BEGIN PAGINATE
    const searchTerm = ref('');
    const page = ref(1);
    const totalPages = ref(0);
    const limit = ref(10);
    const totalElements = ref(0);
    const abonneid= ref<null | string>(null)

    const handlePaginate = ({ page_, limit_ }) => {
      try {
        page.value = page_;
        getAllAbonnements(abonneid.value, page_, limit_);
      } catch (error) {
        //
      }
    };
    function rechercher(){
      getAllAbonnements(abonneid.value, page.value, limit.value, searchTerm.value );
    }
    // END PAGINATE

    function getAllAbonnements(abonneid:string|null,page = 1, limi = 10, searchTerm = '') {
      return ApiService.get(`/abonnements/abonne?page=${page}&limit=${limi}&mot=${searchTerm}&`)
        .then(({ data }) => {
          abonnements.value = data.data.data;
          totalPages.value = data.data.totalPages;
          limit.value = data.data.limit;
          totalElements.value = data.data.totalElements;
        })
        .catch(({ response }) => {
          error(response.data.message)
      });
    }
    return { 
      abonnements,
      getAllAbonnements,
      page,
      totalPages,
      limit,
      totalElements,
      handlePaginate,
      searchTerm,
      separateur,
      rechercher
    };
  },
});
</script>