<template>
  <BreadCrumb PageTitle="Envoyer une redevance" />
  <AddRedevance />
  <AddRedevancePaiementModal />
  
</template>

<script lang="ts">
  import { defineComponent } from "vue";

  import BreadCrumb from "../../components/Common/BreadCrumb.vue";
  import AddRedevance from "../../components/Redevances/AddRedevance.vue";
  import AddRedevancePaiementModal from "../../components/Redevances/AddRedevancePaiementModal.vue";

  export default defineComponent({
    name: "AddRedevancePage",
    components: {
      BreadCrumb,
      AddRedevance,
      AddRedevancePaiementModal,
    },
  });
</script>