<template>
  <BreadCrumb PageTitle="Envoyer une demande de resiliation" />
  <AddDemandeResiliation />
</template>

<script lang="ts">
  import { defineComponent } from "vue";

  import BreadCrumb from "../../components/Common/BreadCrumb.vue";
  import AddDemandeResiliation from "../../components/DemandeResiliations/AddDemandeResiliation.vue";

  export default defineComponent({
    name: "AddDemandeResiliationPage",
    components: {
      BreadCrumb,
      AddDemandeResiliation,
    },
  });
</script>