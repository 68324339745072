<template>
  <div class="card mb-25 border-0 rounded-0 bg-white">
    <div
      class="card-body p-15 p-sm-20 p-sm-25 pt-lg-30 pb-lg-30 ps-lg-25 pe-lg-25 letter-spacing"
    >
      <div class="mb-10 mb-md-25 d-flex justify-content-between">
        <div class="title">
          <h5 class="card-title fw-bold mb-7">Actualités</h5>
          <span class="d-block text-black fw-medium">Pour vous, nous travaillons</span>
        </div>
        <div class="todays-event-swiper-pagination"></div>
      </div>
      <swiper
        :slides-per-view="1"
        :loop="true"
        :autoplay="{
          delay: 4000,
          disableOnInteraction: true,
        }"
        :pagination="{
          clickable: true,
        }"
        :modules="modules"
        class="todaysEventSwiper"
      >
        <swiper-slide v-for="course in courses" :key="course.id">
          <div>
            <div class="row ms-0 me-0 align-items-center">
              <div class="col-lg-4 ps-0 pe-0 pb-25">
                <img :src="course.image" alt="course-image" />
              </div>
              <div class="col-lg-8 ps-0 pe-0">
                <div class="content">
                  <router-link
                    to=""
                    class=" fw-bold text-decoration-none text-black fs-3 ms-10">
                    {{ course.title }}
                  </router-link>
                  <span class="mt-8 pb-10 text-black fw-medium d-flex align-items-center">
                    <span class="w-6 h-6 bg-white rounded-circle me-8" ></span>
                    {{ course.speakers }}
                  </span>
                </div>
              </div>
              <div class="col-lg-3 ps-0 pe-0 pb-25">
              </div>
              <div class="col-lg-6 ps-0 pe-0 pb-25">
                <div class="ms-10 pt-15 pt-md-15 " >
                  <a :href="course.link" target="_blank" class="default-btn transition border-0 fw-medium text-white pt-10 pb-10 ps-25 pe-25 pt-md-11 pb-md-11 ps-md-35 pe-md-35 rounded-1 fs-md-15 fs-lg-16">Lire la suite</a>
                </div>
              </div>
              <div class="col-lg-3 ps-0 pe-0 pb-25">
              </div>
            </div>
            <!-- <span
              class="fw-medium text-secondary mt-10 mt-md-25 mb-5 mb-md-8 d-block"
            >
              Start
              <span class="fw-bold text-black">
                in {{ course.start }} mins
              </span>
            </span> -->
            <!-- <div
              class="progress"
              role="progressbar"
              :aria-valuenow="course.progress"
              aria-valuemin="0"
              aria-valuemax="100"
            >
              <div
                class="progress-bar"
                :style="{ width: course.progress + '%' }"
              ></div>
            </div> -->
           
          </div>
        </swiper-slide>
      </swiper>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { Swiper, SwiperSlide } from "swiper/vue";
import { Autoplay, Pagination } from "swiper/modules";

export default defineComponent({
  name: "TodaysEvent",
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      courses: [
        {
          id: 1,
          image: require("../../../assets/images/courses/banner2.jpg"),
          title: "Distinction de la Poste du Bénin",
          speakers: "La concurrence mondiale dans le secteur de la livraison rapide est féroce et de nombreuses entreprises cherchent à améliorer leur qualité de service pour satisfaire les besoins en constante évolution de leur clientèle. C’est dans ce contexte que La Poste du Bénin a récemment été distinguée comme Meilleur service de livraison rapide à la clientèle au monde pour le compte de l’année 2022. Et ceci, grâce à EMS Bénin, sa structure dédiée à la collecte et à la livraison express des courriers, paquets...",
          topics: "5",
          start: "7",
          progress: "60",
          link: "https://laposte.bj/distinction-de-la-poste-du-benin-comme-meilleur-service-de-livraison-rapide-a-la-clientele-au-monde/"
        },
        {
          id: 2,
          image: require("../../../assets/images/courses/banner3.jpeg"),
          title: "Financement du secteur postal",
          speakers: "Ainsi, pendant trois jours, les représentants des ministères et des régulateurs postaux, les institutions financières et bancaires, les experts en financement et en développement économique, les organisations régionales et internationales, les entreprises privées opérant dans le secteur postal se sont rassemblés à Cotonou au Bénin pour parler des enjeux liés au financement du secteur postal en Afrique, d’explorer les différentes sources de financement disponibles et de promouvoir la collaboration entre les acteurs...",
          topics: "4",
          start: "2",
          progress: "80",
          link: "https://laposte.bj/journee-mondiale-de-la-poste-edition-2023-programme-des-manifestations/"
        },
        {
          id: 3,
          image: require("../../../assets/images/courses/banner4.jpeg"),
          title: "Prospection commerciale",
          speakers: "La prospection commerciale est une stratégie de vente très efficace pour les entreprises qui souhaitent renforcer leur présence sur le terrain. Cette stratégie consiste à se rendre sur des lieux de forte affluence économique pour proposer ses produits aux clients.A cet effet, les équipes commerciales du Département Marketing appuyées des Chefs Sections relations clients CNE, CCP, des Chefs d’Agences respectifs ont sillonné les différents marchés relevant de leurs zones commerciales...",
          topics: "6",
          start: "5",
          progress: "90",
          link: "https://laposte.bj/prospection-commerciale-dans-les-marches-de-cotonou-et-porto-novo-une-strategie-payante-pour-la-poste-du-benin/"
        },
      ],
    };
  },
  setup() {
    return {
      modules: [Autoplay, Pagination],
    };
  },
});
</script>