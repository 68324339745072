<template>
  <BreadCrumb PageTitle="Liste des réclamations" />
  <ListReclamations />
</template>

<script lang="ts">
import { defineComponent } from "vue";

import BreadCrumb from "../../components/Common/BreadCrumb.vue";
import ListReclamations from "../../components/Reclamations/ListReclamations.vue";


export default defineComponent({
  name: "ListReclamationsPage",
  components: {
    BreadCrumb,
    ListReclamations,
  },
});
</script>