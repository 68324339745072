<template>
  <BreadCrumb PageTitle="Faire un réabonnement" />
  <AddReabonnement />
</template>

<script lang="ts">
  import { defineComponent } from "vue";

  import BreadCrumb from "../../components/Common/BreadCrumb.vue";
  import AddReabonnement from "../../components/Reabonners/AddReabonnement/AddReabonnement.vue";

  export default defineComponent({
    name: "AddReabonnementPage",
    components: {
      BreadCrumb,
      AddReabonnement,
    },
  });
</script>