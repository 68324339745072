<template>
  <div class="card mb-25 border-0 rounded-0 bg-white checkout-box letter-spacing">
    <div class="card-body">
      <ul class="nav nav-tabs bg-white border-0 rounded-0" id="myTab" role="tablist">
        <li class="nav-item" role="presentation">
          <button
            class="nav-link fs-14 fs-md-15 fs-lg-16 fw-semibold position-relative border-0 rounded-0 d-block w-100 active"
            id="shipping-details-tab" data-bs-toggle="tab" data-bs-target="#shipping-details-tab-pane" type="button"
            role="tab" aria-controls="shipping-details-tab-pane" aria-selected="true">
            <i class="flaticon-express-delivery"></i>
            ABONNEMENT
          </button>
        </li>
        <li class="nav-item" role="presentation">
          <button
            class="nav-link fs-14 fs-md-15 fs-lg-16 fw-semibold position-relative border-0 rounded-0 d-block w-100"
            id="payment-method-tab" data-bs-toggle="tab" data-bs-target="#payment-method-tab-pane" type="button"
            role="tab" aria-controls="payment-method-tab-pane" aria-selected="false">
            <i class="flaticon-atm"></i>
            ABONNE {{ nom ? ' ' + nom : "" }}
          </button>
        </li>
        <li class="nav-item" role="presentation">
          <button
            class="nav-link fs-14 fs-md-15 fs-lg-16 fw-semibold position-relative border-0 rounded-0 d-block w-100"
            id="place-order-tab" data-bs-toggle="tab" data-bs-target="#place-order-tab-pane" type="button" role="tab"
            aria-controls="place-order-tab-pane" aria-selected="false">
            <i class="flaticon-sterile-box"></i>
            PAIEMENT
          </button>
        </li>
      </ul>
      <div class="tab-content p-15 p-sm-20 p-md-25 p-lg-30" id="myTabContent">
        <div class="tab-pane fade show active" id="shipping-details-tab-pane" role="tabpanel" tabindex="0">
          <h5 class="mb-15 mb-md-25 fw-bold text-black">
            Informations sur l'abonnement
          </h5>
          <Form>
            <div class="row">
              <div class="col-md-6">
                <div class="form-group mb-15 mb-sm-20 mb-md-25">
                  <label class="d-block text-black fw-semibold mb-10">
                    Commune <span class="text-danger">*</span>
                  </label>
                  <Field name="commune" v-model="commune" v-slot="{ field }">
                    <Multiselect v-model="field.value" v-bind="field" :options="communesOptions" :searchable="true"
                      placeholder="Rechercher une commune" noResultText="Aucun enregistrement trouvé"
                      noOptionsText="Tapez au moins deux caractères" />
                  </Field>
                  <ErrorMessage name="commune" class="text-danger" />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group mb-15 mb-sm-20 mb-md-25">
                  <label class="d-block text-black fw-semibold mb-10">
                    Agence <span class="text-danger">*</span>
                  </label>
                  <Field name="bureau" v-model="bureau" v-slot="{ field }">
                    <Multiselect v-model="field.value" v-bind="field" :options="bureauxOptions"
                      placeholder="Selectionner un bureau" />
                  </Field>
                  <ErrorMessage name="bureau" class="text-danger" />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group mb-15 mb-sm-20 mb-md-25">
                  <label class="d-block text-black fw-semibold mb-10">
                    Boîte <span class="text-danger">*</span>
                  </label>
                  <Field v-model="boite" name="boite" v-slot="{ field }">
                    <Multiselect v-model="field.value" v-bind="field" :searchable="true"
                      placeholder="Sélectionner une boite" :options="boitesOptions"
                      noOptionsText="Sélectionner d'abord le bureau et le modèle" />
                  </Field>
                  <ErrorMessage name="boite" class="text-danger" />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group mb-15 mb-sm-20 mb-md-25">
                  <label class="d-block text-black fw-semibold mb-10">
                    Abonnement <span class="text-danger">*</span>
                  </label>
                  <Field name="abonne" v-slot="{ field }" v-model="abonnement">
                    <Multiselect v-model="field.value" v-bind="field" :disabled="true"
                      placeholder="Rechercher un abonnement" :searchable="true" :options="abonnementsOptions" />
                  </Field>
                  <ErrorMessage name="abonne" class="text-danger" />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group mb-15 mb-sm-20 mb-md-25">
                  <label class="d-block text-black fw-semibold mb-10">
                    Adresse postale
                  </label>
                  <Field readonly="true" v-model="adressePostale" name="adressePostale" type="text" aria-disabled="true"
                    class="form-control shadow-none fs-md-15 text-black" placeholder="Adresse Postale" />
                  <ErrorMessage name="adressePostale" class="text-danger" />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group mb-15 mb-sm-20 mb-md-25">
                  <label class="d-block text-black fw-semibold mb-10">
                    Type de boîte
                  </label>
                  <Field name="typeBoite" v-slot="{ field }">
                    <Multiselect :options="options" :searchable="true" v-model="field.value" v-bind="field"
                      placeholder="Sélectionner le type de boîte" />
                  </Field>
                  <ErrorMessage name="typeBoite" class="text-danger" />
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group mb-15 mb-sm-20 mb-md-25">
                  <label class="d-block text-black fw-semibold mb-10">
                    Loyer annuel
                  </label>
                  <Field readonly="true" v-model="loyer" name="loyer" type="text" aria-disabled="true"
                    class="form-control shadow-none fs-md-15 text-black" placeholder="Entrer le loyer mensuel" />
                  <ErrorMessage name="loyer" class="text-danger" />
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group mb-15 mb-sm-20 mb-md-25">
                  <label class="d-block text-black fw-semibold mb-10">
                    Caution
                  </label>
                  <Field readonly="true" v-model="caution" name="caution" type="text" aria-disabled="true"
                    class="form-control shadow-none fs-md-15 text-black" placeholder="Entrer le loyer mensuel" />
                  <ErrorMessage name="caution" class="text-danger" />
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group mb-15 mb-sm-20 mb-md-25">
                  <label class="d-block text-black fw-semibold mb-10">
                    Montant Abonnement
                  </label>
                  <Field readonly="true" v-model="montantAb" name="montant" type="text" aria-disabled="true"
                    class="form-control shadow-none fs-md-15 text-black" placeholder="Entrer le loyer mensuel" />
                  <ErrorMessage name="montant" class="text-danger" />
                </div>
              </div>
            </div>
          </Form>
          <!-- {# <div class="d-sm-flex align-items-center justify-content-between">
            <span></span>
            <button
              class="default-btn transition border-0 fw-medium text-white pt-11 pb-11 ps-25 pe-25 pt-md-12 pb-md-12 ps-md-35 pe-md-35 rounded-1 fs-md-15 fs-lg-16 bg-success"
              type="submit"
            >
              INFOS ABONNE
              <i class="flaticon-right-arrow position-relative ms-5 top-2"></i>
            </button>
          </div> #}-->
        </div>
        <div class="tab-pane fade" id="payment-method-tab-pane" role="tabpanel" tabindex="0">
          <h5 class="mb-15 mb-md-25 fw-bold text-black">
            Information sur l'abonné
          </h5>
          <Form>
            <div class="row">
              <div class="col-md-6">
                <div class="form-group mb-15 mb-sm-20 mb-md-25">
                  <label class="d-block text-black fw-semibold mb-10">
                    Nom Complet
                  </label>
                  <Field readonly="true" v-model="nom" name="nom" type="text" aria-disabled="true"
                    class="form-control shadow-none fs-md-15 text-black" placeholder="Nom complet" />
                  <ErrorMessage name="nom" class="text-danger" />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group mb-15 mb-sm-20 mb-md-25">
                  <label class="d-block text-black fw-semibold mb-10">
                    Catégorie Abonné
                  </label>
                  <Field readonly="true" v-model="categorieAbonne" name="categorie" type="text" aria-disabled="true"
                    class="form-control shadow-none fs-md-15 text-black" placeholder="Catégorie abonné" />
                  <ErrorMessage name="categorie" class="text-danger" />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group mb-15 mb-sm-20 mb-md-25">
                  <label class="d-block text-black fw-semibold mb-10">
                    Nationalité
                  </label>
                  <Field readonly="true" v-model="nationalite" name="nationalite" type="text" aria-disabled="true"
                    class="form-control shadow-none fs-md-15 text-black" placeholder="Catégorie abonné" />
                  <ErrorMessage name="nationalite" class="text-danger" />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group mb-15 mb-sm-20 mb-md-25">
                  <label class="d-block text-black fw-semibold mb-10">
                    Téléphone
                  </label>
                  <Field readonly="true" v-model="telephone" name="telephone" type="text" aria-disabled="true"
                    class="form-control shadow-none fs-md-15 text-black" placeholder="Téléphone" />
                  <ErrorMessage name="telephone" class="text-danger" />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group mb-15 mb-sm-20 mb-md-25">
                  <label class="d-block text-black fw-semibold mb-10">
                    Email
                  </label>
                  <Field readonly="true" v-model="email" name="email" type="text" aria-disabled="true"
                    class="form-control shadow-none fs-md-15 text-black" placeholder="Adresse postale" />
                  <ErrorMessage name="email" class="text-danger" />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group mb-15 mb-sm-20 mb-md-25">
                  <label class="d-block text-black fw-semibold mb-10">
                    Sexe
                  </label>
                  <Field readonly="true" v-model="sexe" name="sexe" type="text" aria-disabled="true"
                    class="form-control shadow-none fs-md-15 text-black" placeholder="Adresse Postale" />
                  <ErrorMessage name="sexe" class="text-danger" />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group mb-15 mb-sm-20 mb-md-25">
                  <label class="d-block text-black fw-semibold mb-10">
                    Activité
                  </label>
                  <Field readonly="true" v-model="activite" name="activite" type="text" aria-disabled="true"
                    class="form-control shadow-none fs-md-15 text-black" placeholder="Adresse Postale" />
                  <ErrorMessage name="activite" class="text-danger" />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group mb-15 mb-sm-20 mb-md-25">
                  <label class="d-block text-black fw-semibold mb-10">
                    Adresse Résidence
                  </label>
                  <Field readonly="true" v-model="adresse" name="adresse" type="text" aria-disabled="true"
                    class="form-control shadow-none fs-md-15 text-black" placeholder="Adresse Postale" />
                  <ErrorMessage name="adresse" class="text-danger" />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group mb-15 mb-sm-20 mb-md-25">
                  <label class="d-block text-black fw-semibold mb-10">
                    Profession
                  </label>
                  <Field readonly="true" v-model="profession" name="profession" type="text" aria-disabled="true"
                    class="form-control shadow-none fs-md-15 text-black" placeholder="Adresse Postale" />
                  <ErrorMessage name="profession" class="text-danger" />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group mb-15 mb-sm-20 mb-md-25">
                  <label class="d-block text-black fw-semibold mb-10">
                    Date de naissance
                  </label>
                  <Field readonly="true" v-model="dateNaissance" name="dateNaissance" type="text" aria-disabled="true"
                    class="form-control shadow-none fs-md-15 text-black" placeholder="Adresse Postale" />
                  <ErrorMessage name="dateNaissance" class="text-danger" />
                </div>
              </div>
            </div>
          </Form>
          <!-- {# <div
            class="mt-15 mt-sm-20 mt-md-25 d-sm-flex align-items-center justify-content-between"
          >
            <button
              type="button"
              class="d-inline-block fs-14 fs-md-15 fs-lg-16 fw-medium text-decoration-none bg-transparent p-0 border-0 text-primary mb-12 mb-sm-0"
            >
              <i
                class="flaticon-left-arrow lh-1 me-5 position-relative top-2"
              ></i>
             Retour
            </button>
            <button
              class="default-btn transition border-0 fw-medium text-white pt-11 pb-11 ps-25 pe-25 pt-md-12 pb-md-12 ps-md-35 pe-md-35 rounded-1 fs-md-15 fs-lg-16 bg-success"
              type="submit"
            >
             Suivant
              <i class="flaticon-right-arrow position-relative ms-5 top-2"></i>
            </button>
          </div> #}-->
        </div>
        <div class="tab-pane fade" id="place-order-tab-pane" role="tabpanel" tabindex="0">
          <h5 class="mb-15 mb-md-25 fw-bold text-black">
            Effectué un paiement
          </h5>
          <div class="box p-15 p-sm-20 p-md-25 mb-15 mb-md-25">
            <div class="mb-15 mb-sm-20 mb-md-25 d-flex align-items-center justify-content-between">
              <h6 class="fw-bold text-black-emphasis mb-0">Listes des redevances en attente</h6>
              <a class="d-inline-block text-decoration-none lh-1 text-muted fw-medium"><i>{{ montantTot + " F CFA"
                  }}</i>
              </a>
            </div>
          </div>
          <Form ref="redeForm" @submit="payer" :validation-schema="redeSchema">
            <div class="box p-15 p-sm-20 p-md-25 mb-15 mb-md-25 d-flex row">
              <div class="inner-box p-15 p-sm-20 p-md-25 bg-white col-4" v-for="(red, index) in redevances"
                :key="index">
                <div class="row">
                  <div class="col-md-12 justify-content-between d-flex flex-row">
                    <div>
                      <label for="jack">{{ "RED -- 00 " + red.annee }}</label>
                    </div>
                    <div>
                      <Field type="checkbox" name="check" :id="red.id" :value="red.id" v-model="redevs" />
                      <ErrorMessage name="check" class="text-danger" />
                    </div>
                  </div>
                  <div class="col-md-12">
                    <ul class="ps-0 mb-0 list-unstyled">
                      <li class="text-paragraph fs-md-15 fs-lg-16 position-relative">
                        <span>Nom:</span>
                        {{ nom }}
                      </li>
                      <li class="text-paragraph fs-md-15 fs-lg-16 position-relative">
                        <span>Numéro</span>
                        {{ telephone }}
                      </li>
                      <li class="text-paragraph fs-md-15 fs-lg-16 position-relative">
                        <span>Email:</span>
                        {{ email }}
                      </li>
                      <li class="text-paragraph fs-md-15 fs-lg-16 position-relative">
                        <span>Adresse:</span>
                        {{ adresse }}
                      </li>
                    </ul>
                  </div>
                </div>

              </div>

            </div>

            <div class="row">
              <div class="col-md-4">
                <div class="form-group mb-15 mb-sm-20 mb-md-25">
                  <label class="d-block text-black fw-semibold mb-10">
                    Loyer Annuel <span class="text-danger">*</span>
                  </label>
                  <Field readonly="true" v-model="loyer" name="loyer" type="text" aria-disabled="true"
                    class="form-control shadow-none fs-md-15 text-black" placeholder="XXXXXXX" />
                  <ErrorMessage name="loyer" class="text-danger" />
                </div>
              </div>
              <!-- <div class="col-md-4">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="d-block text-black fw-semibold mb-10">
                Mode de paiement <span class="text-danger">*</span>
                </label>
                <Field name="mode" v-slot="{ field }" v-model="mode">
                  <Multiselect 
                    :options="modeOptions" 
                    :searchable="true"
                    v-model="field.value"
                    v-bind="field"
                    placeholder="Sélectionner le mode de paiement"
                  />
                </Field>
                <ErrorMessage name="mode" class="text-danger"/>
              </div>
            </div> -->
              <div class="col-md-4">
                <div class="form-group mb-15 mb-sm-20 mb-md-25">
                  <label class="d-block text-black fw-semibold mb-10">
                    Montant à Payer <span class="text-danger">*</span>
                  </label>
                  <Field readonly="true" v-model="montantTot" name="montantTot" type="text" aria-disabled="true"
                    class="form-control shadow-none fs-md-15 text-black" placeholder="Catégorie abonné" />
                  <ErrorMessage name="montantTot" class="text-danger" />
                </div>
              </div>
            </div>
            <div class="mt-15 mt-sm-20 mt-md-25 d-sm-flex align-items-center justify-content-between">
              <button type="button"
                class="d-inline-block fs-14 fs-md-15 fs-lg-16 fw-medium text-decoration-none bg-transparent p-0 border-0 text-primary mb-8 mb-sm-0">
                <!-- <i
                class="flaticon-left-arrow lh-1 me-5 position-relative top-2"
              ></i>
              RETOUR SUR L'ABONNE-->
              </button>
              <button
                class="default-btn transition border-0 fw-medium text-white pt-11 pb-11 ps-25 pe-25 pt-md-12 pb-md-12 ps-md-35 pe-md-35 rounded-1 fs-md-15 fs-lg-16 bg-success"
                type="submit">
                PAYER MAINTENANT
                <i class="flaticon-right-arrow position-relative ms-5 top-2"></i>
              </button>
            </div>
          </Form>

        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, toRef, onMounted, watch, reactive } from 'vue';
import { Form, Field, ErrorMessage } from 'vee-validate';
import Multiselect from '@vueform/multiselect'
import * as Yup from 'yup';
import axios from 'axios';
import router from '@/router';
import ApiService from '../../services/ApiService';
import { useRouter } from 'vue-router';
import { error, success } from '@/utils/utils';
import { openKkiapayWidget, addKkiapayListener, removeKkiapayListener } from "kkiapay";
import Swal from "sweetalert2";
import { KKIAPAY } from '@/models/KKIAPAY';

export default defineComponent({
  name: "AddReabonnement",
  components: {
    Form,
    Field,
    ErrorMessage,
    Multiselect,
  },

  setup: () => {

    const redeSchema = Yup.object().shape({
      montantTot: Yup.number().required('Le montant est obligatoire'),
      loyer: Yup.number().required('Le loyer est obligatoire'),
      //mode :Yup.number().required('Le mode est obligatoire'),
      check: Yup.array().required('La catégorie est obligatoire'),
    });

    const redeForm = ref();
    const communesOptions = ref([]);
    const bureauxOptions = ref([]);
    const redevances = ref([]);
    const boitesOptions = ref([]);
    const boite = ref();
    const redevs = ref([])
    const adressePostale = ref();
    const commune = ref();
    const loyer = ref();
    const bureau = ref();
    const adresse = ref();
    const activite = ref();
    const profession = ref();
    const sexe = ref();
    const dure = ref();
    const mode = ref();
    const email = ref();
    const dateNaissance = ref();
    const nationalite = ref();
    const telephone = ref();
    const villeResidence = ref();
    const categorieAbonne = ref();
    const nom = ref();
    const options = [
      { value: '', label: 'Selectionnez un type' },
      { value: 'Ordinaire', label: 'Ordinaire' },
      { value: 'Spécifique', label: 'Spécifique' }
    ];

    const adressesOptions = ref([]);
    const modeOptions = ref([]);
    const abonnementsOptions = ref([]);
    const abonnement = ref();
    const montantAb = ref();
    const caution = ref();
    const fetchCommune = async () => {
      ApiService.get('/communes')
        .then(({ data }) => {
          const donneee = data.data;
          communesOptions.value = donneee.map((commune: any) => {
            return {
              label: commune.libelle,
              value: commune.id,
            };
          });
        })
        .catch((response) => {
          error(response.data.message);
        });
    }
    const addAPaiementRedev = async (values, { resetForm }) => {
      ApiService.post("/paiement_redevance/by/redevance", values)
        .then(({ data }) => {
          if (data.code == 201) {
            success(data.message);
            router.push({ name: "ListeReabonnementPage" });
          }
        }).catch(({ response }) => {
          error(response.data.message);
        });
    };
    const fetchAgences = async (key) => {
      try {
        const response = await axios.get('/bureaux/commune/' + key);
        const bureauxData = response.data.data;
        bureauxOptions.value = bureauxData.map((bureau) => ({
          value: bureau.id,
          label: bureau.nom,
        }));
        return bureauxOptions.value;
      } catch (error) {
        //
      }
    };

    const fetchModePaiement = async () => {
      try {
        const response = await axios.get('/modepaiements');
        const modesData = response.data.data;
        modeOptions.value = modesData.map((mode) => ({
          value: mode.id,
          label: mode.libelle,
        }));
        return modeOptions.value;
      } catch (error) {
        //
      }
    };


    const fetchBoites = async (key) => {
      try {
        const response = await axios.get('/boitePostales/use/bureau/' + key);
        const bureauxData = response.data.data;
        boitesOptions.value = bureauxData.map((boite) => ({
          value: boite.id,
          label: boite.refBoite,
        }));
        return bureauxOptions.value;
      } catch (error) {
        //
      }
    };

    const fetchAbonnement = async (key) => {
      try {
        const response = await axios.get('/abonnements/by/boite/' + key);
        const abonnementsData = response.data.data;
        abonnementsOptions.value = abonnementsData.map((abonnement) => ({
          value: abonnement.id,
          label: abonnement.refAbo,
        }));
        const id = abonnementsData ? abonnementsData[0] : null;
        // Return the updated value of abonnement.value
        return id;
      } catch (error) {
        //
      }
    };
    var check = reactive({
      typeError: "RAS",
      idTransaction: 0
    });

    async function successHandler(response, values, { resetForm }) {
      check.typeError = "SUCCESS";
      check.idTransaction = response.transactionId;
      values["idTransaction"] = check.idTransaction;
      values["check"] = check;
      if (check.typeError === "SUCCESS") {
        await addAPaiementRedev(values, { resetForm });
      } else {
        check.typeError = "RAS";
        check.idTransaction = 0;
      }

      // Retourne les données après l'exécution
      return { check, response };
    }

    async function failHandler(response) {
      // Mise à jour de l'objet check avec les informations de l'échec
      check.typeError = "ECHOUE";
      check.idTransaction = response.transactionId;

      check.typeError = "RAS";
      check.idTransaction = response.transactionId;

      // Retourne les données après l'exécution
      return { check, response };
    }

    const fetchRedevances = async (key) => {
      try {
        const response = await axios.get('/redev/non/abonnement/' + key);
        const abonnementsData = response.data.data;
        return abonnementsData;
      } catch (error) {
        //
      }
    };

    const payer = async (values, { resetForm }) => {
      if (values.mode == 1) {
        try {
          const result = await Swal.fire({
            text: "Vous êtes sur le point de faire un paiement d'une somme de " + values.montantTot + " F CFA",
            icon: "warning",
            buttonsStyling: true,
            showCancelButton: true,
            confirmButtonText: "Payer",
            cancelButtonText: `Annuler`,
            heightAuto: false,
            customClass: {
              confirmButton: "btn btn-danger",
            },
          });

          if (result.isConfirmed) {
            addAPaiementRedev(values, { resetForm });
          }
          // else {
          //   error("Ok");
          // }

        } catch (error) {
          // Gérer les erreurs ici
        }
        resetForm();
      }
      if (values.mode == 2) {
        try {
          const result = await Swal.fire({
            text: "Vous êtes sur le point de faire un paiement d'une somme de " + values.montantTot + " F CFA",
            icon: "warning",
            buttonsStyling: true,
            showCancelButton: true,
            confirmButtonText: "Payer",
            cancelButtonText: `Annuler`,
            heightAuto: false,
            customClass: {
              confirmButton: "btn btn-danger",
            },
          });

          if (result.isConfirmed) {
            KKIAPAY.kkia(values.montantTot);
            await new Promise<void>((resolve) => {
              addKkiapayListener('failed', (event) => {
                failHandler(event);
                resolve();
              });

              addKkiapayListener('success', (event) => {
                successHandler(event, values, { resetForm });
                resolve();
              });

              const checkRef = toRef(check);

              let resolved = false;
              const intervalId = setInterval(() => {
                if (!resolved && checkRef.value.typeError !== "RAS") {
                  clearInterval(intervalId);
                  resolved = true;
                  resolve(); // Ajoutez ici un argument (par exemple, undefined)
                }
              }, 500); // Vérifier toutes les 500 millisecondes
            });
            
          }
          // else {
          //   error("Ok");
          // }

        } catch (error) {
          // Gérer les erreurs ici
        }
      }

    };


    watch(boite, async (newValue, oldValue) => {
      if (newValue != null) {
        const val = await fetchAbonnement(newValue);
        abonnement.value = val ? val.id : null;
        montantAb.value = val ? val.montant : null;
        loyer.value = val ? val.loyer : null;
        if (val) {
          if (val.abonne) {
            adresse.value = val.abonne.adresseResidence;
            activite.value = val.abonne.activite.libelle;
            profession.value = val.abonne.profession;
            sexe.value = val.abonne.sexe;
            email.value = val.abonne.email;
            dateNaissance.value = val.abonne.dateNaissance;
            nationalite.value = val.abonne.nationalite;
            telephone.value = val.abonne.telephone;
            villeResidence.value = val.abonne.villeResidence;
            categorieAbonne.value = val.abonne.categorieAbonne.libelle;
            nom.value = val.abonne.nomComplet;
          }
        }
        redevances.value = val ? await fetchRedevances(val.id) : null;
        adressePostale.value = val ? val.adressePostale.adresse_postale : null;
      } else {
        abonnementsOptions.value = [];
        redevances.value = [];
        adressePostale.value = null;
        boite.value = null;
        abonnement.value = null;
        montantAb.value = null;
        loyer.value = null;
        profession.value = null;
        sexe.value = null;
        email.value = null;
        dateNaissance.value = null;
        nationalite.value = null;
        activite.value = null;
        dure.value = null;
        telephone.value = null;
        adresse.value = null;
        villeResidence.value = null;
        categorieAbonne.value = null;
        nom.value = null;
        adressesOptions.value = [];
        mode.value = null;
        email.value = null;
        dateNaissance.value = null;
        caution.value = null;
      }
    });
    onMounted(() => {
      fetchModePaiement()
      fetchCommune();
    });

    watch(commune, (newValue, oldValue) => {
      commune.value = newValue;
      if (newValue != null) {
        fetchAgences(newValue);
      } else {
        bureauxOptions.value = [];
        boitesOptions.value = [];
        abonnementsOptions.value = [];
        redevances.value = [];
        bureau.value = null;
        adressePostale.value = null;
        boite.value = null;
        abonnement.value = null;
        montantAb.value = null;
        loyer.value = null;
        profession.value = null;
        sexe.value = null;
        email.value = null;
        dateNaissance.value = null;
        nationalite.value = null;
        activite.value = null;
        dure.value = null;
        telephone.value = null;
        adresse.value = null;
        villeResidence.value = null;
        categorieAbonne.value = null;
        nom.value = null;
        adressesOptions.value = [];
        mode.value = null;
        email.value = null;
        dateNaissance.value = null;
        caution.value = null;

      }
    });
    const montantTot = ref(0);
    watch(redevs, (newValue, oldValue) => {
      redevs.value = newValue;
      if (newValue.length > 0) {
        montantTot.value = newValue.length * loyer.value;
      } else {
        montantTot.value = 0;
      }

    });

    watch(bureau, (newValue, oldValue) => {
      bureau.value = newValue;
      if (newValue != null) {
        fetchBoites(newValue);
      } else {
        boitesOptions.value = [];
        abonnementsOptions.value = [];
        redevances.value = [];
        bureau.value = null;
        adressePostale.value = null;
        boite.value = null;
        abonnement.value = null;
        montantAb.value = null;
        loyer.value = null;
        profession.value = null;
        sexe.value = null;
        email.value = null;
        dateNaissance.value = null;
        nationalite.value = null;
        activite.value = null;
        dure.value = null;
        telephone.value = null;
        adresse.value = null;
        villeResidence.value = null;
        categorieAbonne.value = null;
        nom.value = null;
        adressesOptions.value = [];
        mode.value = null;
        email.value = null;
        dateNaissance.value = null;
        caution.value = null;
      }
      // fetchBoites(newValue);
    });

    // watch(adressePostale, (newValue, oldValue) => {
    //   adressePostale.value = newValue;
    //   fetchBoites(newValue);
    // });


    return {
      communesOptions,
      bureauxOptions,
      boitesOptions,
      abonnementsOptions,
      options,
      redeForm,
      boite,
      adressePostale,
      commune,
      loyer,
      abonnement,
      bureau,
      dure,
      redeSchema,
      caution,
      montantAb,
      adresse,
      mode,
      activite,
      addAPaiementRedev,
      profession,
      sexe,
      payer,
      modeOptions,
      email,
      dateNaissance,
      nationalite,
      telephone,
      villeResidence,
      categorieAbonne,
      nom,
      montantTot,
      redevs,
      redevances,
      adressesOptions,
      fetchBoites,
      fetchCommune,
      fetchAbonnement,
    }
  }

});
</script>