<template>
  <div
    class="modal fade createNewModal"
    id="AddRedevancePaiementModal"
    tabindex="-1"
    ref="addRedevancePaiementRef"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content p-15 p-md-40">
        <div class="modal-header d-block ps-0 pe-0 pt-0 pb-15 pb-md-25">
          <h5 class="modal-title fw-bold text-black"></h5>
        </div>
        <div class="modal-body ps-0 pe-0 pb-0 pt-15 pt-md-25">
          <Form ref="redevanceForm" @submit="addRedevancePaiement" :validation-schema="redevanceSchema">
            <div class="row">
              <div class="col-md-12">
                <div class="form-group mb-15 mb-sm-20 mb-md-25">
                  <label class="d-block text-black fw-semibold mb-10">Tarif</label>
                  <Field v-model="montantval" readonly="true" name="montant" type="text" class="form-control shadow-none fs-md-15 text-black" placeholder="Tarif" />
                  <ErrorMessage name="montant" class="text-danger" />
                </div>
              </div>
              <div class="col-md-12">
                <div class="d-flex align-items-center">
                  <button
                    class="default-btn me-20 transition border-0 fw-medium text-white pt-10 pb-10 ps-25 pe-25 pt-md-11 pb-md-11 ps-md-35 pe-md-35 rounded-1 fs-md-15 fs-lg-16 bg-success"
                    type="submit"
                  >
                    Payé la redevance
                  </button>
                  <router-link to="/liste-paiementRedevances" 
                class=" btn btn-danger transition border-0 lh-1 fw-medium"><i class="flaticon-delete lh-1 me-1 position-relative top-2"></i>
                <span class="position-relative"></span>Annuler</router-link>
                </div>
              </div>
            </div>
          </Form>
        </div>
        <button type="button" class="btn-close shadow-none" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref, watch } from 'vue';
import { Form, Field, ErrorMessage, useForm } from 'vee-validate';
import * as Yup from 'yup';
import { error, success } from '@/utils/utils';
import { Redevance } from '@/models/Redevance';
import { useRouter } from 'vue-router';
import ApiService from '@/services/ApiService';

export default defineComponent({
  name: 'AddRedevancePaiementModal',
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  setup: () => {
    const redevanceSchema = Yup.object().shape({
      montant: Yup.number().required(),
    });

    const redevanceForm = ref<Redevance | null>(null);
    const router = useRouter();
    const montantval = ref(0);
    const selectedRedevanceId = ref<number | null>(null);

    const addRedevancePaiement = async (values, { resetForm }) => {
      if (selectedRedevanceId.value) {
        const redevanceData = await getMontant(selectedRedevanceId.value);
        // Faites quelque chose avec redevanceData...
      }
      // ApiService.post("/redevances", values)
      //   .then(({ data }) => {
      //     if(data.code == 201) { 
      //       success(data.message);
      //       resetForm();
      //       router.push({ name: "ListRedevancesPage" });
      //     }
      //   }).catch(({ response }) => {
      //     error(response.data.message);
      //   });
    };

    const getMontant = (id: number): Promise<void | any> => {
      return ApiService.get(`/redevances/${id.toString()}`)
        .then(({ data }) => {
          const redevanceData = data.data;
          if (redevanceData && redevanceData.service) {
            const montant = redevanceData.abonnement.montant || 0;
            montantval.value = montant;
            redevanceForm.value?.setFieldValue('montant', montantval.value);
          } else {
            montantval.value = 0;
            redevanceForm.value?.setFieldValue('montant', montantval.value);
          }
        });
    };

    onMounted(async () => {
    if (selectedRedevanceId.value !== null) {
      await getMontant(selectedRedevanceId.value);
      redevanceForm.value?.setFieldValue('montant', montantval.value);
    }
  });

    return { addRedevancePaiement, montantval, redevanceSchema, redevanceForm, selectedRedevanceId };
  },
});
</script>
