<template>
  <div class="card mb-25 border-0 rounded-0 bg-white add-user-card">
    <div class="card-body p-15 p-sm-20 p-md-25 p-lg-30 letter-spacing">
      <Form ref="reclamationForm" @submit="addReclamation" :validation-schema="reclamationSchema">
        <div class="row">
          <div class="col-md-6">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="d-block text-black fw-semibold mb-10">
                  Adresse
                </label>
                <Field  name="abonnement"  v-slot="{ field }">
                  <Multiselect
                    :options="abonnementOptions"
                    track-by="value"
                    label="label"
                    v-model = "field.value"
                    v-bind = "field"
                    placeholder="Sélectionner l'adresse"
                  />
                </Field>  
                <ErrorMessage name="abonnement" class="text-danger"/>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="d-block text-black fw-semibold mb-10">
                  Objet
                </label>
                <Field name="objet" v-slot="{ field }">
                  <Multiselect 
                    :options="['Abonnement', 'Attestation', 'Cession', 'Changement de clé', 'Partage', 'Résiliation', 'Transfert', 'Autre Objet']" 
                    :searchable="true" 
                    v-model="field.value"
                    v-bind="field"
                    placeholder="Sélectionner l'objet"
                    @select="handleObjetInput"
                  />
                </Field>
                <ErrorMessage name="objet" class="text-danger"/>
              </div>
            </div>
            <div class="col-md-12" v-if="showAutreObjet || isAutreObjetNonListe">
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">
                Autre objet
              </label>
              <Field name="autreObjet" type="text"  @input="handleAutreObjetInput"
              class="form-control shadow-none fs-md-15 text-black" placeholder="Entrer l'objet"/>
            <ErrorMessage name="autreObjet" class="text-danger"/>
            </div>
          </div>
          <div class="col-md-12">
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">
                Date
              </label>
              <Field name="dateDemande" type="date" v-model="currentDate" readonly="true"
              class="form-control shadow-none rounded-0 text-black" placeholder="Choisisser la date"/>
                <ErrorMessage name="dateDemande" class="text-danger"/>
            </div>
          </div>
            <div class="col-md-12">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="d-block text-black fw-semibold mb-10">
                  Message
                </label>
                <Field name="contenu" as="textarea"  cols="30"
                  rows="12" placeholder="Ecrire votre message" v-slot="{ field}" class="form-control shadow-none rounded-0 text-black">
                <textarea
                class="form-control shadow-none rounded-0 text-black"
                  v-model="field.value"
                ></textarea>
              </Field>
              <ErrorMessage name="contenu" class="text-danger"/>
              </div>
            </div>
          <div class="col-md-12">
            <div class="d-flex align-items-center ">
              <button
                class="default-btn me-20 transition border-0 fw-medium text-white pt-10 pb-10 ps-25 pe-25 pt-md-11 pb-md-11 ps-md-35 pe-md-35 rounded-1 fs-md-15 fs-lg-16 bg-success"
                type="submit"
              >
                  Faire la réclamation
              </button>
              <router-link to="/liste-reclamations" 
                class=" btn btn-danger transition border-0 lh-1 fw-medium"><i class="flaticon-delete lh-1 me-1 position-relative top-2"></i>
                <span class="position-relative"></span>Annuler</router-link>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted } from "vue";
import axios from "axios";
import { Form, Field, ErrorMessage } from 'vee-validate';
import * as Yup from 'yup';
import Multiselect from '@vueform/multiselect'
import { error, success } from '@/utils/utils';
import { Demande } from '@/models/Demande';
import { useRouter } from 'vue-router';
import ApiService from '@/services/ApiService';
import JwtService from "@/services/JwtService";


export default defineComponent({
  name: "AddReclamation",
  components: {
    Form,
    Field,
    ErrorMessage,
    Multiselect,
  },
  setup: () => {

    const reclamationSchema = Yup.object().shape({
      objet: Yup.string().required('L\' objet est obligatoire'),
      autreObjet: Yup.string(),
      contenu: Yup.string().required("Le message est obligatoire"),
      dateDemande: Yup.date().required('La date de demande est obligatoire'),
      typeDemande: Yup.number().default(8).typeError('Veuillez entrer un nombre').required('Le type de d\'emande est obligatoire'),
      abonnement: Yup.string().required('L\'adresse de l\'abonne est obligatoire'),
    });

    const reclamationForm =  ref<Demande | null>;
    const router = useRouter();
    const showAutreObjet = ref(false);
    const isAutreObjetNonListe = ref(false);
    const options = ['Abonnement', 'Attestation', 'Cession', 'Changement de clé', 'Partage', 'Résiliation', 'Transfert', 'Autre Objet'];
    const abonnements = ref(null);
    const abonnementOptions = ref([]);

    
    const abonneid = JwtService.getUser();

    const fetchAbonnements = async (abonneid) => {  
      try {
        const response = await axios.get('/abonnements/abonne');
        const abonnementsData = response.data.data.data;
        abonnementOptions.value = abonnementsData.map((abonnement) => ({
          value: abonnement.id+'-'+abonnement.loyer,
          label: `${abonnement.refAbo} - [${abonnement.boite? abonnement.boite.adressePostale.adresse_postale:abonnement.adresseVirtuelle}] - [${abonnement.abonne.nomComplet}]`,
        }));
        return abonnementOptions.value;
      } catch (error) {
        //
      }
    }

    const addReclamation = async (values, {resetForm}) => {
    const updatedValues = {
    ...values,
        typeDemande: values.typeDemande || 8,
      };
      ApiService.post("/demandes/A",updatedValues)
        .then(({ data }) => {
          if(data.code == 201) { 
            success(data.message);
            resetForm();
            router.push({ name: "ListReclamationsPage" });
          }
        }).catch(({ response }) => {
          error(response.data.message);
        });
    };
    const handleAutreObjetInput = (value) => {
      showAutreObjet.value = value === 'Autre Objet';
      isAutreObjetNonListe.value = !options.includes(value);
    };

    const currentDate = ref(new Date().toISOString().split('T')[0]);

    onMounted(() => {
      currentDate.value = new Date().toISOString().split('T')[0];
      fetchAbonnements(abonneid);
    });

    const handleObjetInput = (value) => {
      showAutreObjet.value = value === 'Autre Objet';
    };

    return { addReclamation, currentDate, reclamationSchema,
       reclamationForm, handleObjetInput, showAutreObjet, 
       isAutreObjetNonListe, handleAutreObjetInput,
       abonnementOptions, abonnements, abonneid};
  },
});
</script>