<template>
  <div class="modal fade createNewModal" id="OpenConditionGeneralModal" tabindex="-1" ref="addOpenConditionGeneralRef"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content p-15 p-md-40" id="scroll">
        <h4 class="text-center fw-bold mb-10 mb-12">
          Politique de confidentialité des données personnelles
        </h4>
        <div class="card-body p-15 p-sm-20 p-md-25 p-lg-30">
          <p class="text-paragraph fs-md-15 fs-lg-16 lh-base" style="text-align: justify;">
            La présente politique de confidentialité est fondée sur l’article 415 figurant au livre cinquième du code du
            numérique relatif à la Protection des Données à caractère personnel.
            Elle mentionne la preuve de l’accomplissement des formalités préalables auprès de l’Autorité de Protection des
            Données Personnelles (APDP) et vous aide à comprendre quelles informations
            nous collectons et traitons à LA POSTE DU BENIN SA, les données personnelles, les finalités visées ainsi que
            la politique de management mise en place aux fins de respecter la législation en vigueur
            en matière de protection des données personnelles et de la vie privée en République du Bénin.
            Veuillez lire attentivement cette Politique de confidentialité qui nous engage.
          </p>
          <h6 class="text-black fw-bold mb-10 mb-12">
            1. Responsable du traitement
          </h6>
          <p class="text-paragraph fs-md-15 fs-lg-16 lh-base" style="text-align: justify;">
            Le responsable du traitement de vos Données Personnelles est : Prof. B. Judith GLIDJA,, Directrice Générale de
            La Poste du Bénin SA
            <br>
            Contact téléphonique : +229 21.31.79.58
            Adresse email : benin@laposte.bj
          </p>

          <h6 class="text-black fw-bold mb-10 mb-12">
            2. Formalités préalables
          </h6>
          <p class="text-paragraph fs-md-15 fs-lg-16 lh-base" style="text-align: justify;">
            Le traitement est poursuivi conformément au ……….RD n° ….…….délivré par l’Autorité de Protection des données
            Personnelles (APDP).
            Vous pouvez contacter cette autorité et vérifier les termes de la présente par le biais des coordonnées
            suivantes : contact@apdp.bj , +229 21.32.57.88 / 69.55.00.00.
          </p>

          <h6 class="text-black fw-bold mb-10 mb-12">
            3. Les données personnelles objet du traitement
          </h6>
          <p class="text-paragraph fs-md-15 fs-lg-16 lh-base" style="text-align: justify;">
            Les données traitées sont : <br>
            - nom, prénoms, <br>
            - sexe, <br>
            - date et lieu de naissance, <br>
            - nationalité et pays de résidence, <br>
            - situation matrimoniale, <br>
            - numéro de pièce d'identité (Carte nationale d'identité, passeport, carte militaire, CIP, etc…), <br>
            - adresse géographique et postale, <br>
            - adresse mail, <br>
            - numéro de téléphone <br>
            - Profession, <br>
            - Employeur, <br>
            - Revenu mensuel, <br>
            - nom et prénoms de la mère, <br>
            - nom d'une personne à contacter, <br>
            - raison Sociale, <br>
            - registre de Commerce et de Crédit Mobilier (RCCM), <br>
            - Identifiant Fiscale Unique (IFU) <br>
          </p>

          <h6 class="text-black fw-bold mb-10 mb-12">
            4. Les opérations effectuées
          </h6>
          <p class="text-paragraph fs-md-15 fs-lg-16 lh-base" style="text-align: justify;">
            La Poste du Bénin SA ne collecte des informations personnelles relatives aux clients, que pour le besoin
            exclusif des services proposés <br>
            Les informations recueillies font l'objet des opérations suivantes : la collecte, l'exploitation,
            l'enregistrement, la sauvegarde, la consultation, l'utilisation, la communication par transmission, <br>
            la diffusion ou toute autre forme de mise à disposition, la structuration, le rapprochement ou
            l'interconnexion, le cryptage, le traitement électronique et manuel destiné à l'atteinte des finalités
            ci-dessous mentionnées.
          </p>

          <h6 class="text-black fw-bold mb-10 mb-12">
            5. La/les finalité(s) du/des traitement(s)
          </h6>
          <p class="text-paragraph fs-md-15 fs-lg-16 lh-base" style="text-align: justify;">
            Les informations sont collectées pour la/les finalité(s) suivante(s) <br>
            - Le suivi et l'orientation de la clientèle (nom et prénoms, secteur d'activité, âge, profession)<br>
            - Le recueil des plaintes des salariés, (adresse électronique, nom, prénoms et numéros de téléphone) <br>
            Il convient de rappeler que chaque traitement correspond à une finalité précise et unique. Dans l'hypothèse où
            un seul et même traitement pourrait servir plusieurs finalités, votre consentement sera recueilli
            distinctement pour chacune d'elles.
          </p>

          <h6 class="text-black fw-bold mb-10 mb-12">
            6. Destinataires des Données personnelles communication et transfert
          </h6>
          <p class="text-paragraph fs-md-15 fs-lg-16 lh-base" style="text-align: justify;">
            6.1 Les destinataires des données sont : <br>
            - Direction de l'Administration et des Finances <br>
            - Direction de l'Audit du Contrôle de Gestion et de l'Inspection: <br>
            - Direction de l'Exploitation Commerciale <br>
            - Direction Poste Finances <br>
            - Cellule du Numérique et de la Transformation Digitale <br>
            - Opérateurs de téléphonie mobile MTN et Moov <br>

            6.2 Les données sont transférées aux structures suivantes :

          <table class="" style="border-collapse: collapse;">
            <thead>
              <th style="border: 1px solid black;">Structures</th>
              <th style="border: 1px solid black;">Pays de conservation et stockage</th>
            </thead>
            <tbody>
              <td class="" style="border: 1px solid black;">- Brigade Economique et Financière, Agblangandan, Cotonou, Tél
                +229 65 48 78 46 (besoins d'enquêtes) <br>

                - Cellule Nationale de Traitement des Informations Financières, Cotonou, Tél : +229 21 31 20 98 (besoins
                d'enquêtes) <br>

                - Opérateur MTN et MOOV (transaction push and pull) <br>
              </td>
              <td class="" style="border: 1px solid black;">BENIN</td>
            </tbody>
          </table>
          </p>

          <h6 class="text-black fw-bold mb-10 mb-12">
            7. Durée du traitement, conservation et stockage
          </h6>
          <p class="text-paragraph fs-md-15 fs-lg-16 lh-base" style="text-align: justify;">
            Les Données Personnelles sont traitées par nos soins pendant une durée de 10 ans après clôture des comptes
            conformément à l’article 35 de la loi 2018-17 du 25 juillet 2018 <br>
            Pour plus d’informations concernant les durées de conservation, vous pouvez nous contacter à l’adresse mail
            suivante: benin@laposte.bj Adresse Tél :(229) 21.31.79.58., <br>

            Les Données Personnelles sont conservées et stockées comme suit :
          <table class="" style="border-collapse: collapse;">
            <thead>
              <th style="border: 1px solid black;">Modalités de conservation et stockage</th>
              <th style="border: 1px solid black;">Pays de conservation et stockage</th>
            </thead>
            <tbody>
              <td class="" style="border: 1px solid black;">
                - sous la forme de dossier papier <br>

                - sous la forme de dossier électronique <br>
              </td>
              <td class="" style="border: 1px solid black;">BENIN</td>
            </tbody>
          </table> <br>
          Après la durée ci-dessus mentionnée, vos données sont supprimées
          </p>

          <h6 class="text-black fw-bold mb-10 mb-12">
            8. Les droits des personnes concernées à propos des traitements
          </h6>
          <p class="text-paragraph fs-md-15 fs-lg-16 lh-base" style="text-align: justify;">
            La loi vous donne sur vos données personnelles les droits suivants : <br>
            – droit d’accès aux informations collectées, <br>
            – droit d’interrogation, <br>
            – droit de modification, <br>
            – droit de rectification, <br>
            – droit à la portabilité, <br>
            – droit d’opposition au traitement, <br>
            – droit de suppression de données, <br>
            – droit à l’oubli. <br>
            – droit à réparation. <br>
            Vous pouvez consulter la définition de ces droits à l’adresse www.apdp.bj <br>
            Vous pouvez exercer vos droits en écrivant, par e-mail, soit par lettre aux adresses ci-après : <br>
            – boite postale : 01 BP 8080 <br>
            – siège : Direction Générale, Route du Port <br>
            – email : benin@laposte.bj <br>
            Nous garantissons une réponse à vos demandes dans un délai de quinze (15) jours à compter de la date de
            réception de votre requête.

          </p>

          <h6 class="text-black fw-bold mb-10 mb-12">
            9. Plaintes
          </h6>
          <p class="text-paragraph fs-md-15 fs-lg-16 lh-base" style="text-align: justify;">
            Vous avez le droit de déposer une plainte auprès de l’Autorité de Protection des Données Personnelles : La
            plainte est <br>
            – déposée au siège de l’institution Rue 6.076 Aïdjèdo Cotonou <br>
            – Adressée (mail uniquement) à l’adresse : contact@apdp.bj <br>

            Ceci est sans préjudice d’un recours devant un tribunal compétent.

          </p>

          <h6 class="text-black fw-bold mb-10 mb-12">
            10. Pour en savoir plus
          </h6>
          <p class="text-paragraph fs-md-15 fs-lg-16 lh-base" style="text-align: justify;">
            Si vous avez d’autres questions ou commentaires concernant le traitement de vos données personnelles,
            n’hésitez pas à contacter le Délégué à la Protection des Données Personnelles <br>
            – soit par e-mail à : benin@laposte.bj; cconformite@laposte.bj <br>
            – soit par courrier : Direction générale de La Poste du Bénin, 01 BP 8080 Cotonou Bénin <br>
            – soit par téléphone : (+229) 21.31.79.58 <br>
            <br>
          </p>

          <div class="col-md-12">
            <button
              class="default-btn me-20 transition border-0 fw-medium text-white pt-10 pb-10 ps-25 pe-25 pt-md-11 pb-md-11 ps-md-35 pe-md-35 rounded-1 fs-md-15 fs-lg-16 bg-danger"
              type="submit" @click="checkTermes()">
              Je refuse
            </button>
            <button
              class="default-btn me-20 transition border-0 fw-medium text-white pt-10 pb-10 ps-25 pe-25 pt-md-11 pb-md-11 ps-md-35 pe-md-35 rounded-1 fs-md-15 fs-lg-16 bg-success"
              type="submit" @click="noCheckTermes()">
              J'accepte
            </button>
          </div>
        </div>
        <button type="button" class="btn-close shadow-none" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
    </div>
  </div>

  <div class="row justify-content-center">
    <div class="col-md-6 col-lg-6 col-xl-6 col-xxl-6 col-xxxl-6">
      <div class="card mb-25 border-0 rounded-0 bg-white authentication-card">
        <div class="card-body letter-spacing">
          <div class="text-center mb-10">
            <img src="../../../assets/images/lapostebj.png" alt="logo-icon" style="height: 100px;" />
          </div>
          <h4 class="text-black fw-bold mb-0 text-center">
            Créer votre compte client
          </h4>
          <div class="text-center">
            <span class="text-muted  mt-15 mt-md-30 mb-12 mb-md-20 fs-md-15 fs-lg-16"> Avez-vous déjà un compte ?
              <router-link to="/login" class="fw-bold">Se connecter maintenant </router-link>
            </span>
          </div>
          <Form ref="registerForm" @submit="onSubmitRegister" :validation-schema="registerSchema">
            <div class="row">
              <div class="col-md-6">
                <div class="form-group mb-15 mb-sm-20 mb-md-25">
                  <label class="d-block text-black fw-semibold mb-10">
                    Catégorie d'abonné <span class="text-danger">*</span>
                  </label>
                  <Field name="categorieAbonne" v-model="categorieAbonneId" v-slot="{ field }">
                    <Multiselect :options="categorieAbonneOptions" :searchable="true" v-model="field.value" v-bind="field"
                      placeholder="Sélectionner la catégorie" @select="handleObjetInput" />
                  </Field>
                  <ErrorMessage name="categorieAbonne" class="text-danger" />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group mb-15 mb-sm-20 mb-md-25">
                  <label class="d-block text-black fw-semibold mb-10">
                    {{ label1 }} <span class="text-danger">*</span>
                  </label>
                  <Field name="nomComplet" type="text" class="form-control shadow-none fs-md-15 text-black" />
                  <ErrorMessage name="nomComplet" class="text-danger" />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group mb-10 mb-sm-15 mb-md-17">
                  <label class="d-block text-black fw-semibold mb-10">
                    Numéro de téléphone <span class="text-danger">*</span>
                  </label>
                  <Field name="telephone" v-model="telephone" v-slot="{ field }">
                    <vue-tel-input placeholder="Ex: +229 97 00 00 00" v-model="field.value" v-bind="field"
                      defaultCountry="BJ" mode="international" @validate="validate"
                      class="shadow-none fs-md-15 text-black py-2">
                    </vue-tel-input>
                  </Field>
                  <ErrorMessage name="telephone" class="text-danger" />
                  <div v-if="!validPhone" class="text-danger">Veuillez entrer un numéro correcte</div>
                </div>
              </div>
              <div class="col-md-6">
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">
                Email <span class="text-danger">*</span>
              </label>
              <Field name="email" type="email"
                class="form-control shadow-none fs-md-15 text-black" placeholder="Entrer l' email"/>
                <ErrorMessage name="email" class="text-danger"/>
            </div>
          </div>
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <div class="d-flex align-items-center justify-content-between mb-10">
                  <label class="d-block text-black fw-semibold ">
                    Mot de passe <span class="text-danger">*</span>
                  </label>
                </div>
                <div class="input-group has-validation">
                  <Field name="password" :type="showPassword ? 'password' : 'text'"
                    class="form-control shadow-none fs-md-15 text-black" placeholder="Entrer votre mot de passe" />
                  <span class="input-group-text" id="inputGroupPrepend">
                    <i :class="{ 'flaticon-eye': !showPassword, 'flaticon-visibility-off': showPassword }"
                      class="flaticon-eye" @click="toggleShow" :aria-label="buttonLabel"></i>
                  </span>
                </div>
                <ErrorMessage name="password" class="text-danger" />
              </div>
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <div class="d-flex align-items-center justify-content-between mb-10">
                  <label class="d-block text-black fw-semibold ">
                    Confirmer le mot de passe
                  </label>
                </div>
                <div class="input-group has-validation">
                  <Field name="password_confirmation" :type="showPassword2 ? 'password' : 'text'"
                    class="form-control shadow-none fs-md-15 text-black" placeholder="Confirmer le mot de passe" />
                  <span class="input-group-text" id="inputGroupPrepend">
                    <i :class="{ 'flaticon-eye': !showPassword2, 'flaticon-visibility-off': showPassword2 }"
                      class="flaticon-eye" @click="toggleShow2" :aria-label="buttonLabel2"></i>
                  </span>
                </div>
                <ErrorMessage name="password_confirmation" class="text-danger" />
              </div>
            </div>
            <div class="form-check form-check-primary mb-0 fs-md-15 fs-lg-16 text-muted lh-1 mb-15 mb-md-20">
              <Field type="checkbox" class="form-check-input shadow-none" name="termes" :value="true" id="remember-me"
                v-model="trueTermes" />
              <label class="form-check-label" for="remember-me">
                j'accepte la
                <a class="text-decoration-none" data-bs-toggle="modal" data-bs-target="#OpenConditionGeneralModal">
                  Politique de confidentialité
                </a>

              </label>
            </div>
            <ErrorMessage name="termes" class="text-danger" />
            <button type="submit" :disabled="!validPhone"
              class="text-decoration-none text-center default-btn transition border-0 fw-medium text-white rounded-1 fs-md-15 fs-lg-16 bg-success  w-100">
              Inscrivez vous maintenant
            </button>
          </Form>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted, computed } from 'vue';
import { Form, Field, ErrorMessage } from 'vee-validate';
import * as Yup from 'yup';
import axios from 'axios';
import { error, success } from '@/utils/utils';
import { useRouter } from 'vue-router';
import { Abonne } from '@/services/auth';
import { CategorieAbonne } from '@/models/Abonne';
import { useAuthStore } from '@/services/auth';
import Swal from 'sweetalert2';
import Multiselect from '@vueform/multiselect'
import { VueTelInput } from 'vue3-tel-input'
import 'vue3-tel-input/dist/vue3-tel-input.css'

export default defineComponent({
  name: "RegisterContent",
  components: {
    Form,
    Field,
    ErrorMessage,
    Multiselect,
    VueTelInput,
  },

  setup: () => {
    const registerSchema = Yup.object().shape({
      email: Yup.string().email("L'email est invalide").notRequired(),
      categorieAbonne: Yup.number().typeError('Veuillez entrer un nombre').required('La catégorie de l\'abonné est obligatoire'),
      nomComplet: Yup.string().required('Le nom et le prénom sont obligatoires'),
      telephone: Yup.string().required('Le numéro de téléphone est obligatoire'),
      password: Yup.string().min(8, 'Le mot de passe doit contenir au moins 8 caractères').required('Le mot de passe est obligatoire'),
      password_confirmation: Yup.string()
        .required('Le champ de confirmation est obligatoire')
        .oneOf([Yup.ref("password")], "Les champs de mot de passe et de confirmation de mot de passe doivent correspondre")
        .label("Confirmation de mot"),
      termes: Yup.boolean().required('Cochez pour accepter les politiques de confidentialité')
    });

    const router = useRouter();
    const store = useAuthStore();
    const userForm = ref<Abonne | null>(null);
    const codepay = ref();
    const nationalnumlber = ref();
    const telephone = ref();
    const numberPhone = ref();
    const validPhone = ref<boolean>(false);
    const numeroNational = ref();

    const showAdditionalFields = ref(true);
    const categorieAbonneId = ref(1);
    const categorieAbonnes = ref(null);
    const categorieAbonneOptions = ref<CategorieAbonne[]>([]);
    const estEmail = ref(false)

    const handleObjetInput = (selectedValue) => {
      showAdditionalFields.value = selectedValue === 1;
    }

    const label1 = computed(() => {
      return showAdditionalFields.value ? 'Nom et prénom' : 'Dénomination';
    });

    const fetchCategorieAbonnes = async () => {
      try {
        const response = await axios.get('/categorieAbonnes');
        const categorieAbonnesData = response.data.data.data;
        categorieAbonneOptions.value = categorieAbonnesData.map((categorieAbonne) => ({
          value: categorieAbonne.id,
          label: categorieAbonne.libelle,
        }));
      } catch (error) {
        //
      }
    };

    onMounted(() => {
      fetchCategorieAbonnes();
    });

    function onInput(phone, phoneObject, input) {
      //
    }

    function validate(phoneObject) {
      validPhone.value = phoneObject.valid;
      if (phoneObject.valid == true) {
        telephone.value = phoneObject.number;
        numberPhone.value = phoneObject.number;
        codepay.value = phoneObject.countryCallingCode;
        nationalnumlber.value = phoneObject.nationalNumber;
        numeroNational.value = phoneObject.nationalNumber;
      } else {
        telephone.value = "";
        numberPhone.value = "";
        codepay.value = "";
        nationalnumlber.value = "";
      }
    }

    const onSubmitRegister = async (values: any, { resetForm }) => {
      store.logout();
      // if (submitButton.value) {
      //   submitButton.value.disabled = true;
      // }
      // Send login request
      values["numeroNational"] = numeroNational.value;
      (numberPhone.value)
      values["telephone"] = numberPhone.value;
      values["estEmail"] = estEmail.value;
      console.log('jnndn', values)
      const result = await store.registerAbonner(values);
      const error = Object.values(store.errors);
      if (result.code == 201) {
        resetForm();
        //router.push({ name: "LoginPage" });
        router.push({ name: "CodeConfPage", query: { telephone: values.telephone, estEmail: values.estEmail } });
      } else {
        Swal.fire({
          text: result.message,
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "OK",
          heightAuto: false,
          customClass: {
            confirmButton: "btn btn-sm fw-semobold btn-danger",
          },
        }).then(() => {
          store.errors = {};
        });
      }
      // eslint-disable-next-line
      //submitButton.value!.disabled = false;
    };

    const trueTermes = ref(true)

    const noCheckTermes = () => {
      trueTermes.value = true
      Swal.fire({
        text: "Vous avez accepté les conditions générales",
        icon: "success",
        buttonsStyling: false,
        confirmButtonText: "OK",
        heightAuto: false,
        customClass: {
          confirmButton: "btn btn-sm fw-semobold btn-danger",
        },
      })
    };

    const checkTermes = () => {
      trueTermes.value = false
      Swal.fire({
        text: "Vous avez refusé les conditions générales",
        icon: "error",
        buttonsStyling: false,
        confirmButtonText: "OK",
        heightAuto: false,
        customClass: {
          confirmButton: "btn btn-sm fw-semobold btn-danger",
        },
      })
    };

    const showPassword = ref(true);
    const password = ref(null);

    const toggleShow = () => {
      showPassword.value = !showPassword.value;
    };

    const buttonLabel = computed(() => (showPassword.value ? 'Hide' : 'Show'));

    const showPassword2 = ref(true);
    const toggleShow2 = () => {
      showPassword2.value = !showPassword2.value;
    };
    const buttonLabel2 = computed(() => (showPassword2.value ? 'Hide' : 'Show'));

    return {
      userForm, registerSchema, onSubmitRegister,
      telephone,
      validPhone,
      validate,
      onInput,
      categorieAbonnes,
      categorieAbonneOptions,
      handleObjetInput,
      showAdditionalFields,
      label1,
      categorieAbonneId,
      numeroNational,
      checkTermes,
      trueTermes,
      noCheckTermes,
      showPassword,
      password,
      toggleShow,
      buttonLabel,
      showPassword2,
      toggleShow2,
      buttonLabel2,
    };
  },
});
</script>

<style>
table {
  border-collapse: collapse;
}

td {
  border: 1px solid black;
}

#OpenConditionGeneralModal {
  max-height: calc(100vh - 210px);
  overflow-y: auto;
}

#scroll {
  overflow-y: auto;
  max-height: calc(100vh - 210px);
}
</style>