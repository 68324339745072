<template>
  <BreadCrumb PageTitle="Envoyer une réclamation" />
  <AddReclamation />
</template>

<script lang="ts">
  import { defineComponent } from "vue";

  import BreadCrumb from "../../components/Common/BreadCrumb.vue";
  import AddReclamation from "../../components/Reclamations/AddReclamation.vue";

  export default defineComponent({
    name: "AddReclamationPage",
    components: {
      BreadCrumb,
      AddReclamation,
    },
  });
</script>