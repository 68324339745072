<template>
  <div class="row">    
    

      <div class="col-md-12 col-xxl-12 col-sm-12 mb-10">
        <div class="card mb-25 border-0 rounded-0">
          <div class="card-body p-15 p-sm-20 p-md-25 p-lg-30 letter-spacing">
            <h4 class="text-black fw-bold mb-10">Détails</h4>
            <table class="table table-responsive">
                <tbody>
                    <tr>
                        <td class="text-wrap">Envoyé le: {{ format_date(notification?.createdAt) }}</td>
                    </tr>
                    <tr>
                        <td class="text-wrap">{{ notification?.message }}</td>
                    </tr>
                </tbody>
            </table>
          </div>
        </div>
      </div>

      

  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from "vue";
import ApiService from "@/services/ApiService";
import { Notification } from "@/models/Notification";
import { format_date, success, error } from "@/utils/utils";
import { useRoute } from "vue-router";

export default defineComponent({
  name: "ViewNotificationContentPage",
  setup: () => {
    const route = useRoute();
    const notification = ref<Notification | null>();

    function getNotification(id: string) {
      return ApiService.get("/notifications/"+id)
        .then(({ data }) => {
          notification.value = data;
        })
        .catch(({ response }) => {
          error(response.data.message);
        });
    }
  function updateEstLu(id: string) {
      ApiService.put("/notifications/lu/"+id,{id:id})
        .then(({ data }) => {
          // success("Ce message est maintenant lu")
        })
        .catch(({ response }) => {
          error(response.data.message);
        });
  }


      onMounted(() => {
        const id = route.params.id as string;
        if(id) {
          getNotification(id);
          updateEstLu(id);
        }
    });



    return { notification, format_date};
  },
});
</script>

<style>
.text-wrap {
    word-wrap: break-word; /* Permet le retour à la ligne des mots longs */
    white-space: normal; /* Assure que le blanc est géré comme espace */
    max-width: 100%; /* Limite la largeur maximale à 100% de son parent */
}
</style>