<template>
  <BreadCrumb PageTitle="Liste des demandes de transferts" />
  <ListDemandeTransferts />
</template>

<script lang="ts">
import { defineComponent } from "vue";

import BreadCrumb from "../../components/Common/BreadCrumb.vue";
import ListDemandeTransferts from "../../components/DemandeTransferts/ListDemandeTransferts.vue";


export default defineComponent({
  name: "ListDemandeTransfertsPage",
  components: {
    BreadCrumb,
    ListDemandeTransferts,
  },
});
</script>