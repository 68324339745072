<template>
  <BreadCrumb PageTitle="Liste de mes abonnements" />
  <ListAbonnements />
</template>

<script lang="ts">
import { defineComponent } from "vue";

import BreadCrumb from "../../components/Common/BreadCrumb.vue";
import ListAbonnements from "../../components/Abonnements/ListAbonnements.vue";


export default defineComponent({
  name: "ListAbonnementsPage",
  components: {
    BreadCrumb,
    ListAbonnements,
  },
});
</script>